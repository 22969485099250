/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1';

export class ClassifyModelAPI {
  static async getDocModels(sortDesc = true) {
    return http
      .get(`${BASE_PATH}/docs/models`, {
        params: {
          sort_desc: sortDesc,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getModel(model) {
    return http
      .get(`${BASE_PATH}/models/`, {
        params: {
          model,
        },
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getAllModels() {
    return http
      .get(`${BASE_PATH}/models/list`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
