<template>
  <div
    class="item-name"
    :class="{
      'fade-in-slow': fadeInSlow,
    }"
  >
    <div :style="{ opacity: loaded ? 1 : 0 }">
      <div
        v-if="!rename"
        ref="nameContainer"
        class="d-flex"
        :class="{ 'primary--text': clickable }"
      >
        <v-tooltip
          v-if="tooLong"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <span
              ref="itemName"
              class="ellipsis"
              :class="{ clickable }"
              v-bind="props"
              @click="$emit('nameClick')"
            >
              {{ item[nameField] }}
            </span>
          </template>
          <span style="color: white">
            {{ item[nameField] }}
          </span>
        </v-tooltip>
        <span
          v-else
          ref="itemName"
          :class="{ clickable }"
          :style="{ opacity: loaded ? 1 : 0 }"
          @click="$emit('nameClick')"
        >
          {{ item[nameField] }}
        </span>
        <v-tooltip
          v-if="showId && item.id !== -1"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              class="hashtag-icon"
              style="margin-left: 5px; margin-top: 5px;"
              size="11"
              v-bind="props"
            >
              fas fa-hashtag
            </v-icon>
          </template>
          <span style="color: white">
            id: {{ item.id }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="showEditIcon && !rename"
          color="#423F4F"
          loaction="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              class="clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
              @click="renameFile"
            >
              fas fa-pen
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t("models.edit_name") }}
          </span>
        </v-tooltip>
        <div
          v-if="item.is_locked"
          class="clickable ml-2"
        >
          <v-tooltip
            color="#423F4F"
            location="bottom"
          >
            <template #activator="{ props }">
              <v-icon
                size="16"
                v-bind="props"
              >
                fas fa-lock
              </v-icon>
            </template>
            <span style="color: white">
              {{ $t('docTypes.doctype_is_locked') }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <div v-else>
        <input
          ref="itemNameField"
          v-model="newName"
          type="text"
          class="typeNameField primary--text"
          :style="{ 'width': `${maxNameWidth - 40}px` }"
          @keydown.enter="saveFileName"
          @keydown.esc="rename = false"
        >
        <v-btn
          class="right-gap-sm"
          style="color: rgb(var(--v-theme-primary)) !important"
          variant="text"
          @click="saveFileName"
        >
          <v-icon size="16">
            fas fa-check
          </v-icon>
        </v-btn>
        <v-btn
          style="color: rgb(var(--v-theme-primary)) !important"
          variant="text"
          @click="$emit('cancelNameEdit'); rename = false;"
        >
          <v-icon size="16">
            fas fa-times
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'ItemName',

  data() {
    return ({
      rename: false,
      newName: '',
      loaded: false,
      windowWidth: window.innerWidth,
      recompute: 1,
    });
  },

  computed: {
    itemName() {
      this.recompute;
      this.windowWidth;
      this.loaded;
      this.$store.getters.menuOpen;
      return this.$refs.itemName;
    },

    tooLong() {
      this.windowWidth;
      this.loaded;
      this.$store.getters.menuOpen;
      this.itemName;
      return this.itemName && this.itemName.offsetWidth > this.maxNameWidth;
    },

    maxNameWidth() {
      if (this.maxWidth) {
        return this.maxWidth;
      }
      this.loaded;
      this.windowWidth;
      this.$store.getters.menuOpen;
      const nameContainer = this.$refs.nameContainer;
      if (nameContainer) {
        return nameContainer.offsetWidth - 20;
      }
      return 200;
    },

    extension() {
      if (this.item && this.item[this.nameField]) {
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(this.item[this.nameField])[1];
        return ext ? ext : '?';
      }
      return '?';
    },
  },

  watch: {
    itemName(name) {
      if (!name) {
        nextTick(() => {
          this.recompute++;
        })
      }
    },

    editingAllowed() {
      if (this.rename && !this.editingAllowed) {
        this.rename = false;
      }
    },

    editing(on) {
      if (on) {
        this.renameFile();
      } else {
        this.rename = false;
      }
    }
  },

  mounted() {
    nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if (this.item && this.item[this.nameField]) {
      this.newName = this.type === 'file' ? this.item[this.nameField].slice(0, (this.extension.length + 1) * -1) : this.item[this.nameField];
    }
    if (this.fadeInSlow) {
      setTimeout(() => {
        this.loaded = true;
      }, 300 );
    } else {
      nextTick(() => {
        this.loaded = true;
      })
    }
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    renameFile() {
      this.rename = true;
      nextTick(() => {
        this.$refs.itemNameField.focus();
      });
    },

    saveFileName() {
      const newName = this.type === 'file' ? `${this.newName}.${this.extension}` : this.newName;
      this.$emit('saveFileName', this.item.id, newName);
      this.loaded = false;
      this.rename = false;
      setTimeout(() => {
        this.loaded = true;
      }, 200);
    },
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    type: {
      type: String,
      required: false,
      default: 'default',
    },

    editingAllowed: {
      type: Boolean,
      required: true,
    },

    showEditIcon: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: true,
    },

    showId: {
      type: Boolean,
      default: true,
    },

    maxWidth: {
      type: Number,
      default: 0,
    },

    fadeInSlow: {
      type: Boolean,
      default: true,
    },

    editing: {
      type: Boolean,
      default: false,
    },

    nameField: {
      type: String,
      default: 'name',
    }
  },

  emits: ['nameClick', 'cancelNameEdit', 'saveFileName'],
}
</script>

<style lang="scss" scoped>
.item-name {
  white-space: nowrap;

  .hashtag-icon {
    color: rgb(var(--v-theme-grey-darken2)) !important;
  }

  .hashtag-icon:hover {
    color: rgb(var(--v-theme-primary)) !important;
  }

  .typeNameField {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
}
</style>
