<template>
  <div>
    <div class="top-gap-sm bottom-gap">
      {{ $t('models.label_types.location') }}
      <v-icon
        v-if="labelType === labelTypes.location"
        class="clickable left-gap-sm"
        size="16"
        @click="$emit('changeType', -1)"
      >
        fas fa-chevron-down
      </v-icon>
      <v-icon
        v-else
        class="clickable left-gap-sm"
        style="margin-top: -1px"
        size="16"
        @click="$emit('changeType', labelTypes.location)"
      >
        fas fa-chevron-right
      </v-icon>
    </div>
    <div v-if="labelType === labelTypes.location">
      <div
        v-for="(label, i) in filteredLabels"
        :key="i"
        :ref="`locationLabel${label.id}`"
        class="location-label-container noselect"
        :class="{
          'label-container-active':
            annotating === label.id ||
            selectedLabelName === label.name,
        }"
        @mouseover="$emit('selectLabel', label.name)"
      >
        <div class="label-name-container">
          <div
            v-if="editName !== label.id"
            class="vertical-centered ellipsis label-name-container-inner"
          >
            <v-tooltip
              v-if="smallLabelName"
              color="#423F4F"
              right
            >
              <template #activator="{ props }">
                <span
                  class="clickable"
                  v-bind="props"
                >
                  {{ label.name }}
                </span>
              </template>
              <span style="color: white">
                {{ label.name }}
              </span>
            </v-tooltip>
            <span v-else>
              {{ label.name }}
            </span>
            <v-tooltip
              v-if="selectedLabelName === label.name"
              color="#423F4F"
              right
            >
              <template #activator="{ props }">
                <v-icon
                  class="clickable edit-name-icon"
                  size="12"
                  v-bind="props"
                  @mouseover="$emit('selectLabel', label.name)"
                  @click="startNameEdit(label.id, label.name)"
                >
                  fas fa-pen
                </v-icon>
              </template>
              <span style="color: white">
                {{ $t("models.edit_name") }}
              </span>
            </v-tooltip>
          </div>
          <div
            v-else
            class="vertical-centered label-name-container-inner"
          >
            <input
              ref="labelNameField"
              v-model="newNameForLabel"
              type="text"
              class="label-name-field"
              @keydown.enter="saveLabelName"
              @keydown.esc="editName = -1"
            >
            <v-icon
              class="clickable edit-name-icon"
              size="12"
              @mouseover="$emit('selectLabel', label.name)"
              @click="saveLabelName"
            >
              fas fa-check
            </v-icon>
          </div>
        </div>
        <div
          v-if="annotations"
          class="annotations-container location-annotations-container text-h4"
          :style="{
            color: getColor(i)
          }"
        >
          {{ annotations[label.name] && annotations[label.name].length || 0 }}
          <span class="text-lowercase ml-2">
            {{ smallLabelName ? $t("models.locations_abbreviated") : $t("models.locations") }}
          </span>
        </div>
        <div
          v-if="selectedLabelName === label.name"
          class="label-buttons"
        >
          <v-tooltip
            color="#423F4F"
            right
          >
            <template #activator="{ props }">
              <div
                class="clickable edit-icon"
                v-bind="props"
                @click="handleAnnotationClick(label.id)"
              >
                <v-icon
                  v-if="annotating === label.id"
                  class="vertical-centered"
                  style="margin-top: -1px"
                  color="primary"
                  size="16"
                >
                  fas fa-check
                </v-icon>
                <v-icon
                  v-else
                  class="vertical-centered edit-icon-icon"
                  color="primary"
                  size="16"
                >
                  fas fa-plus
                </v-icon>
              </div>
            </template>
            <span style="color: white">
              {{
                annotating === label.id
                  ? $t("models.finish_annotation")
                  : $t("models.start_annotation")
              }}
            </span>
          </v-tooltip>
          <v-tooltip
            v-if="
              annotations &&
                annotations[label.name] &&
                annotations[label.name].length > 0
            "
            color="#423F4F"
            right
          >
            <template #activator="{ props }">
              <div
                class="clickable edit-icon ml-2"
                v-bind="props"
                @click="$emit('deleteAnnotations', label.name)"
              >
                <v-icon
                  class="vertical-centered"
                  style="margin-top: -1px"
                  color="primary"
                  size="16"
                >
                  fas fa-trash
                </v-icon>
              </div>
            </template>
            <span style="color: white">
              {{ $t("models.remove_all_annotations") }}
            </span>
          </v-tooltip>
        </div>
        <div class="icon-bg" />
        <v-icon
          v-if="selectedLabelName === label.name"
          class="clickable close-icon"
          size="12"
          @click="$emit('setDelete', label.id)"
          @mouseover="$emit('selectLabel', label.name)"
        >
          fas fa-times
        </v-icon>
      </div>
      <div
        v-if="trimmedFilter !== '' && filteredLabels.length === 0"
        class="no-labels"
      >
        {{ $t('studio.no_labels') }}
      </div>
      <v-text-field
        v-model="newLabel"
        class="inline-middle right-gap label-field"
        style="width: 300px"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('models.type_label_name')"
        @keydown.enter="addLabel"
      />
      <v-btn
        class="add-button"
        style="margin-top: -20px"
        color="primary"
        :disabled="trimmedLabel === ''"
        @click="addLabel"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t("docTypes.add") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { http } from "@/plugins/axios";

export default {
  name: 'LocationLabelList',

  data() {
    return {
      editName: -1,
      newLabel: "",
      newNameForLabel: "",
      editOldName: "",
      labelTypes: {
        annotation: 0,
        location: 1,
      },
    };
  },

  computed: {
    filteredLabels() {
      return this.locationLabels.filter(l => l.name.toLowerCase().includes(this.trimmedFilter));
    },

    trimmedFilter() {
      return this.filter.trim().toLowerCase();
    },

    trimmedNewNameForLabel() {
      return this.newNameForLabel.trim().toUpperCase();
    },

    trimmedLabel() {
      return this.newLabel.trim().toUpperCase();
    }
  },

  methods: {
    startNameEdit(id, name) {
      this.editName = id;
      this.editOldName = name;
      this.newNameForLabel = name;
      setTimeout(() => {
        this.$refs.labelNameField[0].focus();
      }, 100);
    },

    async saveLabelName() {
      if (this.trimmedNewNameForLabel !== "") {
        try {
          const oldName = this.editOldName;
          const newName = this.trimmedNewNameForLabel;
          await http.put(`dataset/label/${this.editName}/`,
            {
              name: newName,
            },
            {
              params: {
                type: 'location',
              },
            });
          this.editName = -1;
          this.newNameForLabel = "";
          this.$emit('updateAnnotations', newName, oldName, 'locationAnnotations');
        } catch (error) {
          this.$store.commit("setSnackbar", true);
          console.log(error);
        }
        this.$emit('getLocationLabels');
      }
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          await http.post(`dataset/label/${this.$route.params.id}/`,
          {
            name: this.newLabel,
          },
          {
            params: {
              type: 'location',
            },
          });
          this.newLabel = "";
        } catch (error) {
          this.$store.commit("setSnackbar", true);
          console.log(error);
        }
        this.$emit('getLocationLabels');
      }
    },

    handleAnnotationClick(id) {
      if (this.annotating === id) {
        this.$emit('setAnnotating', -1);
      } else {
        this.$emit('setAnnotating', id);
      }
    },

    getColor(i) {
      return this.$store.getters.annotationColors[i % 9];
    },
  },

  props: {
    annotations: {
      type: Object,
      default: () => {},
    },

    annotating: {
      type: Number,
      required: true,
    },

    deleting: {
      type: Number,
      required: true,
    },

    locationLabels: {
      type: Array,
      required: true,
    },

    selectedLabelName: {
      type: String,
      required: true,
    },

    filter: {
      type: String,
      required: true,
    },

    labelType: {
      type: Number,
      required: true,
    },

    smallLabelName: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'changeType',
    'selectLabel',
    'deleteAnnotations',
    'setDelete',
    'updateAnnotations',
    'getLocationLabels',
    'setAnnotating',
  ],
}
</script>
<style lang="scss" scoped>
  .no-labels {
    font-style: italic;
    text-align: center;
    margin-top: 12px;
  }

  .location-label-container {
    background-color: white;
    width: 100%;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 0.7rem;
    padding: 10px;
    position: relative;
    height: 65px;
    color: #4c4c4c;
    border: #c8c8c8 2px solid;
    overflow: hidden;

    .icon-bg {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: white;
      width: 55px;
    }

    .label-buttons {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      bottom: 0px;
      z-index: 100;
      display: flex;
    }

    .close-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .edit-name-icon {
      position: absolute;
      right: 0px;
      top: 1px;
    }

    .edit-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: rgb(var(--v-theme-primary)) 1px solid;
      text-align: center;

      .edit-icon-icon {
        margin-top: -3px;
      }
    }

    .annotations-container {
      padding-top: 10px;
      margin-left: 180px;
      max-width: calc(100% - 270px);
      position: relative;
      text-align: center;
    }

    .location-annotations-container {
      max-width: calc(100% - 100px) !important;
    }

    .label-name-container {
      position: absolute;
      width: 170px;
      left: 0px;
      top: 0px;
      bottom: 0px;
      padding: 10px;

      .label-name-container-inner {
        position: absolute;
        max-width: 200px;
        padding-right: 20px;

        .label-name-field {
          display: inline-block;
          text-transform: uppercase;
          background-color: #ffffff00;
          padding: 0 !important;
          border: none;
          width: 130px;
          border-bottom: 1px solid rgb(var(--v-theme-primary));

          &:focus {
            outline: none;
            border-bottom: 1px solid rgb(var(--v-theme-primary));
          }

          &:focus-visible {
            outline: none;
            border-bottom: 1px solid rgb(var(--v-theme-primary));
          }
        }
      }
    }
  }

  .add-button {
    top: 8px;
    box-shadow: none !important;
  }

  .label-container-active {
    border: rgb(var(--v-theme-primary)) 2px solid;
  }
</style>
