<template>
  <div>
    <div class="d-flex justify-space-between w-100">
      <v-card
        v-for="(category, i) in formatted"
        :key="i"
        class="chart-card top-gap-lg pb-1"
      >
        <h3 class="text-h3">
          {{ $t(`traces.${category.name}`) }} ({{ sumOfValues(category.values[0].data) }})
        </h3>
        <ApexChart
          v-if="category.values[0].data.length > 0 && !category.isEmpty"
          :key="chartKey"
          width="100%"
          type="bar"
          :options="getChartOptions(category.name === 'size_sum')"
          :series="category.values"
        />
        <div
          v-else
          class="no-data"
        >
          <div class="no-data-text">
            {{ $t('traces.no_data') }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VueApexCharts from 'vue3-apexcharts';
import format_mixin from '@/mixins/format.js';

export default {
  name: 'TracesCharts',

  mixins: [format_mixin],

  components: {
    ApexChart: VueApexCharts,
  },

  data() {
    return {
      chartKey: 0,
      forceCompute: 0,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser || null;
    },

    dateArray() {
      return this.categories.map(item => item.timestamp);
    },
    dateParsed() {
      return [...this.dateArray, ...this.emptyCurrentDate];
    },
    emptyCurrent() {
      if (this.dateArray.length > 0 && !this.dateArray.includes(this.currentDate)) {
        return [0];
      }
      return [];
    },
    emptyCurrentDate() {
      if (this.dateArray.length > 0 && !this.dateArray.includes(this.currentDate)) {
        return [this.currentDate];
      }
      return [];
    },
    formatted() {
      this.forceCompute;
      return this.showCharts.map(category => ({
        name: category,
        values: [{
          name: this.$t(`traces.${category}`),
          color: '#502BFF',
          data: [...this.categories.map(item => item[category]), ...this.emptyCurrent],
        }],
        isEmpty: this.categories.every(item => item[category] === 0),
      }));
    },
  },

  watch: {
    categories: {
      handler() {
        this.forceCompute += 5;
        setTimeout(() => {
          this.chartKey += 5;
        }, 1);
      },
      deep: true
    },
  },

  mounted() {
    if (this.user) {
      moment.locale(this.user.language)
    }
  },

  methods: {
    getChartOptions(isSize = false) {
      let opts = {
        chart: {
          height: '100%',
          toolbar: {
            show: false,
          },
          type: 'line',
          stacked: false,
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: this.dateParsed,
          axisTicks: {
            show: false,
          },
          tickPlacement: 'between',
          labels: {
            hideOverlappingLabels: false,
            formatter: val => {
              if (this.dateRange === 1) {
                const week = moment(val, 'YYYY-WW');
                return week.startOf('isoWeek').format('DD/MM') + " - " + week.endOf('isoWeek').format('DD/MM');
              }
              return moment(val, 'YYYY-MM').format('MMM YY');
            }
          }
        },
        yaxis: {
          max: (max) => max + max * 0.1,
          forceNiceScale: true,
          tickAmount: 10,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          y: { formatter: this.formatNumber },
        },
      };
      if (isSize) {
        opts.yaxis = {
          ...opts.yaxis,
          labels: {
            formatter: val => this.formatSize(val, 0),
          }
        };
        opts.tooltip = {
          ...opts.tooltip,
          y: {
            formatter: val => this.formatSize(val, 1),
          }
        }
        opts = { ...opts };
      }
      return opts;
    },
    sumOfValues(valuesArray){
      return valuesArray.reduce((a, b) => a + b, 0);
    }
  },

  props: {
    categories: {
      required: true,
      type: Array,
    },
    currentDate: {
      default: null,
      type: String,
    },
    showCharts: {
      default: () => [],
      type: Array,
    },
    dateRange : {
      required: true,
      type: Number,
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-card {
  width: 95% !important;
}
.chart-card:not(:last-of-type) {
  margin-right: 15px;
}
.no-data {
  position: relative;
  padding-top: 56.25%
}
.no-data-text {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 100%;

  text-align: center;

  font-family: Telegraf;
  font-weight: bold;
  font-size: 20px;
  color: #BBB;
}

</style>
