<template>
  <div class="classification-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      wide
    />
    <ClassifiersView
      v-if="activeTab === 'models'"
      @create-click="activeTab = 'training'"
    />
    <TrainingView
      v-if="activeTab === 'training'"
      type="classification"
    />
  </div>
</template>

<script>
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import TrainingView from '@/components/extract/views/Studio/TrainingView';
import ClassifiersView from '@/components/extract/views/Studio/ClassifiersView';
import PageTabs from '@/components/common/elements/General/PageTabs';

export default {
  name: 'ClassificationView',

  components: {
    TrainingView,
    ClassifiersView,
    PageTabs,
  },

  data() {
    return ({
      activeTab: 'models',
      statusCheck: null,
    });
  },

  computed: {
    classifiers: {
      get() {
        return this.$store.getters.classifiers;
      },
      set(classifiers) {
        this.$store.commit('setClassifiers', classifiers);
      },
    },

    trainingClassifiers: {
      get() {
        return this.$store.getters.trainingClassifiers;
      },
      set(classifiers) {
        this.$store.commit('setTrainingClassifiers', classifiers);
      },
    },

    tabItems() {
      return [
        {
          title: this.$t('menu.classifiers.title'),
          bigNumber: this.classifiers.length,
          name: 'models',
        },
        {
          title: this.$t('models.training'),
          bigNumber: this.trainingClassifiers.length,
          name: 'training',
        }
      ];
    },
  },

  created() {
    this.activeTab = this.startView;
    this.getClassifiers();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async getClassifiers() {
      try {
        const response = await ClassifyModelAPI.getDocModels();
        this.classifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained != null);
        this.trainingClassifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained === null);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  props: {
    startView: {
      type: String,
      default: 'models'
    },
  }
}
</script>
