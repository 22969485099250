<template>
  <v-container
    style="height: 100%"
    fill-height
  >
    <v-row justify="end">
      <v-col
        :md="5"
        :lg="4"
      >
        <v-card class="pa-8 vertical-centered">
          <h2 class="dialog-title mb-3">
            {{ $t('login.lost_password') }}
          </h2>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onEnter"
          >
            <p class="mt-7">
              {{ $t('login.we_will_email') }}
            </p>
            <div style="font-size: 0.8rem; margin-bottom: -16px">
              Email
            </div>
            <v-text-field
              v-model="form.user_email"
              class="mt-10"
              placeholder='i.e. "johndoe@email.com"'
              variant="outlined"
              color="primary"
              density="compact"
              :error-messages="errorsMessages.user_email"
              :rules="emailRules"
              @keyup.enter="onEnter"
              required
            />
          </v-form>
          <v-btn
            class="mt-9"
            color="primary"
            @click="validate()"
            rounded
            large
            block
          >
            {{ $t('login.reset_password') }}
          </v-btn>
          <v-btn
            class="mt-6"
            style="box-shadow: none"
            color="black"
            variant="outlined"
            @click="backToLogin()"
            rounded
            large
            block
          >
            {{ $t('login.back') }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-row justify="center">
          <HelperLogin />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelperLogin from '@/components/common/elements/Login/HelperLogin.vue';
import FormRules from '@/utils/classes/FormRules/FormRules';
import { PasswordAPI } from '@/API/authenticator/PasswordAPI';

export default {
  name: 'LostPassword',

  components: {
    HelperLogin,
  },

  data: () => ({
    valid: true,
    form: {
      user_email: '',
    },
    errorsMessages: {
      user_email: [],
    },
  }),

  computed: {
    emailRules() {
      const requiredError = this.$t('forms.email_required');
      const validError = this.$t('forms.email_must_valid');

      return [
        v => !!v || requiredError,
        v => FormRules.emailLocal(v) || validError,
      ]
    }
  },

  methods: {
    backToLogin() {
      this.$router.push({ name: 'login' });
    },

    async validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          await PasswordAPI.passwordRecoveryRequest(this.form.user_email);
          await this.$store.commit(
            'setSuccessMessage',
            this.$t('receiveEmailMessage')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.$router.push({ name: 'login' });
        } catch (error) {
          console.log(error);
          this.$store.commit('setSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },
    onEnter() {
      this.validate();
    },
    lostPassword() {
      this.$router.push({ name: 'LostPassword' });
    },
  },
};
</script>
