<template>
  <div class="extraction-group-config">
    <div class="label">
      {{ $t('dataPoints.aggregation_method') }}
    </div>
    <v-select 
      v-model="group.aggregation_method" 
      class="inline-middle"
      style="margin-top: 5px; width: 300px;"
      variant="outlined"
      color="primary"
      density="compact"
      :items="localMethods"
      @update:model-value="handleMethodChange"
    />
    <div v-if="type === 'extraction_group' && group.aggregation_method === 'block'">
      <div class="top-gap-sm">
        <div
          class="inline-middle radio-box right-gap-sm"
          style="padding-right: 20px"
        >
          <v-checkbox
            v-model="group.multi_page"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :label="$t('dataPoints.multi_page')"
            @update:model-value="handleMultiPage"
          />
        </div>
      </div>
    </div>
    <div v-if="type === 'extraction_group' && group.aggregation_method === 'cluster'">
      <div class="label">
        {{ $t('dataPoints.number_of_clusters') }}
      </div>
      <div class="top-gap-sm">
        <div
          class="inline-middle radio-box right-gap-sm"
          style="padding-right: 20px"
        >
          <v-checkbox
            v-model="autoDetectClusters"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :label="$t('dataPoints.auto_detect')"
          />
        </div>
        <div
          class="inline-middle radio-box"
          style="padding-right: 10px"
          :style="{ opacity: autoDetectClusters ? 0.5 : 1 }"
        >
          <input
            ref="PageField"
            v-model="group.nb_clusters"
            class="config-input-field right-gap-sm accent--text"
            style="margin-top: 5px; margin-left: 10px;"
            placeholder="0"
            type="number"
            :min="0"
            @input="handleClusterNumbers"
          >
        </div>
      </div>
    </div>
    <div
      v-if="type == 'extraction_group'"
      class="top-gap-sm"
    >
      <div
        class="radio-box inline-middle"
        style="padding-right: 20px"
      >
        <v-checkbox
          v-model="keepBestOn"
          class="inline-middle right-gap-sm"
          style="margin-top: -7px"
          color="primary"
          :label="$t('dataPoints.keep_values_from')"
        />
        <input
          ref="PageField"
          v-model="group.keep_best"
          class="config-input-field right-gap-sm primary--text"
          style="position: relative; top: -14px"
          placeholder="1"
          type="number"
          :min="1"
          @input="handleKeepBestInput"
        >
        <div
          class="inline-middle clickable"
          style="font-size: 0.9rem; margin-top: -30px;"
          @click="keepBestOn = !keepBestOn"
        >
          {{ $t('dataPoints.best_pages') }}
        </div>
      </div>
      <div
        class="left-gap-lg top-gap-sm"
        style="width: fit-content"
      >
        <div
          class="radio-box inline-top right-gap-sm"
          style="padding-right: 10px"
        >
          <v-checkbox
            v-model="hasForbiddenValues"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :label="$t('dataPoints.forbidden_values')"
            :class="{ 'disabled-text': !keepBestOn }"
            :disabled="!keepBestOn"
          />
        </div>
        <div
          style="text-align: right"
          class="top-gap"
        >
          <div class="inline-top">
            <v-text-field
              v-for="(text, i) in group.forbidden_values"
              :key="i"
              class="word-input"
              style="width: 250px"
              append-icon="fas fa-times"
              variant="outlined"
              color="primary"
              density="compact"
              :value="text"
              :disabled="!hasForbiddenValues"
              @input="(t) => group.forbidden_values[i] = t"
              @click:append="removeExcluded(text)"
            />
            <v-text-field
              ref="sameLineField"
              v-model="newExcluded"
              class="word-input"
              style="width: 250px"
              variant="outlined"
              color="primary"
              density="compact"
              :placeholder="$t('dataPoints.type_new_text')"
              :disabled="!hasForbiddenValues"
              @keyup.enter="handleNewExcluded"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 'extraction_group'">
      <div
        v-for="(option, i) in switchOptions"
        :key="i"
        class="inline-middle radio-box top-gap-sm right-gap-sm"
        style="padding-right: 9px !important;"
      >
        <v-checkbox
          v-model="group[option.value]"
          class="inline-middle right-gap-sm"
          style="margin-top: -7px"
          color="primary"
          :class="{
            'disabled-text': option.value === 'single_item' && group.aggregation_method === 'cluster'
          }"
          :label="option.label"
          :disabled="option.value === 'single_item' && group.aggregation_method === 'cluster'"
          @update:model-value="option.onChange"
        />
        <v-tooltip
          v-if="option.value === 'show_empty_primary'"
          right
        >
          <template #activator="{ props }">
            <v-icon
              class="clickable"
              color="primary"
              style="margin-top: -30px"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ option.info }}
        </v-tooltip>
      </div>
    </div>
    <div class="top-gap">
      <div class="label">
        {{ $t('models.labels') }} ({{ `${selectedName} ${
          group.custom_model_version ? `v. ${group.custom_model_version}` : `– ${$t('models.no_version_selected')}`
        }`
        }})
        <v-chip
          class="ml-2"
          style="background-color: #CCC2FF !important"
          variant="outlined"
        >
          {{ group.labels.length }}
          {{ $tc('models.added_labels', group.labels.length == 1 ? 1 : 2) }}
        </v-chip>
      </div>
      <div
        v-if="group.labels.length > 0 && !group.primary_labels.some(item => item)"
        class="info-box top-gap"
      >
        <small>
          <div
            class="inline-middle"
            style="width: 30px"
          >
            <v-icon
              class="info-icon"
              size="16"
            >
              fas fa-info-circle
            </v-icon>
          </div>
          <div
            class="inline-middle"
            style="width: calc(100% - 30px)"
          >
            {{ $t('dataPoints.primary_info') }}
          </div>
        </small>
      </div>
      <small class="inline-middle top-gap right-gap-sm">
        {{ $t('models.select_version') }}
      </small>
      <v-select
        v-if="group.custom_model_id !== 0"
        v-model="group.custom_model_version"
        class="clickable inline-middle top-gap"
        variant="outlined"
        color="primary"
        density="compact"
        item-title="version"
        item-value="version"
        style="width: 120px"
        :disabled="group.custom_model_id === 0"
        :items="modelVersions"
        :placeholder="$t('models.no_version_selected')"
        @update:model-value="handleVersionChange"
      />
      <v-card
        v-if="group.labels.length > 0"
        class="top-gap label-card"
      >
        <v-row class="label-row font-weight-bold">
          <v-col class="d-flex align-center py-0">
            {{ $t('models.labels') }}
          </v-col>
          <v-col class="d-flex align-center text-center pl-0 pr-2 py-0">
            {{ $t('datatable.header.value_type') }}
          </v-col>
          <v-col
            cols="3"
            class="d-flex justify-center align-center pa-0"
          >
            <div class="right-gap-sm">
              {{ $t('dataPoints.primary') }}
            </div>
            <v-switch
              v-model="allPrimary"
              class="primary-label-switch mt-0"
              color="primary"
              density="compact"
              :disabled="group.primary_labels.length === 1 && group.primary_labels[0] && group.labels.length === 1"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          v-for="(label, i) in group.labels"
          :key="i"
          class="label-row"
          :class="{ 'pb-0': group.value_types[i] === 'regex' }"
        >
          <v-col style="overflow-wrap: anywhere">
            <v-tooltip
              v-if="editName !== i"
              right
            >
              <template #activator="{ props }">
                <span
                  v-bind="props"
                  style="position: relative; top: 3px"
                >
                  {{ group.display_names[i] || label }}
                </span>
              </template>
              {{ label }}
            </v-tooltip>
            <v-text-field
              v-else
              :ref="`displayName-${i}`"
              v-model="group.display_names[i]"
              variant="outlined"
              style="margin-top: -5px"
              color="primary"
              density="compact"
              :rules="[(name) => noDuplicates(name, i) || $t('docTypes.duplicated_names')]"
              @keyup.enter="saveDisplayName(i)"
              @keyup.esc="editName = -1"
            />
            <v-tooltip
              v-if="type === 'extraction_group' && editName !== i"
              right
            >
              <template #activator="{ props }">
                <v-icon
                  class="fas fa-pen clickable ml-3"
                  color="primary"
                  size="16"
                  v-bind="props"
                  @click="editDisplayName(i)"
                />
              </template>
              {{ $t('docTypes.display_name_info') }}
            </v-tooltip>
            <v-icon
              class="close-button clickable"
              color="black"
              size="14"
              @click="deleteLabel(i)"
            >
              fas fa-times
            </v-icon>
          </v-col>
          <v-col>
            <v-select
              v-model="group.value_types[i]"
              class="inline-middle"
              style="width: 150px; margin-top: -5px;"
              variant="outlined"
              color="primary"
              density="compact"
              :items="valueTypes"
              :disabled="type === 'data_point'"
            />
          </v-col>
          <v-col
            cols="3"
            class="text-center pa-0"
          >
            <v-switch
              v-model="group.primary_labels[i]"
              color="primary"
              class="primary-label-switch inline-middle mt-2"
              density="compact"
              :disabled="group.primary_labels.length === 1 && group.primary_labels[0] && group.labels.length === 1"
            />
            <v-icon
              style="position: absolute; right: 7px; top: 7px;"
              color="black"
              size="14"
              @click="deleteLabel(i)"
            >
              fas fa-times
            </v-icon>
          </v-col>
          <v-col
            v-if="group.value_types[i] === 'regex'"
            cols="12"
            class="regex-config px-7 py-2"
            :class="{ 'rounded-card rounded-t-0': i + 1 === group.labels.length }"
          >
            Regex:
            <input
              ref="regexField"
              v-model="group.regex[i].pattern"
              class="regex-field right-gap-sm primary--text"
              @keydown.enter="blurRegexField"
            >
            <div
              class="inline-middle mt-2"
              style="padding-right: 20px"
            >
              {{ $t('dataPoints.replace_match_with') }}
              <input
                ref="substituteField"
                v-model="group.regex[i].substitute"
                class="regex-field primary--text"
                @keydown.enter="blurSubstituteField"
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
      <div
        v-else
        class="no-label-sign mt-5"
      >
        {{ $t('dataPoints.no_labels') }}
      </div>
      <div class="d-flex justify-space-between align-center top-gap">
        <v-select
          v-model="selectedEntity"
          style="width: 300px"
          class="right-gap inline-middle"
          variant="outlined"
          color="primary"
          density="compact"
          item-title="name"
          item-value="id"
          :items="availableLabels"
          :placeholder="$t('models.add_label')"
          :disabled="availableLabels.length === 0"
          :style="{
            opacity: availableLabels.length === 0 ? '0.6' : '1',
          }"
          @update:model-value="addLabel(selectedEntity)"
        />
        <v-btn
          color="primary"
          style="box-shadow: none"
          variant="outlined"
          :disabled="availableLabels.length === 0"
          @click="addAllLabels"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('dataPoints.add_all_labels') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import model_mixin from '@/mixins/model.js';
import feedback_mixin from '@/mixins/feedback.js';

export default {
  name: 'ExtractionGroupConfig',

  mixins: [
    model_mixin,
    feedback_mixin,
  ],

  data() {
    return ({
      selectedEntity: null,
      errorTimeOut: null,
      valueTypes: [
        {
          title: this.$t('datatable.value_type.unspecified'),
          value: 'unspecified',
        },
        {
          title: this.$t('datatable.value_type.date'),
          value: 'date',
        },
        {
          title: this.$t('datatable.value_type.integer'),
          value: 'integer',
        },
        {
          title: this.$t('datatable.value_type.float'),
          value: 'float',
        },
        {
          title: this.$t('datatable.value_type.regex'),
          value: 'regex',
        },
      ],
      editName: -1,
      methods: ['line', 'divided_line', 'column', 'block', 'cluster', 'table_row', 'table_cell'],
      autoDetectClusters: true,
      multi_page: false,
      keepBestOn: false,
      addingExcluded: false,
      newExcluded: '',
      selectedName: '',
      labels: [],
      hasForbiddenValues: false,
      modelVersions: [],
      switchOptions: [
        {
          info: this.$t('dataPoints.empty_primary_info'),
          label: this.$t('dataPoints.create_empty_primary_group'),
          onChange: this.handleShowPrimary,
          value: 'create_empty_primary_group',
        },
        {
          info: this.$t('dataPoints.full_primary_info'),
          label: this.$t('dataPoints.show_empty_primary'),
          onChange: this.handleFullPrimary,
          value: 'show_empty_primary',
        },
        {
          info: this.$t('dataPoints.single_item_group_info'),
          label: this.$t('dataPoints.single_item_group'),
          onChange: (value) => this.group.single_item = value,
          value: 'single_item',
        }
      ],
      allPrimary: false,
    });
  },

  computed: {
    availableLabels() {
        return this.labels.filter(label => !this.group.labels.includes(label.id));
    },

    localMethods() {
      return this.methods.map(method => ({ 
        title: this.$t(`dataPoints.${method}`), 
        value: method 
      }))
    }
  },

  watch: {
    allPrimary(newAllPrimary) {
      this.group.primary_labels = new Array(this.group.labels.length).fill(newAllPrimary);
    },

    addingExcluded(newAddingExcluded) {
      if (newAddingExcluded) {
        setTimeout(() => this.$refs.NewExcludedInput.focus(), 10);
      }
    },

    keepBestOn(keepBestOn) {
      if (keepBestOn && !this.group.keep_best) {
        this.group.keep_best = 1;
      } else if (!keepBestOn) {
        this.group.keep_best = null;
      }
      if (!keepBestOn) {
        this.hasForbiddenValues = false;
      }
    },

    autoDetectClusters(auto) {
      const clusters = this.group.nb_clusters || 1;
      this.group.nb_clusters = auto ? 0 : clusters;
    },
  },

  async mounted() {
    this.handleClusterNumbers();
    this.handleMultiPage();
    this.keepBestOn = !!this.group.keep_best;
    const modelInfo = await this.getModelInfo(this.group.custom_model_id);
    if (modelInfo) {
      this.selectedName = modelInfo.name;
      const version = modelInfo.versions.find(v => v.version === this.group.custom_model_version);
      this.modelVersions = modelInfo.versions;
      this.labels = version.labels.map(entity => ({ id: entity, name: entity }));
    }
    if (this.group.forbidden_values && this.group.forbidden_values.length > 0) {
      this.hasForbiddenValues = true;
    }
    this.allPrimary = this.group.primary_labels.length > 0 && this.group.primary_labels.every(item => item);
  },

  methods: {
    saveDisplayName(i) {
      const textField = this.$refs[`displayName-${i}`][0];
      if (textField.valid) {
        textField.validate(false);
        this.editName = -1;
      }
    },

    addLabel(label) {
      this.group.labels.push(label);
      this.group.value_types.push('unspecified');
      this.group.primary_labels.push(this.group.labels.length === 1);
      this.group.display_names.push('');
      this.group.regex.push({
        pattern: '',
        substitute: '',
      });
      if (this.group.required_precisions) {
        this.group.required_precisions.push(null);
      }
      setTimeout(() => {
        this.selectedEntity = null;
      }, 10);
    },

    addAllLabels() {
      const length = this.availableLabels.length
      const remainingLabels = this.availableLabels.map((item) => item.id);
      const remainingTypes = Array(length).fill('unspecified');
      const remainingPrimary = Array(length).fill(false);
      const remainingNames = Array(length).fill('');
      const remainingRegex = Array(length).fill({
        pattern: '',
        substitute: '',
      });

      this.group.labels.push(...remainingLabels);
      this.group.value_types.push(...remainingTypes);
      this.group.primary_labels.push(...remainingPrimary);
      this.group.display_names.push(...remainingNames);
      this.group.regex.push(...remainingRegex);
    },

    handleVersionChange(newVersion) {
      const selectedVersion = this.modelVersions.find(v => v.version === newVersion);
      this.labels = selectedVersion.labels.map(entity => ({ id: entity, name: entity }));
      const newLabels = [];
      const newTypes = [];
      const newPrimary = [];
      const newNames = [];
      const newRegex = [];
      const newPrecisions = [];
      this.group.labels.forEach((label, i) => {
        if (selectedVersion.labels.includes(label)) {
          newLabels.push(label);
          newTypes.push(this.group.value_types[i]);
          newPrimary.push(this.group.primary_labels[i]);
          newNames.push(this.group.display_names[i]);
          newRegex.push(this.group.regex[i]);
          if (this.group.required_precisions) {
            newPrecisions.push(this.group.required_precisions[i]);
          }
        }
      });
      this.group.labels = newLabels;
      this.group.primary_labels = newPrimary;
      this.group.value_types = newTypes;
      this.group.regex = newRegex;
      this.group.display_names = newNames;
      if (this.group.required_precisions) {
        this.group.required_precisions = newPrecisions;
      }
    },

    blurRegexField() {
      setTimeout(() => {
          this.$refs.regexField.blur();
        }, 10);
    },

    blurSubstituteField() {
      setTimeout(() => {
          this.$refs.substituteField.blur();
        }, 10);
    },

    checkPrimary(index) {
      if (this.errorTimeOut) {
        clearTimeout(this.errorTimeOut);
      }
      if (this.group.primary_labels.every(item => !item)) {
        this.primaryLabelError();
        setTimeout(() => this.$emit('updatePrimary', index), 500);
      } else if (
        this.group.show_empty_primary && !this.group.primary_labels[index]
      ) {
        this.$store.commit('setErrorMessage', this.$t('dataPoints.need_all_primary'));
        this.$store.commit('setSnackbar', true);
        this.errorTimeOut = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')),
          5000
        );
        setTimeout(() => this.$emit('updatePrimary', index), 500);
      }
    },

    deleteLabel(index) {
      this.group.labels.splice(index, 1);
      this.group.value_types.splice(index, 1);
      this.group.primary_labels.splice(index, 1);
      this.group.display_names.splice(index, 1);
      if (this.group.required_precisions) {
        this.group.required_precisions.splice(index, 1);
      }
    },

    editDisplayName(index) {
      this.editName = index;
      setTimeout(() => {
        this.$refs[`displayName-${index}`][0].focus();
      }, 10);
    },

    noDuplicates(name, index) {
      return this.group.display_names.reduce(
        (res, item, i) => res && (index === i || !item || item !== name),
        true
      );
    },

    handleFullPrimary(fullPrimary) {
      if (fullPrimary) {
        this.group.create_empty_primary_group = false;
        this.group.primary_labels = new Array(this.group.primary_labels.length).fill(true);
      }
    },

    handleMultiPage(multiPage) {
      if (multiPage) {
        this.group.multi_page = true;
      }
    },

    handleShowPrimary(showPrimary) {
      if (showPrimary) {
        this.group.show_empty_primary = false;
      }
    },

    removeExcluded(value) {
      this.group.forbidden_values = this.group.forbidden_values.filter(v => v !== value);
    },

    handleNewExcluded() {
      this.group.forbidden_values.push(this.newExcluded.trim());
      this.newExcluded = '';
    },

    handleKeepBestInput() {
      this.keepBestOn = !!this.group.keep_best;
    },

    handleClusterNumbers() {
      this.autoDetectClusters = this.group.nb_clusters == 0;
    },

    handleMethodChange(method) {
      if (method === 'cluster' && this.type === 'data_point') {
        this.group.nb_clusters = 0;
      }
      if (method !== 'blocks') {
        this.group.multi_page = false;
      }
    },
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['updatePrimary'],
}
</script>

<style lang="scss" scoped>
.extraction-group-config {
  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .disabled-text {
    opacity: 0.5;
  }

  .label-card {
    margin-bottom: -5px;
    padding: 0;
  }

  .label-row {
    margin: 0 !important;
    position: relative;
    height: 55px;
    border-bottom: 1px solid #eee;
  }

  .regex-config {
    background-color: #e1dbff;
    font-size: 0.8rem;
  }

  .rounded-card {
    border-radius: 0.3125rem;
  }

  .regex-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 200px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
  .no-label-sign {
    font-style: italic;
    text-align: center;
  }

  .add-all-labels {
    font-size: 12px;

    &__enabled {
      cursor: pointer;
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
      font-style: normal;
    }

    &__disabled {
      cursor: default;
      color: #999;
      text-decoration: none;
      font-style: italic;
    }
  }

  .word-input {
    width: 200px;
    position: relative;
    top: -4px;
    left: -10px;
    margin-top: -11px !important;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .close-button {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 3;
  }
}
</style>