<template>
  <div class="create-dataset">
    <div
      class="clickable top-gap"
      @click="handleBack"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('models.all_datasets') }}
      </h4>
    </div>
    <div>
      <div>
        <v-text-field
          v-model="dataset.name"
          class="top-gap"
          style="max-width: 300px"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('models.type_new_dataset_name')"
          @keydown.enter="saveDataset"
        />
        <DatasetOptions
          type="create"
          :dataset="dataset"
        />
      </div>
      <v-btn
        class="top-gap-sm"
        style="top: -2px; box-shadow: none"
        color="primary"
        :disabled="!nameValid"
        @click="saveDataset"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <div class="message-card top-gap-lg">
      <small>{{ $t('models.min_upload_message', { number: $store.getters.minEntries }) }}</small>
    </div>
    <FileUploader @files-selected="handleFilesSelect" />
  </div>
</template>

<script>
import { EntryAPI } from '@/API/extract/EntryAPI';

import { http } from '@/plugins/axios';
import FileUploader from '@/components/common/elements/Forms/FileUploader';
import DatasetOptions from '@/components/extract/elements/Datasets/DatasetOptions';

export default {
  name: 'CreateDataset',

  components: {
    FileUploader,
    DatasetOptions,
  },

  data() {
    return ({
      files: [],
      dataset: {
        name: '',
        custom_reading_order: 'roma2',
        force_ocr: true,
        use_tables: false,
        use_deskew: true,
        straighten_pages: true,
      }
    })
  },

  computed: {
    nameValid() {
      return this.dataset.name.trim().length > 4;
    },
  },

  methods: {
    handleBack() {
      this.$emit('goBack');
    },

    successMessage() {
      this.$store.commit('setSuccessMessage', this.$t('models.saved_dataset'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    async saveDataset() {
      if (this.nameValid) {
        let success = false;
        if (!this.dataset.id) {
          success = await this.addNew();
        } else {
          success = await this.editNew();
        }
        if (success) {
          await this.uploadFiles();
        }
      }
    },

    async handleFilesSelect(files) {
      this.files = files;
      this.saveDataset();
    },

    async editNew() {
      try {
        await http.put(
          `dataset/${this.dataset.id}`, this.dataset,
        );
        return true;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return false;
      }
    },

    async addNew() {
      try {
        const response = await http.post(
          'dataset/', this.dataset
        );
        this.dataset = {...this.dataset, id: response.data};
        return true;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return false;
      }
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await EntryAPI.post(this.dataset.id, file);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async uploadFiles() {
      const length = this.files.length;
      if (length > 0) {
        if (typeof this.files === 'object') {
          this.files = Object.values(this.files).map(item => item);
        }
        for (let i = 0; i < length; i++) {
          await this.startUpload(this.files[i]);
        }
        this.$emit('selectDataset', this.dataset);
      }
      this.successMessage();
    },
  },

  emits: ['goBack', 'selectDataset'],
}
</script>

<style lang="scss" scoped>
.create-dataset {

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }

  .message-card {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 13px 24px;
    width: 100%;
  }
}
</style>
