<template>
  <div class="search-llm-settings">
    <div class="label text-field-label">
      {{ $t('settings.llm_provider') }}
    </div>
    OpenAI
    <div class="label text-field-label top-gap">
      {{ $t('settings.llm_name') }}
    </div>
    <v-select
      v-if="providerSelectedAndHasOptions"
      v-model="config.llm_name"
      class="inline-middle mt-0"
      style="width: 300px"
      density="compact"
      variant="outlined"
      color="primary"
      placeholder="Select model version"
      :items="options.llm_name[config.llm_provider]"
      :disabled="loading"
      @update:model-value="$emit('update')"
    />
    <v-select
      v-else
      class="inline-middle mt-0"
      style="width: 300px"
      density="compact"
      variant="outlined"
      color="primary"
      placeholder="Select model version"
      disabled
    />

    <div class="label text-field-label top-gap">
      {{ $t('settings.api_key') }}
    </div>
    <v-text-field
      v-model="config.llm_api_key"
      class="inline-middle mt-0"
      style="width: 500px"
      variant="outlined"
      color="primary"
      density="compact"
      :disabled="loading"
      @keyup.enter="handleAPIkeyUpdate"
    />
    <v-btn
      class="left-gap-sm"
      color="primary"
      style="margin-top: -22px;"
      @click="handleAPIkeyUpdate"
      rounded
    >
      <v-icon>
        fas fa-check
      </v-icon>
    </v-btn>

    <div class="label text-field-label">
      {{ $t('prompt') }}
    </div>
    <div>
      <textarea
        v-model="config.llm_prompt"
        class="custom-textarea right-gap inline-top"
        style="width: 500px !important"
        variant="outlined"
        color="primary"
        resize
      />
      <div
        class="inline-top"
        style="margin-left: -9px"
      >
        <v-btn
          class="d-block bottom-gap-sm"
          color="primary"
          @click="$emit('update')"
          rounded
        >
          <v-icon>
            fas fa-check
          </v-icon>
        </v-btn>
        <v-tooltip
          v-if="config.llm_prompt !== options.llm_prompt_default"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="d-block"
              color="primary"
              :disabled="config.llm_prompt === options.llm_prompt_default"
              @click="resetPrompt"
              rounded
            >
              <v-icon>
                fas fa-undo
              </v-icon>
            </v-btn>
          </template>
          {{ $t('search.settings.reset_prompt') }}
        </v-tooltip>
        <v-btn
          v-else
          class="d-block"
          color="primary"
          disabled
          rounded
        >
          <v-icon>
            fas fa-undo
          </v-icon>
        </v-btn>
      </div>
    </div>

    <div class="d-flex align-center top-gap-sm">
      <v-switch
        v-model="config.llm_active"
        class="flex-grow-0"
        color="primary"
        :disabled="!isComplete
          ||(!updatedApiKeyWithValue && !config.llm_active)
          || loading"
        @update:model-value="handleActiveChange"
        hide-details
        inset
      />
      <div class="left-gap">
        <h4>
          {{ $t('active') }}
        </h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.search.llm_active.message`) }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'SearchLLMSettings',

  data() {
    return ({
      updatedApiKeyWithValue: false,
    })
  },

  computed: {
    providerSelectedAndHasOptions() {
      return (!!this.config.llm_provider
        && this.options.llm_name
        && this.options.llm_name[this.config.llm_provider]
      )
    },

    isComplete() {
      return !!this.config.llm_provider && !!this.config.llm_name;
    },
  },

  watch: {
    config(config) {
      if (config.llm_api_key) {
        this.updatedApiKeyWithValue = true;
      }
    }
  },

  methods: {
    resetPrompt() {
      this.config.llm_prompt = this.options.llm_prompt_default;
      this.$emit('update');
    },

    handleAPIkeyUpdate() {
      if (this.config.llm_api_key) {
        this.config.llm_api_key = this.config.llm_api_key.trim();
      }
      this.updatedApiKeyWithValue = !!this.config.llm_api_key;
      this.$emit('update');
    },

    handleProviderUpdate() {
      this.config.llm_name = this.options.llm_name[this.config.llm_provider][0];
      this.$emit('update');
    },

    handleActiveChange() {
      this.$store.commit('setStartedSearch', false);
      this.$store.commit('setSearchResults', {});
      this.$emit('update');
    }
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update'],
}
</script>

<style lang="scss" scoped>
.settings-right {
  width: 600px;
}
</style>