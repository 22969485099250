<template>
  <div class="classify-home-view page-padding py-7">
    <BreadcrumbComponent />
    Classify Home
  </div>
</template>

<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";

export default {
  name: 'ClassifyHomeView',

  components: {
    BreadcrumbComponent,
  },

  async created() {
    this.setBreadcrumb();
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home') },
          { title: this.$t('classify.title') },
        ]
      );
    },
  }
}
</script>
