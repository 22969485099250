<template>
  <div class="value-type-config">
    <div class="label">
      {{ $t('datatable.header.value_type') }}
    </div>
    <div style="margin-top: 5px">
      <v-radio-group
        v-model="metaValueType"
        inline
      >
        <div
          v-for="type, i in metaValueTypes"
          :key="i"
          class="radio-box right-gap-sm"
        >
          <v-radio
            :label="type.text"
            :value="type.value"
            :disabled="extractionType === 'group_based' && type.value !== 'unspecified'"
            :style="{opacity: extractionType === 'group_based' && type.value !== 'unspecified' ? 0.5 : 1}"
          />
        </div>
      </v-radio-group>
      <div
        v-if="metaValueType === 'pre_defined'"
        style="margin-top: -11px"
        class="bottom-gap-sm"
      >
        <v-select
          v-model="dataPoint.value_type"
          class="inline-middle"
          style="width: 300px"
          variant="outlined"
          color="primary"
          density="compact"
          :items="valueTypes"
        />
      </div>
      <div
        v-if="metaValueType === 'regex'"
        style="margin-top: -11px"
      >
        <div class="radio-box inline-middle bottom-gap-sm">
          <span style="position: relative; left: 7px; top: 7px;">
            Regex: 
          </span>
          <input
            ref="regexField"
            v-model="dataPoint.regex_pattern"
            class="regex-field right-gap-sm accent--text"
            @input="handleRegexInput"
            @keydown.enter="blurRegexField"
          >
        </div>
        <div>
          <div
            class="inline-middle radio-box"
            style="padding-right: 20px"
            :style="{
              opacity: substituteEnabled ? 1 : 0.5,
            }"
          >
            <v-checkbox
              v-model="substituteOn"
              class="inline-middle"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.replace_match_with')"
              :disabled="!substituteEnabled"
            />
            <input
              ref="substituteField"
              v-model="dataPoint.substitute"
              class="regex-field accent--text"
              style="top: -15px"
              :disabled="!substituteEnabled"
              @input="handleSubstituteInput"
              @keydown.enter="blurSubstituteField"
            >
          </div>
        </div>
        <RegexInfo class="top-gap-sm bottom-gap" />
      </div>
    </div>
  </div>
</template>

<script>
import RegexInfo from '@/components/extract/elements/DataPoints/RegexInfo';

export default {
  name: 'ValueTypeConfig',

  components: {
    RegexInfo,
  },

  data() {
    return ({
      substituteEnabled: false,
      substituteOn: false,
      initialized: false,
      mounting: true,
      metaValueType: '',
      metaValueTypes: [
        {
          text: this.$t('datatable.value_type.unspecified'),
          value: 'unspecified',
        },
        {
          text: this.$t('datatable.value_type.pre_defined'),
          value: 'pre_defined',
        },
        {
          text: this.$t('datatable.value_type.custom'),
          value: 'regex',
        },
      ],
      valueTypes: [
        {
          title: this.$t('datatable.value_type.date'),
          value: 'date',
        },
        {
          title: this.$t('datatable.value_type.integer'),
          value: 'integer',
        },
        {
          title: this.$t('datatable.value_type.float'),
          value: 'float',
        },
      ],
    });
  },

  computed: {
    isValid() {
      return !(
        this.valueType === 'regex' &&
        (!this.dataPoint.regex_pattern || this.dataPoint.regex_pattern.trim() === '')
      );
    },
  },

  watch: {
    isValid(valid) {
      this.$emit('validityChange', valid);
    },

    metaValueType(type) {
      if (type === 'unspecified') {
        this.dataPoint.value_type = 'unspecified';
      } else if (type === 'pre_defined') {
        if (['unspecified', 'regex'].includes(this.dataPoint.value_type)) {
          this.dataPoint.value_type = 'date';
        }
      } else if (type === 'regex') {
        this.dataPoint.value_type = 'regex';
        if (this.initialized) {
          setTimeout(() => {
            this.$refs.regexField.focus();
          }, 10);
        }
      }
      this.initialized = true;
      this.$emit('change');
    },

    valueType() {
      this.$emit('change');
    },

    substituteOn(on) {
      if (on) {
        this.focusSubstituteField();
      } else {
        this.dataPoint.substitute = '';
      }
      this.$emit('change');
    }
  },

  mounted() {
    this.metaValueType = this.getMetaValueType();
    if (this.metaValueType === 'regex' && this.dataPoint.regex) {
      this.dataPoint.regex_pattern = this.dataPoint.regex.pattern || '';
      this.dataPoint.substitute = this.dataPoint.regex.substitute || '';
      this.substituteOn = this.dataPoint.substitute !== '';
      this.substituteEnabled = this.dataPoint.regex_pattern !== '';
    }
    setTimeout(() => {
      this.mounting = false;
    }, 100);
  },

  methods: {
    blurRegexField() {
      setTimeout(() => {
          this.$refs.regexField.blur();
        }, 10);
    },

    blurSubstituteField() {
      setTimeout(() => {
          this.$refs.substituteField.blur();
        }, 10);
    },

    focusSubstituteField() {
      if (!this.mounting) {
        setTimeout(() => {
          this.$refs.substituteField.focus();
        }, 10);
      }
    },

    handleSubstituteInput() {
      this.substituteOn = this.dataPoint.substitute.trim() !== '';
      this.$emit('change');
    },

    handleRegexInput() {
      this.substituteEnabled = this.dataPoint.regex_pattern.trim() !== '';
      this.$emit('change');
    },

    getMetaValueType() {
      if (this.valueType) {
        switch(this.valueType) {
          case 'unspecified': return 'unspecified';
          case 'regex': return 'regex';
          default: return 'pre_defined';
        }
      }
      this.dataPoint.value_type = 'unspecified';
      return 'unspecified';
    },
  },

  props: {
    dataPoint: {
      type: Object,
      required: true,
    },

    valueType: {
      type: String,
      required: true,
    },

    extractionType: {
      type: String,
      required: true,
    },
  },

  emits: ['validityChange', 'change'],
}
</script>

<style lang="scss" scoped>
  .value-type-config {    
    .section {
      margin-top: 15px;
    }

    .regex-field {
      display: inline-block;
      background-color: #ffffff00;
      padding: 0 !important;
      border: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
      width: 200px;
      position: relative;
      top: 7px;
      left: 5px;

      &:focus {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }

      &:focus-visible {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }
    }
  }
</style>
