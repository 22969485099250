<template>
  <v-dialog
    v-model="openDialog"
    max-width="400"
    content-class="show-overflow"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="copyDataset()"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form
        ref="form"
        @submit.prevent="copyDataset()"
      >
        <h2 class="dialog-title mb-3">
          {{ $t('dataset.copy.title') }}
        </h2>
        {{ $t('dataset.copy.destination') }}
        <DatasetSelect
          class="top-gap-sm"
          :datasets="destinationList"
          :selected="destinationId"
          @selected-changed="(id) => destinationId = id"
          @get-more="getDatasets(numberOfDatasets)"
        />
        <div
          v-if="warningMessage"
          class="warning-message-card top-gap"
        >
          <h4 class="label bottom-gap-sm">
            <v-icon
              class="mb-1"
              size="16"
            >
              fas fa-exclamation-triangle
            </v-icon>
            {{ $t('dataset.copy.warning') }}
          </h4>
          <p align="justify">
            {{ warningMessage }}
          </p>
        </div>
        <div class="d-flex mt-8">
          <div class="dialog-button mr-2">
            <v-btn
              style="box-shadow: none"
              variant="outlined"
              @click="$emit('close')"
              block
              rounded
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
          <div class="dialog-button ml-2">
            <v-btn
              color="primary"
              type="submit"
              :disabled="destinationId === -1"
              block
              rounded
            >
              {{ $t('ok') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { http } from '@/plugins/axios';
import DatasetSelect from "@/components/extract/elements/Datasets/DatasetSelect";

export default {
  name: 'CopyDatasetDialog',

  components: {
    DatasetSelect,
  },

  data() {
    return {
      datasets: [],
      destinationId: -1,
      totalDatasets: 0,
      prefillAnnotations: false,
      openDialog: this.modelValue,
    };
  },

  computed: {
    numberOfDatasets() {
      return this.datasets.length;
    },
    destinationList() {
      return this.datasets.filter(d => d.id !== this.originDataset.id);
    },
    destinationDataset() {
      return this.destinationList.find(dataset => dataset.id === this.destinationId);
    },
    warningMessage() {
      const sharedKeys = ['force_ocr', 'use_tables', 'custom_reading_order', 'straighten_pages', 'use_deskew'];
      if (this.destinationDataset && sharedKeys.some(key => this.destinationDataset[key] !== this.originDataset[key])) {
        return this.$t('dataset.copy.warningMessage');
      }
      return '';
    }
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  async mounted() {
    await this.getDatasets();
    this.destinationId = this.$store.getters.destinationId || this.destinationList[0].id;
  },

  methods: {
    async getDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalDatasets) {
        return;
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await http.get(
          'dataset/', {
            params: {
              limit,
              offset,
            },
          }
        );
        if (offset > 0) {
          this.datasets = [...this.datasets, ...response.data];
        } else {
          this.datasets = response.data;
        }
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async copyDataset() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.post(`dataset/copy/${this.originDataset.id}/into/${this.destinationId}/`);
        this.$store.commit('setSuccessMessage', this.$t('dataset.copy.success'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('close');
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error)
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    originDataset: {
      type: Object,
      required: true,
    }
  },

  emits: ['close', 'update:modelValue'],

}
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 0.875rem;
}

.dialog-button {
  width: 100%;
}

.v-application .v-select {
  max-width: 100%;
}

.warning-message-card {
  padding: 10px;
  margin: 10px 0 0 0;
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 5px;
  background-color: #502BFF33;
}
</style>
