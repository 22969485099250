<template>
  <v-menu
    class="add-extractor-button"
    offset-y
  >
    <template #activator="{ props }">
      <v-btn
        class="primary--text"
        style="top: -1px; box-shadow: none"
        variant="outlined"
        v-bind="props"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t('datatable.header.add') }}
        <v-icon
          size="16"
          end
        >
          fas fa-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in createItems"
        :key="index"
        class="clickable dropdown-button"
        @click="item.action()"
      >
        <v-icon
          :size="item.iconSize"
          start
        >
          {{ item.icon }}
        </v-icon>
        {{ $t(`dataPoints.${item.name}`) }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AddExtractorButton',

  data() {
    return ({
      createItems: [
        {
          name: 'single',
          icon: 'fas fa-cube',
          iconSize: 17,
          action: this.createSingle,
        },
        {
          name: 'group',
          icon: 'fas fa-cubes',
          iconSize: 17,
          action: this.createGroup,
        },
      ],
    });
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  methods: {
    createFromModel(id) {
      this.$router.push({
        name: 'FromModel',
        params: { id },
      });
    },

    handleEditButton(id) {
      if (this.user.role === 'orgadmin') {
        this.$router.push({
          name: 'datapoints',
          params: { id },
        });
      }
    },

    createSingle() {
      if (this.user.role === 'sysadmin') {
        this.createFromModel(this.$route.params.id);
      } else {
        this.handleEditButton(this.$route.params.id);
      }
    },

    createGroup() {
      this.$router.push({
        name: 'labelgroup',
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  color: rgb(var(--v-theme-primary)) !important;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.8rem !important;
  font-weight: 600;
}

.dropdown-button:hover {
  background-color: #f4f5f9;
}
</style>
