<template>
  <div class="classify-settings-view page-padding py-7">
    <BreadcrumbComponent />
    <ProfileSettings />
  </div>
</template>

<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import ProfileSettings from '@/components/common/elements/Settings/ProfileSettings';

export default {
  name: 'ClassifySettingsView',

  components: {
    BreadcrumbComponent,
    ProfileSettings,
  },

  created() {
    this.setBreadcrumb();
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'ClassifyHome'} },
          { title: this.$t('breadcrumb.settings') },
        ]
      );
    },

  }
}
</script>

<style lang="scss">
.classify-settings-view {
  .settings-body {
    width: 1024px;

    .settings-left {
      width: 400px;
      padding-right: 30px;
    }

    .settings-right {
      width: 600px;

      .text-field-label {
        margin-bottom: 3px;
      }

      .radio-group-label {
        margin-bottom: -12px;
      }
    }
  }
}
</style>
