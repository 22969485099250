<template>
  <div class="where-config bottom-gap">
    <div class="label top-gap-sm">
      {{ $t('dataPoints.where') }}
    </div>
    <div
      class="inline-middle radio-box right-gap-sm"
      style="padding-right: 80px; margin-top: 5px"
    >
      <v-checkbox
        v-model="anywhere"
        style="margin-top: -7px"
        color="primary"
        :label="$t('dataPoints.anywhere')"
      />
    </div>

    <div
      class="inline-middle radio-box right-gap-sm"
      style="margin-top: 5px;"
    >
      <v-checkbox
        v-model="pagesOn"
        class="inline-middle"
        style="margin-top: -7px"
        color="primary"
        @update:model-value="handleCheckbox"
      />
      <div
        v-if="!pagesOn"
        class="inline-middle"
        style="opacity: 0.5; position: relative; top: -15px;"
      >
        (ex. 1-15, 19...)
      </div>
      <input
        v-else-if="pagesEditOn"
        ref="PageField"
        v-model="pagesRaw"
        type="text"
        class="page-field accent--text"
        style="margin-top: -7px"
        @keydown.enter="savePages"
        @keydown.esc="pagesEditOn = false"
      >
      <div
        v-else
        class="ellipsis inline-middle"
        style="width: 100px; margin-top: -33px"
      >
        <div
          v-for="(page, i) in where.page_num"
          :key="i"
          class="inline-middle"
        >
          <span v-if="showNumber(page)">
            {{ page * coeff }}
            <span v-if="i + 1 < where.page_num.length">
              ,
              <div style="width: 1rem" />
            </span>
          </span>
          <span v-if="showRange(page)">
            {{ page[0] * coeff }}-{{ page[1] * coeff }}
            <span v-if="i + 1 < where.page_num.length">
              ,
              <div style="width: 1rem" />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div
      class="inline-middle radio-box right-gap-sm"
      style="margin-top: 5px;"
    >
      <v-checkbox
        v-model="locationOn"
        style="margin-top: -7px"
        color="primary"
        :label="$t('dataPoints.location')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhereConfig',

  data() {
    return ({
      anywhere: true,
      pagesOn: false,
      pagesEditOn: false,
      pagesRaw: '',
      coeff: 1,
      from: 'beginning',
      locationOn: false,
    })
  },

  computed: {
    pagesFilled() {
      return this.where.page_num && this.where.page_num.length > 0;
    },

    pages() {
      return this.where.page_num;
    },

    pageLocation() {
      return this.where.page_location;
    }
  },

  watch: {
    locationOn() {
      this.$emit('locationChange', this.locationOn);
      if (!this.locationOn) {
        this.where.page_location = null;
      }
      this.checkAnywhere();
    },

    pageLocation() {
      this.checkAnywhere();
    },

    pages() {
      this.checkAnywhere();
    },

    where() {
      this.checkAnywhere();
    },

    anywhere() {
      if (this.anywhere) {
        this.pagesOn = false;
      }
      if (this.anywhere) {
        this.locationOn = false;
      }
    },

    pagesOn() {
      if (!this.pagesOn) {
        this.where.page_num = [];
      }
    }
  },

  mounted() {
    this.checkAnywhere();
    this.checkPagesOn();
    this.checkCoordinates();
  },

  methods: {
    handleCheckbox() {
      if (this.pagesOn) {
        this.pagesEditOn = true;
        setTimeout(() => this.$refs.PageField.focus(), 100);
      }
    },

    showNumber(page) {
      if (!Number.isInteger(page)) {
        return false;
      }
      return (
        this.from === 'beginning' && page > 0
        || this.from === 'end' && page < 0
      )
    },

    showRange(pageRange) {
      if (Number.isInteger(pageRange)) {
        return false;
      }
      return (
        this.from === 'beginning' && pageRange.every(page => page > 0)
        || this.from === 'end' && pageRange.every(page => page < 0)
      )
    },

    savePages() {
      this.addNewPageNums();
      this.pagesEditOn = false;
      this.pagesRaw = '';
    },

    addNewPageNums() {
      let numArray = this.pagesRaw.split(',');
      numArray = numArray.map(
        page => {
          let pageString = page.trim();
          let pageValue = '';
          if (pageString.includes('-')) {
            let rangeArray = pageString.split('-');
            if (rangeArray.length !== 2) {
              return 0
            }
            rangeArray = rangeArray.map(rangePage => rangePage.trim());
            let range1 = parseInt(rangeArray[0], 10);
            let range2 = parseInt(rangeArray[1], 10);
            if (isNaN(range1) || range1 <= 0 || isNaN(range2) || range2 <= 0 || (this.coeff === -1 && range2 > range1) || (this.coeff === 1 && range2 < range1)) {
              return 0
            }
            return [range1, range2]
          } else {
            pageValue = parseInt(pageString, 10);
            if (isNaN(pageValue) || pageValue <= 0) {
              pageValue = 0;
            }
          }
          return pageValue
        }
      );
      numArray = numArray.filter(item => item !== 0);

      numArray.forEach(pageValue => {
        if (Number.isInteger(pageValue)) {
          this.addNewPageNum(pageValue);
        } else {
          this.addNewPageRange(pageValue);
        }
      });
    },

    preparePages() {
      if (!this.where.page_num) {
        this.where.page_num = [];
      }
    },

    addNewPageNum(value) {
      this.preparePages();
      if (!this.where.page_num.includes(value * this.coeff)) {
        this.where.page_num.push(value * this.coeff);
      }
    },

    addNewPageRange(range) {
      this.preparePages();
      this.where.page_num.push([range[0] * this.coeff, range[1] * this.coeff]);
    },

    checkAnywhere() {
      this.anywhere = !(this.pagesFilled || this.pageLocation || this.locationOn);
    },

    checkPagesOn() {
      if (this.pagesFilled) {
        this.pagesOn = true;
      }
    },

    checkCoordinates() {
      if (this.pageLocation) {
        this.locationOn = true;
      }
    }
  },

  props: {
    where: {
      type: Object,
      required: true,
    },
  },

  emits: ['locationChange'],
}
</script>

<style lang="scss" scoped>
.page-field {
  display: inline-block;
  background-color: #ffffff00;
  padding: 0 !important;
  margin-right: 9px;
  position: relative;
  top: -14px;
  border: none;
  border-bottom: 1px solid rgb(var(--v-theme-primary));
  width: 100px;

  &:focus {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &:focus-visible {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }
}
</style>
