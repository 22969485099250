<template>
  <div
    class="extractor-select d-flex"
    :style="{ opacity: disabled ? 0.5 : 1 }"
  >
    <div
      ref="customSelect"
      class="custom-select noselect"
      :style="{ width: small ? '190px' : '300px' }"
      @click="toggleSelectOn"
    >
      <div :style="{ 'max-width': small ? '190px' : '250px' }">
        <v-icon
          v-if="selectedExtractor && selectedExtractor.type === 'dp'"
          color="primary"
          class="inline-middle"
          :size="17"
          start
        >
          fas fa-cube
        </v-icon>
        <v-icon
          v-if="selectedExtractor && selectedExtractor.type === 'eg'"
          class="inline-middle"
          color="primary"
          :size="17"
          start
        >
          fas fa-cubes
        </v-icon>
        <span
          v-if="numberOfExtractors === 0"
          style="font-style: italic"
        >
          {{ selectedExtractor.text }}
        </span>
        <span v-if="selectedText === ''">
          <span v-if="extractors.every(e => e.type === 'eg')">
            {{ $t('businessRules.insert_group_label') }}
          </span>
          <span v-else>
            {{ $t('businessRules.insert_extractor') }}
          </span>
        </span>
        <ItemName
          v-if="selectedText !== ''"
          class="inline-middle"
          style="width: 80%"
          :item="{name: selectedExtractor.text}"
          :editing-allowed="false"
          :clickable="false"
          :show-id="false"
        />
      </div>
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        :style="{ width: small ? '275px' : '300px' }"
        @focusout="selectOn = false"
      >
        <div
          v-for="(extractor, i) in extractors"
          :ref="`Option${i}`"
          :key="extractor.text"
          class="option"
          :value="extractor.text"
        >
          <div @click="selectedText = extractor.text; $emit('selectedChanged', selectedExtractor);">
            <v-icon
              v-if="extractor.type === 'dp'"
              color="primary"
              :size="17"
              start
            >
              fas fa-cube
            </v-icon>
            <v-icon
              v-if="extractor.type === 'eg'"
              color="primary"
              :size="17"
              start
            >
              fas fa-cubes
            </v-icon>
            <ItemName
              class="inline-middle"
              style="width: 80%"
              :item="{name: extractor.text}"
              :editing-allowed="false"
              :clickable="false"
              :fade-in-slow="false"
              :show-id="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="disabled"
      class="stretch"
    />
  </div>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'ExtractorSelect',

  components: {
    ItemName,
  },

  data() {
    return ({
      selectedText: '',
      selectOn: false,
    })
  },

  computed: {
    numberOfExtractors() {
      return this.extractors.length;
    },

    selectedExtractor() {
      if (this.numberOfExtractors === 0) {
        return {
          name: this.$t('dataPoint.no_extractors'),
          status: ''
        };
      }
      const extractor = this.extractors.find(d => d.text === this.selectedText);
      let name = '';
      return extractor || {
        name,
        status: '', id: -1
      };
    },
  },

  methods: {
    reset() {
      this.selectedText = '';
    },

    toggleSelectOn() {
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          this.$refs.selectPanel.focus();
        }, 50);
      }
    }
  },

  props: {
    extractors: {
      type: Array,
      required: true,
    },

    small: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectedChanged'],
}
</script>

<style lang="scss" scoped>
.extractor-select {
  .custom-select {
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
    }

    .select-panel {
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
      cursor: pointer;
      min-height: 50px;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
}
</style>
