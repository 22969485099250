<template>
  <TableWithFooter
    class="top-gap"
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="6">
        <SortButton
          v-model="sortDesc"
          style="margin-top: -5px"
        />
        {{ $t('dataPoints.label_group') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        style="text-align: center"
      >
        {{ $t('display') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        style="text-align: center"
      >
        {{ $t('docTypes.as_table') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <div
          v-for="item in pages[currentPage]"
          :key="item.id"
        >
          <v-row
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee !important"
          >
            <v-col cols="6">
              <ItemName
                :key="item.id"
                :ref="`name_${item.id}`"
                class="left-gap-lg"
                style="z-index: 202"
                :item="item"
                :editing-allowed="false"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              style="text-align: center"
            >
              <v-switch
                v-if="!disabled"
                v-model="item.display_in_review"
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                @change="updateIntermediate(item)"
                inset
              />
              <v-switch
                v-else
                v-model="item.display_in_review"
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                disabled
                inset
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              style="text-align: center"
            >
              <v-switch
                v-if="!disabled"
                v-model="item.table_display"
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                :disabled="item.aggregation_method === 'cluster'"
                @change="updateTableDisplay(item)"
                inset
              />
              <v-switch
                v-else
                v-model="item.table_display"
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                disabled
                inset
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';


export default {
  name: 'LabelGroupsReviewTable',

  components: {
    ItemName,
    TableWithFooter,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    sortDesc: {
      get() {
        return this.$store.getters.lgSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setLgSortDesc', sortDesc);
      },
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.$emit('getItems', desc);
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    async updateTableDisplay(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/extraction_groups/${group.id}`,
            { table_display: group.table_display }
          );
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      }, waitTime);
    },

    async updateIntermediate(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/extraction_groups/${group.id}`,
            { intermediate: !group.display_in_review }
          );
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      }, waitTime);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['saveName', 'setItems', 'getItems'],
}
</script>
