<template>
  <div
    v-if="searchTotalFilesSet"
    class="search-home-view page-padding py-7"
  >
    <BreadcrumbComponent />
    <SearchPage />
  </div>
</template>

<script>
import { FolderAPI } from '@/API/search/FolderAPI';
import { MetadataAPI } from '@/API/search/MetadataAPI';
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import SearchPage from "@/components/search/views/Query/SearchPage";

export default {
  name: 'SearchQueryView',

  components: {
    BreadcrumbComponent,
    SearchPage,
  },

  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    searchOrg() {
      return this.$store.getters.searchOrg;
    },

    searchTotalFiles() {
      return this.$store.getters.searchTotalFiles;
    },

    searchTotalFilesSet() {
      return this.$store.getters.searchTotalFilesSet;
    },
  },

  watch: {
    async searchOrg(org) {
      await this.getDisplayData(org);
      if (this.$store.getters.searchFolders.length === 0) {
        this.$router.push({name: 'SearchDocuments'});
      }
    },
  },

  async created() {
    if (this.searchOrg === -1) {
      this.$store.commit('setSearchOrg', this.loggedInUser.org_id);
    }
    this.setBreadcrumb();
  },

  methods: {
    setDisplayFilters(options) {
      const filters = {}
      Object.keys(options).forEach(meta => filters[meta] = null);
      this.$store.commit('setSearchDisplayFilters', filters);
    },

    async getDisplayData(org) {
      try {
        const rootId = await SearchConfigAPI.getRoot(org);
        await this.getFolders(rootId);
        const totalFiles = await this.getFilesCount(rootId);
        this.$store.commit('setSearchTotalFiles', totalFiles);
        const metaOptions = await this.getMetaOptions();
        this.setDisplayFilters(metaOptions);
        this.$store.commit('setSearchMetaOptions', metaOptions);
      } catch (err) {
        console.log(err);
      }
    },

    async getFolders(id = null) {
      try {
        const folders = await FolderAPI.getFolders(id);
        this.$store.commit(
          'setSearchFolders',
          folders.filter(f => f.parent_folder_id).map(f => {
            f.open = false;
            f.folders = [];
            f.selected = false;
            return f;
          })
        );
      } catch (err) {
        console.log(err);
      }
    },

    async getFilesCount(id) {
      try {
        const folder = await FolderAPI.getFolder(id);
        return folder.nb_processed_files;
      } catch (err) {
        console.log(err);
      }
    },

    async getMetaOptions() {
      try {
        return await MetadataAPI.getFilters();
      } catch (err) {
        console.log(err);
      }
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home') },
          { title: this.$t('search.title') },
        ]
      );
    },
  }
}
</script>
