<template>
  <div>
    <div class="top-gap-sm">
      <v-row align="center">
        <v-col>
          <TableActions
            type="jobsTable"
            :number-of-selected="selected.length"
            :edit-condition="false"
            :selected="selectedAction"
            @delete-click="deleteDialog = true"
            @selected-changed="(name) => selectedAction = name "
          />
        </v-col>
        <v-col>
          <v-row class="d-flex justify-center align-center">
            <MaxWidthChip
              v-for="state in jobStates"
              :key="state"
              class="ma-4"
              :color="getColor(state)"
              :text-array="[state]"
              @click="handleStatusFilter(state)"
            />
          </v-row>
        </v-col>
      </v-row>

      <TableWithFooter
        no-results-message="workflows.no_results"
        :loading="loading"
        :paginated-items-length="paginatedJobs.length"
        :total="totalJobs"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
        @change-page="(page) => currentPage = page"
      >
        <template #header>
          <!--
          <v-col cols="auto">
            <v-checkbox
              v-model="allSelected"
              style="margin-top: -16px"
              class="inline-middle"
              @change="() => {
                paginatedJobs.forEach(item => {
                  item.selected = allSelected;
                });
              }"
            />
          </v-col>
          -->
          <v-col cols="4">
            {{ $t('workflows.name') }}
          </v-col>
          <v-col cols="1">
            {{ $t('workflows.jobs.id') }}
          </v-col>
          <v-col cols="3">
            {{ $t('workflows.created_at') }}
          </v-col>
          <v-col cols="2">
            {{ $t('workflows.steps.name') }}
          </v-col>
          <v-col cols="1">
            {{ $t('workflows.jobs.is_test') }}
          </v-col>
          <v-col cols="1">
            {{ $t('workflows.steps.state') }}
          </v-col>
        </template>
        <template #body>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              v-for="item in paginatedJobs"
              :key="item.id"
              class="table-row fade-in table-row-height"
            >
              <!--
              <v-col
                class="h-100"
                cols="auto"
              >
                <v-checkbox
                  v-model="item.selected"
                  style="margin-top: -15px"
                />
              </v-col>
              -->
              <v-col cols="4">
                <ItemName
                  :key="item.workflow_id"
                  style="width: 90%"
                  :item="item.workflow"
                  :editing-allowed="false"
                  :clickable="true"
                  @name-click="navigateToWorkflow(item.workflow_id)"
                />
              </v-col>
              <v-col cols="1">
                <ItemName
                  :key="item.id"
                  style="width: 90%"
                  :item="item"
                  :editing-allowed="false"
                  :clickable="true"
                  :show-id="false"
                  @name-click="navigateToJob(item.id)"
                />
              </v-col>
              <v-col cols="3">
                {{ formatDate(item.updated_at) }}
              </v-col>
              <v-col cols="2">
                {{ item.step_name }}
              </v-col>
              <v-col cols="1">
                {{ item.is_test ? 'True' : 'False' }}
              </v-col>
              <v-col cols="1">
                <MaxWidthChip
                  style="margin-top: -5px"
                  :color="getColor(item.state)"
                  :text-array="[item.state]"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </TableWithFooter>
    </div>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('workflows.delete')"
      :message="$t('workflows.delete_confirmation')"
      @confirm="deleteJobs"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import format_mixin from '@/mixins/format.js';
import TableActions from '@/components/common/elements/Tables/TableActions';
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';



export default {
  name: 'JobsTable',

  mixins: [format_mixin],

  components: { TableActions, ItemName, MaxWidthChip, DeleteDialog, TableWithFooter },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`
    );

    return {
      allSelected: false,
      deleteDialog: false,
      totalJobs: 0,
      paginatedWorkflows: [],
      paginatedJobs: [],
      loading: false,
      id: -1,
      steps: [],
      transitions: [],
      jobHistory: [],
      jobStates: ['started', 'error', 'done'],
      statusFilter: '',
      actions: [],
      selectedAction: '',
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    selected: {
      get(){
        if (this.paginatedJobs.length > 0){
          return this.paginatedJobs.filter(item => item.selected);
        }
        return [];
      }
    },
  },

  watch: {
    selectedAction(){
      this.getJobs();
    },
    trimmedFilter: _.debounce(
      function() {
        this.getJobs();
      }, 300
    ),
    itemsPerPage(){
      this.getJobs();
    },
    currentPage(){
      this.getJobs();
    }
  },

  async mounted() {
    this.loading = true;
    await this.getJobs()
  },

  methods: {
    resetCurrentPage(){
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedJobs.forEach(item => {
        item.selected = false;
      });
    },

    handleActionChanged(name){
      this.selectedAction = name;
    },

    async getJobs(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
        try {
            const options = {
              stepFilter: this.selectedAction,
              statusFilter: this.statusFilter,
              uuid: '',
              limit,
              offset,
              sortDesc: true
            }
            const response = await WorkflowAPI.getJobs(null, options);
            const { data, headers } = response;
            this.paginatedJobs = data.map(job => {
              return {
                ...job,
                name: job.id,
                workflow: {
                  id: job.workflow_id,
                  name: job.workflow_name
                }
              };
            });

            this.totalJobs = parseInt(headers['x-total-count'], 10);
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    },

    async deleteJobs(){
      await Promise.all(this.selected.map(async job => {
        if (job.state === 'done'){
          try{
            await WorkflowAPI.deleteJob(job.id);
          } catch (error){
            console.log(error);
          }
        }
      }));
      this.finishDeletion();
    },
    
    async finishDeletion(){
      const { currentPage, itemsPerPage} = this;
      await this.getJobs();
      const lastPage = Math.max(1, Math.ceil(this.totalJobs / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('workflows.delete_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    navigateToWorkflow(id) {
      this.$router.push(
        {
          name: 'ConfigureWorkflow',
          params: {
            id: id,
        }
      });
    },

    navigateToJob(id) {
      this.$router.push(
        {
          name: 'JobHistory',
          params: {
            id: id,
        }
      });
    },

    getColor(state){ 
      // To improve this function in the future
      if (state === 'done'){
        return "#502BFF";
      } else if (state === 'error'){
        return "#FF6F91";
      } else {
        return "#FFA07A";
      }
    },
    handleStatusFilter(state){
      this.statusFilter = state;
      this.getJobs();
    },
  },
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  &:hover {
    border: 2px solid #502BFF !important;
  }
}

</style>
