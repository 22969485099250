/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/entry/';

export class EntryAPI {
  static async get(
    datasetId,
    offset,
    limit,
    nameFilter = '',
    annotatedLabelFilter = null,
    sortDesc = true,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          dataset_id: datasetId,
          name_filter: nameFilter,
          annotated_label_filter: annotatedLabelFilter,
          sort_desc: sortDesc,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async post(datasetId, file) {
    const formData = new FormData();
    formData.append("entry_in", file);
    formData.append("dataset_id", datasetId);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(BASE_PATH, formData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
