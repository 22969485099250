<template>
  <input
    ref="uploader"
    class="d-none"
    type="file"
    :accept="acceptedFiles"
    :multiple="uploadMultiple"
    @change="handleUpload"
  >
</template>

<script>
import file_mixin from '@/mixins/file.js';

export default {
  name: 'FileInput',

  mixins: [file_mixin],

  constants: {
    SINGLE_FILE_UPLOAD_TYPES: ['searchMetadata', 'searchSynonyms'], 
  },

  computed: {
    acceptedFiles() {
      switch (this.type) {
        case 'searchFiles':
          return `${this.$store.getters.acceptedFiles}, .zip`;
        case 'searchMetadata':
          return '.xls, .xlsx, .xlsm';
        case 'searchSynonyms':
          return '.txt';
        default:
          return this.$store.getters.acceptedFiles;
      }
    },

    uploadMultiple() {
      return !this.$options.constants.SINGLE_FILE_UPLOAD_TYPES.includes(this.type);
    }
  },

  methods: {
    click() {
      this.$refs.uploader.click();
    },

    handleUpload({ target: { files }}) {
      if (typeof files === 'object') {
        files = Object.values(files).map(item => item);
      }
      files = this.getValidFiles(files, this.type === 'searchFiles' && '.zip' || null);
      this.$emit('change', files);
    }
  },

  props: {
    type: {
      type: String,
      default: "extract",
    },
  },

  emits: ['change'],
}
</script>