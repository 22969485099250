<template>
  <div
    class="stat-item inline-middle"
    @click="$emit('statClick')"
  >
    <div :class="{ 'inline-middle': linear }">
      {{ (left / right * 100).toFixed(0) }}%
    </div>
    <div
      :style="{ 'font-size': linear ? '1rem' : '0.8rem' }"
      :class="{ 'inline-middle': linear }"
    >
      ({{ left }}/{{ right }})
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatItem',

  props: {
    left: {
      type: Number,
      required: true,
    },

    right: {
      type: Number,
      required: true,
    },

    linear: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['statClick'],
}
</script>

<style>

</style>