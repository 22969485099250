<template>
  <div class="from-model px-1">
    <BackLink :target="backTarget" />
    <div
      class="top-gap-lg"
      style="padding-left: 34px"
    >
      <div class="label">
        {{ $t('forms.name') }}
      </div>
      <v-text-field
        ref="nameField"
        v-model="DPName"
        class="filter-items"
        style="width: 300px; margin-top: 5px;"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('dataPoints.type_name')"
        @keydown.enter="handleEnter"
      />
      <DPModels
        @model-change="model => selectedModel = model"
        @entity-change="entity => selectedCustomEntity = entity"
      />
      <v-btn
        color="primary"
        :disabled="!valid"
        @click="saveDP"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import DPModels from '@/components/extract/elements/DataPoints/DPModels';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';

export default {
  name: 'FromModel',

  components: {
    DPModels,
    BackLink,
  },

  data() {
    return ({
      savedChanges: false,
      DPName: '',
      selectedCustomEntity: '',
      selectedModel: '',
    })
  },

  computed: {
    valid() {
      return this.selectedModel !== '' && this.selectedCustomEntity && this.DPName.trim().length >= 3;
    },

    backTarget() {
      return {
        name: 'DocType',
        params: {
          id: this.$route.params.id,
          tab: 'extractors',
        },
      };
    }
  },

  methods: {
    handleEnter() {
      if (this.valid) {
        this.saveDP();
      }
    },

    async saveDP() {
      this.$store.commit('setLoadingScreen', true);
      const dp = await this.addDP();
      if (dp > -1) {
        await this.addEP(dp);
      }
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.created'));
      this.$store.commit('setSuccessSnackbar', true);
      this.$store.commit('setLoadingScreen', false);
      this.$router.push({
        name: 'DocType',
        params: {
          id: this.$route.params.id,
          tab: 'extractors',
        },
      });
    },

    async addEP(id) {
      const extraction_paths = [{
        what: {
          custom_entity: [[this.selectedModel, this.selectedCustomEntity]],
          regex: null,
          entity: null,
        },
        where: {
          page_num: [],
        },
      }];
      try {
        await http.post(`system_2/extract_path/${id}/`, extraction_paths);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    async addDP() {
      try {
        const {data: id} = await http.post(
          `system_2/data_point/${this.$route.params.id}`,
          { name: this.DPName.trim() }
        );
        return id;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return -1;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.from-model {
  padding: 20px;
  padding-top: 13px;
  padding-left: 22px !important;

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
</style>
