<template>
  <v-card class="new-subgroup-card pa-3 my-4 mx-3">
    <div
      class="d-flex justify-end pb-3"
      style="border-bottom: solid 1px #c8c8c8"
    >
      <v-btn
        class="mr-2 small-button"
        color="black"
        style="box-shadow: none"
        variant="outlined"
        @click="$emit('cancelAddLabel')"
        rounded
      >
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        class="small-button"
        @click="$emit('saveLabel')"
        rounded
      >
        {{ $t("save") }}
      </v-btn>
    </div>
    <div class="d-flex align-center my-2">
      <div class="mr-3">
        {{ $t("models.add_label") }}:
      </div>
      <v-select
        class="mt-0 inline-middle"
        style="width: 300px;"
        variant="outlined"
        color="primary"
        density="compact"
        item-title="name"
        item-value="value"
        :items="availableLabels"
        :disabled="availableLabels.length === 0"
        :placeholder="$t('dataPoints.group_labels')"
        @update:model-value="(label) => $emit('addSubgroupLabel', label)"
      />
    </div>
    <div
      v-if="values.length === 0"
      class="text-center"
      style="color: #b9b9b9"
    >
      {{ $t("dataPoints.no_values") }}
    </div>
    <div
      v-for="(value, i) in values"
      :key="i"
      :ref="`new-value-${i}`"
      class="label-container d-flex align-center noselect"
      @mouseover="selectLabel(value.label)"
      @mouseleave="selectedLabel = ''"
    >
      <div class="label-name-container mr-4">
        {{ getValueLabel(value) }}
      </div>
      <v-text-field
        v-if="editingLabel === value.label"
        :ref="`valueField-${value.label}`"
        v-model="value.valid_value"
        style="margin-top: 20px"
        variant="outlined"
        color="primary"
        density="compact"
        @keydown.esc="$emit('editSubgroupLabel', '')"
        @keydown.enter="saveValue"
      />
      <div
        v-else
        class="value-container pa-1"
      >
        {{ value.valid_value || value.value || "N/A" }}
      </div>
      <v-icon
        v-if="selectedLabel === value.label"
        class="clickable close-icon"
        size="12"
        @click="deleting = value.id; deleteDialog = true;"
        @mouseover="selectLabel(value.label)"
      >
        fas fa-times
      </v-icon>
      <EditButton
        :editing="editingLabel === value.label"
        @stop-edit="$emit('editSubgroupLabel', '')"
        @start-edit="handleClickEdit(value.label)"
      />
    </div>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_label')"
      :message="$t('dataPoints.label_delete_confirmation')"
      @confirm="deleteLabel"
      @close="deleteDialog = false"
    />
  </v-card>
</template>
<script>
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import EditButton from "@/components/extract/elements/Validation/EditButton";

import format_mixin from "@/mixins/format";
import ui_mixin from "@/mixins/ui";

export default {
  name: "AddMissingLabel",

  mixins: [
    format_mixin,
    ui_mixin,
  ],

  components: {
    EditButton,
    DeleteDialog,
  },

  data() {
    return {
      selectedLabel: "",
      deleting: -1,
      deleteDialog: false,
    };
  },

  methods: {
    selectLabel(label) {
      this.selectedLabel = label;
    },

    deleteLabel() {
      this.$emit("deleteSubgroupLabel", this.deleting);
      this.deleting = -1;
      this.deleteDialog = false;
    },

    handleClickEdit(label) {
      this.$emit("editSubgroupLabel", label);
      setTimeout(() => {
        this.$refs[`valueField-${label}`][0].focus();
      }, 200);
    },

    saveValue() {
      this.$emit("editSubgroupLabel", "");
    },
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    labelMap: {
      type: Object,
      required: true,
    },
    takenLabels: {
      type: Array,
      required: true,
    },
    editingLabel: {
      type: String,
      required: true,
    },
  },

  emits: [
    'cancelAddLabel',
    'saveLabel',
    'addSubgroupLabel',
    'editSubgroupLabel',
    'deleteSubgroupLabel',
  ],
};
</script>

<style lang="scss" scoped>
.new-subgroup-card {
  border: solid 1px #ccc2ff;
  background-color: #f4f5f9;
}

.label-container {
  font-size: 0.7rem;
  background-color: white;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 65px;
  color: #4c4c4c;
  overflow: hidden;
  border-bottom: #c8c8c8 1px solid;

  &:last-child {
    border-bottom: #fff 0px solid;
  }

  .icon-bg {
    background-color: white;
    width: 55px;
  }

  .value-container {
    width: 80%;
    font-size: 1rem;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.label-name-container {
  width: 200px;
  text-transform: uppercase;
}
</style>
