<template>
  <div class="recycle-bin-icon">
    <v-icon
      :class="{ active }"
      :color="color"
    >
      fas fa-trash
    </v-icon>
    <v-icon
      size="8"
      class="bin-icon"
      :style="{
        color: color === 'white' ? 'primary-darken3 !important' : 'white !important',
      }"
    >
      fas fa-recycle
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'RecycleBinIcon',

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: 'white',
    },
  },
}
</script>

<style lang="scss" scoped>
.recycle-bin-icon {
  position: relative;

  .bin-icon {
    position: absolute;
    top: 48%;
    left: 6px;
  }
}
</style>
