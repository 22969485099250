<template>
  <v-dialog
    v-model="openDialog"
    max-width="400"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="$emit('confirm', selectedModel)"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-3">
        {{ title }}
      </h2>
      {{ message }}
      <ModelSelect
        class="top-gap"
        :models="customModels ? customModels : []"
        :selected="selectedModel"
        @selected-changed="(id) => {selectedModel = id}"
        @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
      />
      <v-select
        v-model="selectedModelVersion"
        class="clickable inline-middle top-gap"
        variant="outlined"
        color="primary"
        density="compact"
        style="width: 300px"
        item-title="version"
        :disabled="selectedModel === 0"
        :items="modelVersions"
        :placeholder="$t('models.select_version')"
      />
      <div
        class="d-flex"
        style="margin-top: 200px"
      >
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            style="box-shadow: none"
            color="primary"
            :disabled="selectedModel === 0 || !selectedModelVersion"
            @click="$emit('confirm', selectedModel, selectedModelVersion)"
            block
            rounded
          >
            {{ $t('confirm') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelSelect from "@/components/extract/elements/Models/ModelSelect";
import model_mixin from '@/mixins/model.js';

export default {
  name: 'PreAnnotateDialog',

  mixins: [
    model_mixin,
  ],

  components: {
    ModelSelect,
  },

  data() {
    return ({
      selectedModel: 0,
      selectedModelVersion: null,
      openDialog: this.modelValue,
    });
  },

  watch: {
    selectedModel(id) {
      const model = this.customModels.find(model => model.id === id);
      this.modelVersions = model.versions;
    },

    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  mounted() {
    this.getCustomModels();
  },

  props: {
    title: {
      required: true,
      type: String,
    },

    message: {
      required: true,
      type: String
    },

    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['close', 'confirm', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
