<template>
  <div class="action-select">
    <div
      class="custom-select noselect"
      @click="toggleSelectOn"
    >
      <div class="action-name ellipsis">
        <span :style="{'font-style': numberOfActions ? 'normal' : 'italic' }">
          {{ selectedAction.title }}
        </span>
      </div>
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(action, i) in actions"
          :ref="`Option${i}`"
          :key="action.id"
          class="option ellipsis"
          :value="action.id"
          @click="selectedName= action.action"
        >
          {{ action.title }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
 import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
  
  export default {
    name: 'WorkflowActionSelect',

    data() {
        return ({
            selectedName: '',
            selectOn: false,
            actions: [],
            loading: false,
        })
    },
    computed: {
        numberOfActions() {
            return this.actions.length;
        },

        selectedAction(){
            if (this.selectedName === ''){
                return { title: 'Select an action'}
            }
            const action = this.actions.find(action => action.action === this.selectedName);
            return action || { title: 'Select an action'};
        }
        
    },

    watch: {
        selectedName(name){
            setTimeout(() => {
            this.selectOn = false;
        }, 10);
        this.$emit('selectedChanged', name);
    }
    },
    async created() {
        await this.getActions();
        this.selectedName = this.selected;
    },

    methods: {
        async getActions(){
            this.loading = true;
            try {
                const actions = await WorkflowAPI.getActions();
                let nodeOptions = [];
                actions.forEach(action => {
                  if (
                    action.action_type !== 'state' &&
                    action.action_type !== 'python' &&
                    (!action.action || !action.action.startsWith('dummy'))
                  ) {
                    let name = this.$te(`workflows.steps.${action.action}`) ? this.$t(`workflows.steps.${action.action}`) : action.action;
                    nodeOptions.push({
                      title: `${this.$t(`workflows.steps.${action.action_type}`)}: ${name}`,
                      config: action.config,
                      action: action.action,
                      action_type:action.action_type
                    });
                  }
                });
                nodeOptions.sort((a, b) => {
                  const textA = a.title.toUpperCase();
                  const textB = b.title.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this.actions = nodeOptions;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
    },

        toggleSelectOn() {
        this.selectOn = !this.selectOn;
        if (this.selectOn) {
            setTimeout(() => {
            this.$refs.selectPanel.focus();
            }, 50);
        }
    }    
    },
    props: {
        selected: {
            type: String,
            default: ''
        }
    },
    emits: ['selectedChanged'],
}
</script>
  
  <style lang="scss" scoped>
  .action-select {
    .custom-select {
      height: 40px;
      width: 300px;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
      background-color: white;
      padding: 8px;
      padding-right: 30px;
      font-size: 0.9rem;
      position: relative;
      margin-bottom: 20px;
  
      .action-name {
        max-width: 250px;
      }
      
      .open-icon {
        position: absolute;
        right: 11px;
        top: 11px;
      }
  
      .select-panel {
        width: 300px;
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 999;
        background-color: white;
        border-radius: 4px;
        border: solid 1px #C8C8C8;
      }
  
      .select-panel:focus, input:focus{
        outline: none;
      }
  
      .select-panel .option {
        height: 40px;
        padding: 8px;
        background-color: white !important;
        box-shadow: 0 0 10px 100px white inset;
        color: black !important;
      }
    }
  }
  </style>
  