<template>
  <div class="review-view">
    <div
      class="inline-top right-gap-lg"
      style="width: calc(50% - 15px)"
    >
      <v-card style="padding-top: 0px">
        <h4 class="inline-middle label mb-2">
          {{ $t('docTypes.review_active') }}
        </h4>
        <v-switch
          v-model="config.user_correction"
          class="inline-middle left-gap-sm"
          color="primary"
          :disabled="config.is_locked"
          @update:model-value="updateConfig"
          inset
        />
        <p style="font-size: 0.8rem; margin-top: -30px;">
          {{ $t('docTypes.user_correction.message') }}
        </p>
        <div style="margin-top: -10px">
          <h4 class="inline-middle label mb-2">
            {{ $t(`docTypes.seamless_validation_token.name`) }}
          </h4>
          <v-switch
            v-model="config.seamless_validation_token"
            class="inline-middle left-gap-sm"
            color="primary"
            :disabled="config.is_locked"
            @update:model-value="updateToken"
            inset
          />
          <p
            class="bottom-gap-sm"
            style="font-size: 0.8rem; margin-top: -30px;"
          >
            {{ $t(`docTypes.seamless_validation_token.message`) }}
          </p>
          <span class="right-gap-sm form-label font-weight-bold">
            {{ $t(`docTypes.seamless_validation_token_duration.name`) }}
          </span>
          <input
            v-model="config.seamless_validation_token_duration"
            class="input-field input-field--hours right-gap-sm primary--text"
            placeholder="1"
            type="number"
            :min="1"
            :disabled="config.is_locked"
            @input="handleTokenHoursInput"
            @keydown.enter="updateConfig"
          >
          <span class="right-gap form-label font-weight-bold">
            {{ $t(`docTypes.seamless_validation_token_duration.days`) }}
          </span>
        </div>
        <div style="margin-top: -5px">
          <h4 class="inline-middle label mb-2">
            {{ $t(`docTypes.organized.name`) }}
          </h4>
          <v-switch
            v-model="config.organized"
            class="inline-middle left-gap-sm"
            color="primary"
            :disabled="config.is_locked"
            @update:model-value="updateConfig"
            inset
          />
          <p style="font-size: 0.8rem; margin-top: -30px;">
            {{ $t(`docTypes.organized.message`) }}
          </p>
        </div>
      </v-card>
      <AuthorizedReviewers
        :config="config"
        :disabled="lockActions"
        @update-authorized="updateReviewers"
      />
    </div>
    <div
      class="inline-middle"
      style="width: calc(50% - 15px)"
    >
      <div
        class="d-flex justify-space-between"
        style="margin-top: -30px"
      >
        <h4
          class="text-h4 primary--text"
          style="margin-top: 40px"
        >
          {{ $t('docTypes.extractors_displayed_in_review') }}
        </h4>
        <TableActions
          type="extractionAgentReview"
          :number-of-selected="0"
          @filter-change="(filter) => {trimmedFilter = filter; handleFilter()}"
        />
      </div>
      <DataPointsReviewTable
        :all-items="dataPoints"
        :filtered-items="filtered"
        :disabled="lockActions"
        :loading="docTypeLoading"
        @set-items="items => $emit('updateDataPoints', items)"
        @get-items="sortDesc => $emit('getDataPoints', sortDesc)"
        @save-name="$emit('saveDPName')"
      />
      <LabelGroupsReviewTable
        :all-items="labelGroups"
        :filtered-items="filteredLabelGroups"
        :disabled="lockActions"
        :loading="docTypeLoading"
        @set-items="items => $emit('updateLabelGroups', items)"
        @get-items="sortDesc => $emit('getLabelGroups', sortDesc)"
        @save-name="$emit('saveGroupName')"
      />
    </div>
  </div>
</template>

<script>
import { http } from "@/plugins/axios";

import TableActions from '@/components/common/elements/Tables/TableActions';
import DataPointsReviewTable from '@/components/extract/elements/DataPoints/DataPointsReviewTable';
import LabelGroupsReviewTable from '@/components/extract/elements/LabelGroups/LabelGroupsReviewTable';
import AuthorizedReviewers from '@/components/extract/elements/DocTypeConfig/AuthorizedReviewers';

export default {
  name: 'ReviewView',

  components: {
    DataPointsReviewTable,
    LabelGroupsReviewTable,
    TableActions,
    AuthorizedReviewers,
  },

  data() {
    return ({
      trimmedFilter: '',
      itemsPerPage: 20,
      currentPage: 1,
      forceCompute: Math.random(),
      firstAllowed: [],
      savedAllowed: [],
      restrictReviewers: false,
    });
  },

  computed: {
    allowedNum() {
      const allowed = this.config.allowed_basic_users;
      return allowed ? allowed.length : 0;
    },

    loading() {
      return this.docTypeLoading || this.$store.getters.loadingScreen;
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    filtered: {
      cache: false,
      get: function() {
        if (this.dataPoints && this.dataPoints.length > 0) {
          return this.dataPoints.filter(dp => dp.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    filteredLabelGroups: {
      cache: false,
      get: function() {
        if (this.labelGroups && this.labelGroups.length > 0) {
          return this.labelGroups.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },
  },

  async created() {
    if (!this.config.allowed_basic_users) {
      this.config.allowed_basic_users = [];
    }
    if (this.config.allowed_basic_users.length === 0) {
      this.restrictReviewers = false;
    }
  },

  methods: {
    updateReviewers(id, authorized) {
      if (authorized) {
        this.config.allowed_basic_users.push(id);
      } else {
        this.config.allowed_basic_users = this.config.allowed_basic_users.filter(
          user => user !== id
        );
      }
      this.updateConfig();
    },

    getFirstAllowed(idArray) {
      this.firstAllowed = [];
      try {
        idArray.slice(0, 2).forEach(async id => {
          const { data } = await http.get(`auth/api/v1/users/${id}/`);
          this.firstAllowed.push(data);
        })
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    handleTokenHoursInput() {
      if (!this.config.seamless_validation_token_duration) {
        this.config.seamless_validation_token_duration = null;
      }
      this.config.seamless_validation_token = !!this.config.seamless_validation_token_duration;
      this.updateConfig();
    },

    updateToken(tokenOn) {
      if (tokenOn && !this.config.seamless_validation_token_duration) {
        this.config.seamless_validation_token_duration = 1;
      } else if (!tokenOn) {
        this.config.seamless_validation_token_duration = null;
      }
      this.updateConfig();
    },

    async updateConfig() {
      setTimeout(async () => {
        this.$emit('save');
      }, 10);
    },

    async saveCorrectors() {
      const allowed = this.config.allowed_basic_users;
      if (allowed.slice(0, 2).some((item, i) => item !== this.savedAllowed[i])) {
        await this.getFirstAllowed(allowed);
      }
      if (allowed.length === 0) {
        this.firstAllowed = [];
      }
      this.updateConfig();
    },

    handleFilter() {
      if (this.trimmedFilter !== '') {
        this.currentPage = 1;
      }
    },

    prevPage() {
      if (!this.isFirstPage) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (!this.isLastPage) {
        this.currentPage++;
      }
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    dataPoints: {
      required: true,
      type: Array,
    },

    labelGroups: {
      required: true,
      type: Array,
    },

    docTypeLoading: {
      required: true,
      type: Boolean,
    },

    lockActions: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'updateDataPoints',
    'getDataPoints',
    'saveDPName',
    'updateLabelGroups',
    'getLabelGroups',
    'saveGroupName',
    'save',
    'refresh',
  ],
}
</script>
<style lang="scss">
.bottom-nav {
  font-size: 12px;
  width: 350px;
  float: right;
}

.table-row {
  padding: 1rem 1.5rem;
  margin: 0 !important;
  width: 100%;

  &:not(&:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  &__header {
    font-size: 14px;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }
}
.disabled {
  color: #999 !important;
}

.input-field {
  display: inline-block;
  background-color: #ffffff00;
  padding: 0 !important;
  border: none;
  border-bottom: 1px solid rgb(var(--v-theme-primary));

  &:focus {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &:focus-visible {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &--hours {
    width: 70px;
  }

  &--pages {
    width: 160px;
  }
}

.form-label {
  font-size: 0.8rem;
}
</style>
