<template>
  <div class="mailboxes-table">
    <TableActions
      type="mailboxes"
      :number-of-selected="selected.length"
      @edit-click="handleEditName"
      @delete-click="deleteDialog = true"
      @create-click="handleCreateButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="mailboxes.length"
      :total="mailboxes.length"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <v-checkbox
            v-model="allSelected"
            style="margin-top: -16px"
            @change="toggleSelectAll"
          />
        </v-col>
        <v-col>
          {{ $t('mailboxes.mailbox_name') }}
        </v-col>
        <v-col cols="3">
          {{ $t('mailboxes.email') }}
        </v-col>
        <v-col cols="3">
          {{ $t('mailboxes.workflow_name') }}
        </v-col>
        <v-col cols="2">
          {{ $t('mailboxes.last_activity') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in mailboxes"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                style="margin-top: -15px"
                @change="handleSelect"
              />
            </v-col>
            <v-col>
              <template v-if="!['INACTIVE', 'NEW'].includes(item.status)">
                <ItemName
                  :key="item.id"
                  style="z-index: 202"
                  :item="item"
                  :editing-allowed="!!(item.selected)"
                  :editing="editingMailbox === item.id"
                  @save-file-name="(id, newName) => saveName(id, newName)"
                  @name-click="handleConfig(item)"
                />
              </template>
              <template v-else-if="item.status === 'INACTIVE'">
                <v-tooltip bottom>
                  <template #activator="{ props }">
                    <v-icon
                      color="primary"
                      size="16"
                      v-bind="props"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  {{ $t('mailboxes.status_inactive') }}
                </v-tooltip>
                <ItemName
                  class="ml-3"
                  style="width: 95%"
                  :item="item"
                  :editing-allowed="false"
                  :clickable="false"
                />
              </template>
              <template v-else>
                <v-tooltip right>
                  <template #activator="{ props }">
                    <v-icon
                      class="ma-0"
                      color="primary"
                      size="16"
                      v-bind="props"
                      v-on="on"
                    >
                      fas fa-spinner fa-pulse
                    </v-icon>
                    <ItemName
                      class="inline-middle ml-3"
                      style="width: 100%"
                      :item="item"
                      :editing-allowed="false"
                      :clickable="false"
                    />
                  </template>
                  {{ $t('mailboxes.status_new') }}
                </v-tooltip>
              </template>
            </v-col>
            <v-col cols="3">
              {{ item.email }}
            </v-col>
            <v-col cols="3">
              {{ item.workflow_name || '-' }}
            </v-col>
            <v-col cols="2">
              <template v-if="language === 'fr'">
                {{ formatDate(item.last_worker_processed_date, "DD/MM/YYYY HH:mm") }}
              </template>
              <template v-else>
                {{ formatDate(item.last_worker_processed_date, "YYYY/MM/DD HH:mm") }}
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('mailboxes.delete')"
      :message="$t('mailboxes.delete_confirmation')"
      @confirm="deleteMailboxes"
      @close="deleteDialog = false"
    />
    <v-dialog
      v-model="progressDialog"
      max-width="400"
      persistent
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-3">
          {{ $t('mailboxes.deleting_mailbox') }} {{ currentDelete }} / {{ allDelete }}
        </h2>
        <v-progress-linear :value="Math.floor(currentDelete / allDelete * 100)" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import moment from "moment";
import DeleteDialog from '@/components/common/elements/Tables/DeleteDialog';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

  export default {
    name: 'MailboxesTable',

    components: {
      DeleteDialog,
      ItemName,
      TableActions,
      TableWithFooter,
    },

    data() {
      const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

      return {
        allSelected: false,
        loading: false,
        mailboxes: [],
        trimmedFilter: '',
        totalMailboxes: 0,
        deleteDialog: false,
        progressDialog: false,
        currentDelete: 0,
        allDelete: 0,
        editingMailbox: -1,
        itemsPerPage,
        currentPage,
      };
    },

    computed: {
      language() {
        return this.$store.getters.loggedInUser.language;
      },

      selected: {
        get() {
          return this.mailboxes.filter(item => item.selected);
        },
        set() {
          // pass
        },
      },

      totalPages() {
        return Math.ceil(this.totalMailboxes / this.itemsPerPage);
      },
    },

    watch: {
      itemsPerPage() {
        this.resetCurrentPage();
        this.getMailboxes(true);
      },

      currentPage(page) {
        this.allSelected = false;
        this.mailboxes.forEach(item => {
          item.selected = this.allSelected;
        });
        this.getMailboxes((page - 1) * this.itemsPerPage, this.itemsPerPage);
      },

      trimmedFilter: _.debounce(
        function() {
          this.resetCurrentPage();
          this.getMailboxes();
        }, 500
      ),
    },

    async mounted() {
      this.loading = true;
      await this.getWorkflows();
      await this.getMailboxes();
      this.loading = false;
      this.checkStatus();
    },

    methods: {
      formatDate(date, format) {
        if (!date) {
          return '-';
        }
        return moment(date).format(format);
      },

      async checkStatus() {
        if (this.mailboxes.some(mailbox => mailbox.status === 'NEW')) {
          await this.getMailboxes((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
          try {
            this.statusCheck = setTimeout(async () => {
              await this.checkStatus();
            }, 3000);
          } catch (err) {
            console.log(err);
          }
        }
      },

      async getWorkflows() {
        try {
          const { data } = await http.get('workflows/api/v1/workflows/');
          this.workflows = data;
        } catch (error) {
          this.$store.commit('setErrorMessage', this.$t('workflows.error_workflows'));
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },

      async getMailboxes(
        offset = (this.currentPage - 1) * this.itemsPerPage,
        limit = this.itemsPerPage)
      {
        try {
          const { data, headers } = await http.get(
            'adapter/api/v1/mailboxes/',
            {
              params: {
                limit,
                offset,
                type: 'WORKFLOW',
                name_filter: this.trimmedFilter,
              },
            }
          );
          this.mailboxes = data.map(mailbox => {
            mailbox.selected = false;
            if (mailbox.workflow_ref) {
              let activeWorkflowName = null;
              let publishedWorkflowName = null;
              
              for (const workflow of this.workflows){
                if (workflow.uuid === mailbox.workflow_ref){
                  if (workflow.status === 'active'){
                    activeWorkflowName = workflow.name;
                    break
                  }
                  if (workflow.status === 'published' && !publishedWorkflowName){
                    publishedWorkflowName = workflow.name;
                  }
                }
              }
              mailbox.workflow_name = activeWorkflowName || publishedWorkflowName || '';
            } 
            return mailbox;
          });
          this.totalMailboxes = parseInt(headers['x-total-count'], 10);
        } catch (error) {
          this.$store.commit('setErrorMessage', this.$t('mailboxes.mailboxes_error'));
          this.$store.commit('setSnackbar', true);
          console.log(error);
        }
      },

      handleConfig(mailbox) {
        this.$router.push({
          name: 'ConfigureMailbox',
          params: {
            id: mailbox.id,
          },
        });
      },

      handleSelect() {
        this.allSelected = this.mailboxes.every(item => item.selected);
      },

      handleEditName() {
        this.editingMailbox = this.selected[0].id;
      },

      async saveName(id, newName) {
        try {
          const mailbox = this.mailboxes.find(mailbox => mailbox.id === id);
          await http.put(`adapter/api/v1/mailboxes/${id}/`, {
            ...mailbox,
            name: newName,
          });
          await this.getMailboxes((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
        } catch (error) {
          console.log(error);
          this.$store.commit('setSnackbar', true);
        }
      },

      async deleteMailboxes() {
        const total = this.selected.length;
        this.deleteDialog = false;
        this.allDelete = total;
        this.progressDialog = true;
        for (let i = 0; i < total; i++) {
          this.currentDelete = i + 1;
          await this.deleteMailbox(this.selected[i].id);
        }
        this.progressDialog = false;
        this.allSelected = false;
        this.currentPage = Math.max(1, Math.min(this.currentPage, this.totalPages));
        this.getMailboxes((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
        this.$store.commit('setSuccessMessage', `${this.$t('mailboxes.mailboxes_deleted')} (${total}).`);
        this.$store.commit('setSuccessSnackbar', true);
      },

      async deleteMailbox(id) {
        try {
          await http.delete(`adapter/api/v1/mailboxes/${id}/`);
        } catch (error) {
          console.log(error);
          this.$store.commit('setErrorMessage', this.$t('mailboxes.delete_error'));
          this.$store.commit('setSnackbar', true);
        }
      },

      handleCreateButton() {
        this.$router.push({
          name: 'ConfigureMailbox',
          params: {
            id: 'new',
          },
        });
      },

      handleEnter() {
        this.handleConfig(this.mailboxes[0]);
      },

      resetCurrentPage() {
        this.currentPage = 1;
        this.allSelected = false;
        this.mailboxes.forEach(item => {
          item.selected = this.allSelected;
        });
      },

      toggleSelectAll() {
        this.mailboxes.forEach(item => {
          item.selected = this.allSelected;
        });
      },
    },
  }
</script>
