<template>
  <div class="training-models-table">
    <TableWithFooter
      no-results-message="models.no_training"
      :loading="loading"
      :paginated-items-length="trainingClassifiers.length"
      :total="trainingClassifiers.length"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col>
          {{ $t('models.classifiers_in_training') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in trainingClassifiers"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col>
              <div class="ellipsis">
                {{ item.name }}
              </div>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="primary"
                class="small-button"
                style="margin-top: -6px"
                @click="handleDeleteButton(item.name)"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-times
                </v-icon>
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title_training')"
      :message="$t('models.confirm_delete_training')"
      @confirm="deleteModel"
      @close="deleteDialog = false; toDelete = '';"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import model_mixin from '@/mixins/model.js';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

export default {
  name: 'TrainingClassifiersTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableWithFooter,
    DeleteDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      deleteDialog: false,
      toDelete: '',
      loading: false,
      statusCheck: null,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.trainingClassifiers.length / this.itemsPerPage);
    },

    classifiers: {
      get() {
        return this.$store.getters.classifiers;
      },
      set(classifiers) {
        this.$store.commit('setClassifiers', classifiers);
      },
    },

    trainingClassifiers: {
      get() {
        return this.$store.getters.trainingClassifiers;
      },
      set(classifiers) {
        this.$store.commit('setTrainingClassifiers', classifiers);
      },
    },
  },

  watch: {
    deleteDialog(on) {
      if (on) {
        clearTimeout(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    async itemsPerPage() {
      this.currentPage = 1;
      await this.getClassifiers();
      await this.modelsCountCheck();
    },

    async currentPage() {
      await this.getClassifiers();
      await this.modelsCountCheck();
    },
  },

  async mounted() {
    await this.getClassifiers();
    await this.modelsCountCheck();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async refreshModels() {
      // Changing this setup might result in models not loading after training
      this.loading = true;
      setTimeout(async () => {
        await this.getClassifiers();
        await this.modelsCountCheck();
      }, 1000);
      await this.modelsCountCheck();
    },

    async modelsCountCheck() {
      if (this.trainingClassifiers.length > 0) {
        await this.getClassifiers(false);
        try {
          this.statusCheck = setTimeout(async () => {
            await this.modelsCountCheck();
          }, 10000);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async getClassifiers(loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ClassifyModelAPI.getDocModels();
        this.classifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained != null);
        this.trainingClassifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained === null);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async deleteModel() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.delete(`classify/api/v1/models/?name=${this.toDelete}`);
        this.finishDeletion();
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async finishDeletion() {
      this.deleteDialog = false;
      await this.getClassifiers();
      this.currentPage = 1;
      this.toDelete = '';
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton(name) {
      this.deleteDialog = true;
      this.toDelete = name;
    },
  }
}
</script>
