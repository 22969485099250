<template>
  <div class="loader-div">
    <v-icon id="loader">
      fas fa-spinner fa-pulse
    </v-icon>
  </div>
</template>

<script>
import { AuthAPI } from '@/API/authenticator/AuthAPI';
import { UserAPI } from '@/API/authenticator/UserAPI';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import doc_type_mixin from '@/mixins/document_type.js';


export default {
  name: 'TokenValidationView',

  mixins: [doc_type_mixin],

  async created()  {
    this.$store.commit('setLoadingScreen', true);
    try {
      const response = await AuthAPI.validateToken(this.$route.params.provider, this.$route.query);
      if (response.access_token) {
        localStorage.setItem('token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        const user = await UserAPI.getCurrentUser();
        if (user.org_id) {
          const org = await OrgsAPI.getOneOrg(user.org_id);
          user.org_name = org.name;
        }
        this.$store.commit('login', user);
        this.$router.push({ name: 'SuiteHome' });
      }
      this.$store.commit('setLoadingScreen', false);
    } catch (error) {
      console.log(error);
      this.$store.commit('setSnackbar', true);
      this.$store.commit('setLoadingScreen', false);
      this.$router.push({ name: 'logout' });
    }
  },
}
</script>

<style lang="scss" scoped>
.loader-div {
  height: 100%;
  display: flex;
  justify-content: center;

  & #loader {
    font-size: 10em;
  }
}
</style>
