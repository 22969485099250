<template>
  <div class="what-config">
    <div class="label">
      {{ $t('dataPoints.what') }}
    </div>
    <div
      v-if="pathType === 'regex' && regex"
      class="bottom-gap"
      style="margin-top: 7px"
    >
      <div style="margin-bottom: -10px">
        <v-chip
          v-for="regexCode in what.regex"
          :key="regexCode"
          class="bottom-gap-sm right-gap-sm"
          style="background-color: #CCC2FF !important"
          variant="outlined"
          close-icon="fas fa-times"
          @click:close="deleteItem('regex', i)"
          closable
        >
          {{ regexCode }}
        </v-chip>
        <div>
          <v-text-field
            ref="regexField"
            v-model="regexNew"
            class="inline-middle right-gap"
            style="width: 250px"
            variant="outlined"
            color="primary"
            density="compact"
            :placeholder="$t('dataPoints.type_new_regex')"
            @keydown.enter="addItem('regex')"
          />
          <v-btn
            class="inline-middle"
            color="primary"
            style="position: relative; top: -10px"
            :disabled="regexNew.trim() === ''"
            @click="addItem('regex')"
            rounded
          >
            <v-icon
              size="16"
              start
            >
              fas fa-plus
            </v-icon>
            {{ $t('docTypes.add') }}
          </v-btn>
        </div>
      </div>
      <RegexInfo />
    </div>
  </div>
</template>

<script>
import RegexInfo from '@/components/extract/elements/DataPoints/RegexInfo';
import _ from 'lodash';

export default {
  name: 'WhatConfig',

  components: {
    RegexInfo,
  },

  data() {
    return ({
      pathType: '',
      regexNew: '',
      newEntities: [],
      types: {
        regex: 'Regex',
      },
      savedEntities: [],
      savedRegex: [],
      initial: true,
    })
  },

  computed: {
    regex() {
      return this.what.regex;
    },

    entity() {
      return this.what.entity;
    },

    displayEntities() {
      let displayEntities = [];
      this.what.entity.forEach(entity => {
        let value = this.$store.getters.entitiesToOptionsMapping[entity];
        if (!value) {
          value = entity;
        }
        let category = this.$store.getters.entityCategories[value];
        if (category && category.selectable && value !== '' && !displayEntities.includes(value)) {
          displayEntities.push(value);
        }
      });
      return displayEntities
    },

    normalEntities() {
      const normalEntities = [];
      Object.keys(
        this.$store.getters.entityCategories
      ).forEach(entity => {
        if (this.$store.getters.entityCategories[entity].selectable) {
          this.$store.getters.entityCategories[entity].display = this.$t(`entities.${entity}`);
          normalEntities.push(this.$store.getters.entityCategories[entity]);
        }
      });
      return normalEntities;
    },
  },

  watch: {
    pathType() {
      this.what.custom_entity = null;
      if (this.pathType === 'any') {
        this.resetRegex();
        this.what.regex = ['__any__'];
      }
      if (!this.initial) {
        switch (this.pathType) {
          case 'regex':
            this.what.regex = this.savedRegex;
            break;
          case 'entity':
            this.what.entity = this.savedEntities;
            break;
        }
      } else {
        this.initial = false;
      }
    },

    regex() {
      if (this.regex) {
        this.resetEntity();
        this.$emit('activate', 'Default');
      }
    },

    entity(value, prev) {
      if (!_.isEqual(value, prev)) {
        this.resetRegex();
        this.$emit('activate', 'NER');
      }
    }
  },

  mounted() {
    this.pathType = this.startType;
  },

  methods: {
    addItem(section) {
      if (!this.what[section].includes(this[`${section}New`])) {
        this.what[section].push(this[`${section}New`]);
        this.refreshValues(section);
      }
      this[`${section}New`] = '';
    },

    deleteItem(section, i) {
      this.what[section].splice(i, 1);
      this.refreshValues(section);
    },

    refreshValues(section) {
      this.what[section] = [...this.what[section]];
    },

    resetEntity() {
      if (this.what.entity) {
        this.savedEntities = this.what.entity;
      }
      this.what.entity = null;
    },

    resetRegex() {
      if (this.what.regex && !this.what.regex.includes('__any__')) {
        this.savedRegex = this.what.regex;
      }
      this.what.regex = null;
    },
  },

  props: {
    what: {
      type: Object,
      required: true,
    },

    startType: {
      type: String,
      required: true,
    },
  },

  emits: ['activate'],
}
</script>
