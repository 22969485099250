<template>
  <div
    :key="selectedId"
    class="model-select d-flex"
  >
    <div
      class="custom-select noselect"
      :class="{ disabled }"
      @click="toggleSelectOn"
    >
      <div
        v-if="!(filter && selectOn)"
        class="model-name ellipsis"
      >
        <span :style="{'font-style': numberOfModels ? 'normal' : 'italic' }">
          {{ selectedModel.name }}
        </span>
      </div>
      <input
        v-else
        ref="filterInput"
        v-model="rawFilter"
        class="model-name"
        :placeholder="$t('filter')"
        @keydown.enter="handleEnter"
        @keydown.esc="handleEscape"
      >
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(model, i) in models"
          :ref="`Option${i}`"
          :key="model.id"
          class="option ellipsis"
          :value="model.id"
          @click="handleSelect(model.id)"
        >
          {{ model.name }}
        </div>
        <div
          v-if="trimmedFilter && models.length === 0"
          class="option no-results"
        >
          {{ $t('docTypes.no_results') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ModelSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      rawFilter: '',
    })
  },

  computed: {
    lastPos() {
      return this.numberOfModels - 1;
    },

    numberOfModels() {
      return this.models.length;
    },

    selectedModel() {
      if (this.selectedId === 0) {
        return {name: this.$t('dataPoints.select_model'), status: ''};
      }
      const model = this.models.find(d => d.id === this.selectedId);
      return model || { name: this.$t('models.no'), status: '' };
    },

    trimmedFilter() {
      return this.rawFilter.toLowerCase().trim();
    },
  },

  watch: {
    selected() {
      this.selectedId = this.selected;
    },

    selectedId(id) {
      setTimeout(() => {
        this.selectOn = false;
      }, 10);
      this.$emit('selectedChanged', id);
    },

    trimmedFilter: _.debounce(function() {
      const reset = true;
      this.$emit('getMore', this.trimmedFilter, reset);
    }, 300),
  },

  mounted() {
    this.selectedId = this.selected;
  },

  methods: {
    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          const reset = false;
          this.$emit('getMore', this.trimmedFilter, reset);
        }
      }
    },

    handleSelect(model_id) {
      this.selectedId = model_id;
      this.rawFilter = '';
    },

    handleEnter(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.handleSelect(this.models[0] && this.models[0].id || 0);
      this.selectOn = false;
    },

    handleEscape(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.selectOn = false;
      this.rawFilter = '';

    },

    toggleSelectOn() {
      if (this.disabled) {
        return;
      }
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          const filterInput = this.$refs.filterInput;
          if (filterInput) {
            filterInput.focus();
          }
        }, 50);
      } else {
        this.rawFilter = '';
      }
    }
  },

  props: {
    models: {
      type: Array,
      required: true,
    },

    selected: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['getMore', 'selectedChanged'],
}
</script>

<style lang="scss" scoped>
.model-select {
  .custom-select {
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .model-name {
      max-width: 250px;
    }

    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
    }

    .select-panel {
      width: 100%;
      max-width: 300px;
      max-height: 285px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 40px;
      left: -1px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;

      .option {
        height: 40px;
        padding: 8px;
        background-color: white !important;
        box-shadow: 0 0 10px 100px white inset;
        color: black !important;

        &.no-results {
          font-style: italic;
          color: rgb(var(--v-theme-grey-darken3)) !important;
        }
      }
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .disabled {
      background-color: #F5F5F5;
      color: #C8C8C8;
      cursor: not-allowed;
    }
  }
}
</style>
