<template>
  <div class="extraction-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      :loading="countLoading"
      wide
    />
    <ModelsTable
      v-if="activeTab === 'models'"
      @create-click="activeTab = 'training'"
    />
    <TrainingView
      v-if="activeTab === 'training'"
      :dataset-id="datasetId"
      @change-tab="tab => activeTab = tab"
    />
  </div>
</template>

<script>
import { ModelAPI } from '@/API/extract/ModelAPI';
import model_mixin from '@/mixins/model.js';
import ModelsTable from '@/components/extract/elements/Models/ModelsTable';
import TrainingView from '@/components/extract/views/Studio/TrainingView';
import PageTabs from '@/components/common/elements/General/PageTabs';

export default {
  name: 'ExtractionView',

  mixins: [
    model_mixin,
  ],

  components: {
    ModelsTable,
    TrainingView,
    PageTabs,
  },

  data() {
    return ({
      activeTab: 'models',
      loaded: false,
      statusCheck: null,
      countLoading: false,
    });
  },

  computed: {
    tabItems() {
      return [
        {
          title: this.$t('models.active'),
          bigNumber: this.totalActiveModelsDisplay,
          name: 'models',
        },
        {
          title: this.$t('models.training'),
          bigNumber: this.totalTrainingModelsDisplay,
          name: 'training',
        }
      ];
    },
  },

  created() {
    if (this.datasetId || this.startView === 'training') {
      this.activeTab = 'training';
    }
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async modelsCountCheck() {
      if (this.totalTrainingModelsDisplay > 0) {
        await this.getModelsCount('active');
        await this.getModelsCount('pending');
        try {
          this.statusCheck = setTimeout(async () => {
            await this.modelsCountCheck();
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      }
    },
    
    async getModelsCount(status) {
      try {
        this.countLoading = true;
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );  
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.countLoading = false;
      }
    },
  },

  props: {
    datasetId: {
      type: Number,
      default: 0,
    },

    startView: {
      type: String,
      default: 'models',
    },
  },
}
</script>

<style lang="scss" scoped>
.extraction-view {
  height: fit-content;
  &__full {
    padding: 0;
  }
}
</style>
