<template>
  <v-row
    class="empty-group-validator fill-height value-row ma-0"
    align="center"
    justify="center"
  >
    <v-col
      cols="3"
      class="px-0 text-uppercase data-point-name"
    >
      <v-tooltip
        color="#423f4f"
        right
      >
        <template #activator="{ props }">
          <div
            class="flex-grow-1"
            v-bind="props"
          >
            <div class="ellipsis">
              {{ group.group_name }}
            </div>
          </div>
        </template>
        <div
          class="ellipsis"
          style="color: #fafafa"
        >
          {{ group.group_name }}
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="1" />
    <v-col
      cols="4"
      class="pa-0"
    >
      <div class="value-div pa-1">
        N/A
      </div>
    </v-col>
    <v-col cols="1" />
    <v-col
      cols="3"
      class="px-0"
    />
    <div class="d-flex justify-center header-buttons">
      <v-icon
        v-if="!inCorrections"
        color="white"
        class="verify-button--disabled"
        style="margin-right: 10px"
        size="17"
      >
        fas fa-times
      </v-icon>
      <v-icon
        color="white"
        class="verify-button--disabled"
        size="17"
      >
        fas fa-check
      </v-icon>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "EmptyGroupValidator",

  computed: {
    inCorrections() {
      return this.$route.name === 'DocCorrections';
    },
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.value-row {
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid transparent;
  &:not(.value-row:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
  }
  &__active {
    padding-bottom: 8px !important;
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }
}
.data-point-name {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}

.value-div {
  font-size: 14px;
  line-height: 1.5rem;
}

.value-input {
  resize: none;
  width: 100%;
  border-radius: 5px;
  &:focus {
    outline: none;
    background-color: #eee;
  }
}

.verify-button--disabled {
  cursor: not-allowed;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  background-color: #ddd !important;
}

.header-buttons {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translate(0, -50%);
}
</style>
