<template>
  <div class="authorized-reviewers top-gap-lg">
    <div
      class="d-flex justify-space-between"
      style="margin-top: -30px"
    >
      <h4
        class="text-h4 primary--text"
        style="margin-top: 40px"
      >
        {{ $t('docTypes.reviewers') }}
      </h4>
      <TableActions
        type="reviewers"
        :number-of-selected="0"
        @filter-change="(filter) => trimmedFilter = filter"
      />
    </div>
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedUsers.length"
      :total="totalUsers"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      include-footer-in-table-card
    >
      <template #header>
        <v-col cols="8">
          <SortButton
            v-model="sortDesc"
            style="margin-top: -5px"
          />
          {{ $t('forms.email') }}
        </v-col>
        <v-col class="text-center">
          {{ $t('authorized') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedUsers"
            :key="item.id"
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee !important"
          >
            <v-col cols="8">
              <ItemName
                :key="item.id"
                class="left-gap-lg"
                :item="{...item, name: `${item.email}`}"
                :editing-allowed="false"
                :clickable="false"
              />
            </v-col>
            <v-col class="text-center">
              <v-switch
                v-if="!disabled"
                v-model="item.authorized_reviewer"
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                @update:model-value="v => updateAuthorized(item.id, v)"
                inset
              />
              <v-switch
                v-else
                style="display: inline-block; margin-top: -17px;"
                color="primary"
                inset
                disabled
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
  </div>
</template>

<script>
import _ from 'lodash';
import { UserAPI } from '@/API/authenticator/UserAPI';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';


export default {
  name: 'AuthorizedReviewers',

  components: { ItemName, TableActions, SortButton, TableWithFooter },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      trimmedFilter: '',
      sortDesc: true,
      loading: false,
      paginatedUsers: [],
      totalUsers: 0,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {  },

  watch: {
    sortDesc() {
      this.getUsers();
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getUsers();
    },

    currentPage(page) {
      this.getUsers(this.itemsPerPage * (page - 1), this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getUsers();
      }, 300
    ),
  },

  created() {
    this.getUsers();
  },

  methods: {
    updateAuthorized(id, authorized) {
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(() => {
        this.$emit('updateAuthorized', id, authorized);
      }, waitTime);
    },

    async refreshUsers() {
      this.paginatedUsers = [];
      await this.getUsers();
      this.currentPage = 1;
    },

    async getUsers(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        const response = await UserAPI.getAllUsers(
          limit,
          offset,
          this.trimmedFilter || '',
          null,
          this.sortDesc,
          'basic',
        );
        this.paginatedUsers = response.data.map(user => {
          user.authorized_reviewer = this.config.allowed_basic_users.includes(user.id);
          return user;
        });
        this.totalUsers = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['updateAuthorized'],
}
</script>
