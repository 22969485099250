<template>
  <div class="search-sources top-gap-sm">
    <h4>
      <v-icon style="margin-right: 5px">
        fas fa-paperclip
      </v-icon>
      {{ $t('search.home.sources') }}
    </h4>
    <div class="top-gap">
      <a
        v-for="(chunk, i) in searchResults.displayChunks.slice(0, maxSources)"
        :key="i"
        target="_blank"
        :href="chunk.url"
      >
        <v-card
          class="bottom-gap right-gap inline-top"
          style="width: calc((40vw - 80px) / 4); padding: 10px;"
          :class="{ 'highlighted-border': hoveredImage === chunk.image }"
          @mouseover="setHovered(chunk.image, chunk.id)"
          @mouseleave="setHovered(null, null)"
        >
          <div class="text-center">
            <ImageWithFallback
              :img-attributes="{
                width: '100%',
                style: 'border: 1px lightgrey solid',
                src: chunk.image,
              }"
              @loaded="chunk.imageLoaded = true"
            />
          </div>
          <div class="result-info">
            <ItemName
              class="noselect inline-top"
              style="max-width: 100%"
              :max-width="100"
              :item="{ name: `${chunk.meta.path.split('/').pop()} (p.${chunk.meta.page})` }"
              :editing-allowed="false"
              :show-id="false"
              :fade-in-slow="false"
            />
          </div>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-if="showFavoriteButton === chunk.id && chunk.imageLoaded"
                v-bind="props"
                class="small-button favorite-button"
                color="primary"
                @click="handleFavoriteClick"
              >
                <v-icon size="10">
                  {{ favorites.includes(chunk.meta.file_id) ? 'fas fa-check' : 'fas fa-star' }}
                </v-icon>
              </v-btn>
            </template>
            {{ favorites.includes(chunk.meta.file_id) ? $t('search.home.remove_from_favorites') : $t('search.home.add_to_favorites') }}
          </v-tooltip>
        </v-card>
      </a>
      <v-btn
        v-if="searchResults.displayChunks.length > maxSources"
        color="primary"
        @click="maxSources = searchResults.displayChunks.length"
        rounded
      >
        {{ $t('search.home.view_more', {number: searchResults.displayChunks.length - maxSources}) }}
      </v-btn>
    </div>
    <FavoriteDialog
      v-model="favoriteDialog"
      :doc-id="docToFavorite"
    />
  </div>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import ImageWithFallback from '@/components/common/elements/General/ImageWithFallback';
import FavoriteDialog from '@/components/search/elements/Home/FavoriteDialog';

export default {
  name: 'SearchSources',

  components: {
    ItemName,
    ImageWithFallback,
    FavoriteDialog,
  },

  data() {
    return ({
      maxSources: 3,
      showFavoriteButton: null,
      favoriteDialog: false,
      docToFavorite: 0,
    });
  },

  computed: {
    hoveredImage() {
      return this.$store.getters.searchHoveredImage;
    },

    searchResults() {
      return this.$store.getters.searchResults;
    },
  },

  methods: {
    handleFavoriteClick(event) {
      event.preventDefault();
      const chunk = this.searchResults.displayChunks.find(c => c.id === this.showFavoriteButton);
      if (chunk) {
        const isFavorite = this.favorites.includes(chunk.meta.file_id);
        if (!isFavorite) {
          this.docToFavorite = chunk.meta.file_id;
          this.favoriteDialog = true;
        } else {
          this.$emit('removeFromFavorites', chunk.meta.file_id);
        }
      }
    },

    setHovered(image, id) {
      this.showFavoriteButton = id;
      this.$store.commit('setSearchHoveredImage', image);
    },
  },

  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },

  emits: [ 'removeFromFavorites' ],
}
</script>

<style lang="scss" scoped>
.result-info {
  font-size: 0.8rem;
  color: rgb(var(--v-theme-grey-base)) !important;
}

.highlighted-border {
  outline: rgb(var(--v-theme-primary)) 2px solid !important;
}

.favorite-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
