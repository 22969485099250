<template>
  <div class="classifiers-view top-gap-lg">
    <div style="margin-top: 50px !important">
      <TableActions
        type="classifiers"
        :number-of-selected="selected.length"
        @delete-click="deleteDialog = true"
        @create-click="$emit('createClick')"
      />
      <TableWithFooter
        :loading="loading"
        :paginated-items-length="pages[currentPage]?.length ?? 0"
        :total="classifiers.length"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
        @change-page="(page) => currentPage = page"
      >
        <template #header>
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="inline-middle"
              style="margin-top: -16px"
            />
          </v-col>
          <v-col cols="4">
            {{ $t('classifiers.name') }}
          </v-col>
          <v-col cols="3">
            {{ $t('classifiers.categories') }}
          </v-col>
          <v-col cols="2">
            {{ $t('classifiers.trained') }}
          </v-col>
          <v-col cols="1">
            {{ $t('classifiers.f_score') }}
          </v-col>
          <v-col cols="1">
            {{ $t('classifiers.precision') }}
          </v-col>
        </template>
        <template #body>
          <v-container
            class="pa-0"
            fluid
          >
            <div
              v-for="item in pages[currentPage]"
              :key="item.id"
            >
              <v-row
                class="table-row fade-in table-row-height"
                style="border-bottom: 1px solid #eee"
              >
                <v-col cols="auto">
                  <v-checkbox
                    v-model="item.selected"
                    class="left-gap"
                    style="margin-top: -15px"
                    @change="classifiers = [...classifiers]"
                  />
                </v-col>
                <v-col cols="4">
                  <div
                    v-if="item.trained === null"
                    class="inline-middle"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <v-icon
                          class="right-gap-sm"
                          color="primary"
                          size="16"
                          v-bind="props"
                        >
                          fas fa-spinner fa-pulse
                        </v-icon>
                      </template>
                      {{ $t('models.training_in_progress') }}
                    </v-tooltip>
                  </div>
                  <div
                    v-else-if="!item.trained"
                    class="inline-middle"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <v-icon
                          class="right-gap-sm"
                          style="top: -2px"
                          color="primary"
                          size="16"
                          v-bind="props"
                        >
                          fas fa-exclamation-circle
                        </v-icon>
                      </template>
                      {{ $t('models.training_failed') }}
                    </v-tooltip>
                  </div>
                  <ItemName
                    :key="item.id"
                    class="inline-middle"
                    style="margin-top: -3px"
                    :style="{ width: !item.trained ? '80%' : '100%' }"
                    :item="item"
                    :editing-allowed="false"
                    :clickable="!!item.trained"
                    @name-click="goToClassifierInfo(item)"
                  />
                </v-col>
                <v-col cols="3">
                  <div
                    v-if="item.categories.length > 0"
                    style="margin-top: -5px; overflow: hidden; white-space: nowrap;"
                  >
                    <MaxWidthChip
                      color="#502BFF"
                      :text-array="[item.categories[0].name]"
                    />
                    <div
                      v-if="item.categories.length > 1"
                      class="inline-middle"
                    >
                      <MaxWidthChip
                        color="#502BFF"
                        :text-array="[`+${item.categories.length - 1}`]"
                      />
                      <v-tooltip
                        v-if="expanded.length === 0 || expanded[0].id !== item.id"
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <v-icon
                            class="clickable"
                            style="margin-top: -9px"
                            size="16"
                            v-bind="props"
                            @click="expanded = [item]"
                          >
                            fas fa-chevron-right
                          </v-icon>
                        </template>
                        <span style="color: white">
                          {{ $t('show_all') }}
                        </span>
                      </v-tooltip>
                      <v-icon
                        v-else
                        class="clickable"
                        style="margin-top: -9px"
                        size="16"
                        @click="expanded = []"
                      >
                        fas fa-chevron-down
                      </v-icon>
                    </div>
                  </div>
                  <div
                    v-else
                    style="margin-top: -4px"
                  >
                    <MaxWidthChip
                      color="#999999"
                      :text-array="[$tc('datatable.header.none', 2)]"
                    />
                  </div>
                </v-col>
                <v-col cols="2">
                  <span v-if="!item.training_result">
                    —
                  </span>
                  <span v-else>
                    {{ formatDate(item.training_result.date) }}
                  </span>
                </v-col>
                <v-col cols="1">
                  <span v-if="!item.training_result">
                    —
                  </span>
                  <span v-else>
                    {{ Math.round(item.training_result.training_score * 100) }}%
                  </span>
                </v-col>
                <v-col cols="1">
                  <span v-if="item.precision === null">
                    —
                  </span>
                  <span v-else>
                    {{ Math.round(item.precision * 100) }}%
                  </span>
                </v-col>
              </v-row>
              <v-row
                v-if="expanded.length > 0 && expanded[0].id === item.id"
                class="table-row"
                style="background-color: rgb(var(--v-theme-grey-darken1))"
              >
                <v-col cols="auto" />
                <v-col cols="4" />
                <v-col
                  cols="6"
                  style="margin-left: 45px"
                >
                  <MaxWidthChip
                    v-for="(category, i) in item.categories"
                    :key="i"
                    color="#502BFF"
                    :text-array="[category.name]"
                    show-full
                  />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </template>
      </TableWithFooter>
      <DeleteDialog
        v-model="deleteDialog"
        :title="$t('classifiers.delete')"
        :message="$t('classifiers.delete_confirmation')"
        @confirm="deleteClassifiers"
        @close="deleteDialog = false"
      />
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';

import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

import format_mixin from '@/mixins/format.js';

export default {
  name: 'ClassifiersView',

  mixins: [format_mixin],

  components: {
    TableActions,
    DeleteDialog,
    ItemName,
    TableWithFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      deleteDialog: false,
      loading: false,
      expanded: [],
      snippetName: '',
      statusCheck: null,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.classifiers.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.classifiers];
        }
        return pages;
      },
      set() {
        // pass
      }
    },

    classifiers: {
      get() {
        return this.$store.getters.classifiers;
      },
      set(classifiers) {
        this.$store.commit('setClassifiers', classifiers);
      },
    },

    trainingClassifiers: {
      get() {
        return this.$store.getters.trainingClassifiers;
      },
      set(classifiers) {
        this.$store.commit('setTrainingClassifiers', classifiers);
      },
    },

    selected: {
      get() {
        if (this.classifiers.length > 0) {
          return this.classifiers.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.classifiers.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.classifiers = [...selected];
      }
    },
  },

  watch: {
    sortDesc() {
      this.getClassifiers();
    },

    deleteDialog(on) {
      if (on) {
        clearTimeout(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },

    currentPage() {
      this.allSelected = false;
      this.classifiers.forEach(item => {
        item.selected = this.allSelected;
      });
    },
  },

  async mounted() {
    await this.modelsCountCheck();
  },

  unmounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async modelsCountCheck() {
      if (this.trainingClassifiers.length > 0) {
        await this.getClassifiers(false);
        try {
          this.statusCheck = setTimeout(async () => {
            await this.modelsCountCheck();
          }, 10000);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async getClassifiers(loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ClassifyModelAPI.getDocModels(this.sortDesc);
        this.classifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained != null);
        this.trainingClassifiers = response.data.filter(c => c.model_type === 'document_models' && c.trained === null);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    goToClassifierInfo(item) {
      if (item.trained) {
        this.$router.push(`/suite/studio/models/classification/${item.name}`);
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.classifiers.forEach(item => {
        item.selected = false;
      });
    },

    async updateClassifier(classifier) {
      this.$store.commit('setLoadingScreen', true);
      try {
        const action = classifier.active ? 'activate': 'deactivate';
        await http.put(`classify/api/v1/models/?name=${classifier.name}&action=${action}`, classifier);
        this.$store.commit('setLoadingScreen', false);
        await this.$store.commit(
          'setSuccessMessage', this.$t('classifiers.updated')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },

    async deleteClassifiers() {
      await Promise.all(this.selected.map(async c => {
        try {
          return await http.delete(`classify/api/v1/models/?name=${c.name}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getClassifiers(true);
      const lastPage = Math.max(1, Math.ceil(this.classifiers.length / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('classifiers.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },
  },

  emits: ['createClick'],
}
</script>
