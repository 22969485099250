<template>
  <div class="py-6">
    <div class="d-flex">
      <div
        v-if="userRole === 'sysadmin'"
        class="mr-6"
      >
        <label
          for="org-select"
          class="mr-4 inline-middle"
        >
          {{ $t('stats.org_select') }}
        </label>
        <v-select
          id="org-select"
          v-model="orgId"
          class="inline-middle mt-0"
          variant="outlined"
          color="primary"
          density="compact"
          item-title="name"
          item-value="id"
          style="width: 200px; margin-top: 0 !important"
          :items="organizations"
        />
      </div>
      <DocTypeSelect
        v-else
        class="inline-middle right-gap-sm"
        @selected-changed="(id) => {docTypeId = id}"
        show-every
      />
      <v-radio-group
        v-model="dateRange"
        class="inline-middle"
        style="margin-top: 0px"
        inline
      >
        <div class="inline-middle radio-box right-gap-sm mb-0">
          <v-radio
            :label="$t('traces.last_four_weeks')"
            :value="1"
          />
        </div>
        <div class="inline-middle radio-box right-gap-sm mb-0">
          <v-radio
            :label="$t('traces.six_months')"
            :value="6"
          />
        </div>
        <div class="inline-middle radio-box right-gap-sm mb-0">
          <v-radio
            :label="$t('traces.last_year')"
            :value="12"
          />
        </div>
      </v-radio-group>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            color="primary"
            style="height: 36px"
            v-bind="props"
            @click="downloadTracesAggregates"
            rounded
          >
            <v-icon start>
              fas fa-download
            </v-icon>
            {{ $t('traces.download') }}
          </v-btn>
        </template>
        {{ $t("traces.download_detail") }}
      </v-tooltip>
    </div>
    <TracesCharts
      style="margin-top: -20px"
      :categories="actions[currentAction] || []"
      :current-date="to_date"
      :show-charts="showCharts[currentAction]"
      :date-range="dateRange"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import TracesCharts from '@/components/extract/elements/Stats/TracesCharts';
import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect';

import stats_mixin from '@/mixins/stats.js';

export default {
  name: 'TracesView',

  mixins: [stats_mixin],

  components: {
    TracesCharts,
    DocTypeSelect,
  },

  data() {
    return {
      chartKey: 0,
      docTypeId: -1,
      currentAction: 'ingestion',
      dateRange: 1,
      showCharts: {
        extraction: ['doc_count', 'page_count'],
        ingestion: ['doc_count', 'useful_page_count', 'page_count'],
        upload: ['doc_count', 'size_sum'],
      },
      traces: [],
    };
  },

  computed: {
    actions() {
      return this.traces.reduce((res, item) => {
        const key = item.trace_item.split(':')[1];
        if (!res[key]) {
          res[key] = [];
        }
        res[key].push(item);
        return res;
      }, {});
    },

    from() {
      const date = new Date();
      if (this.dateRange === 1) {
        date.setDate(date.getDate() - 7 * 4); // Move the date back to four weeks ago.
        return this.getWeekNumber(date).join("-");
      }
      date.setMonth(date.getMonth() - this.dateRange);
      return date.toISOString().split("-").slice(0, 2).join("-");
    },

    to_date() {
      const date = new Date();
      if (this.dateRange === 1) {
        return this.getWeekNumber(date).join("-");
      }
      return date.toISOString().split("-").slice(0, 2).join("-");
    },
  },

  watch: {
    orgId(id, old) {
      if (id !== old) {
        this.dateRange === 1 ? this.getTraces('week') : this.getTraces()
      }
    },

    docTypeId(id, old) {
      if (id !== old) {
        this.dateRange === 1 ? this.getTraces('week') : this.getTraces()
      }
    },

    dateRange(range, old) {
      if (range !== old) {
        this.dateRange === 1 ? this.getTraces('week') : this.getTraces()
      }
    }
  },

  methods: {
    async getTraces(timeGranularity='month') {
      try {
        let params = {
          trace_item: '%doctype:ingestion%',
          time_granularity: timeGranularity,
          timestamp_from: this.from,
          timestamp_to: this.to_date,
          object_id: this.docTypeId === -1 ? null : this.docTypeId,
        }
        if (this.userRole === 'sysadmin') {
          params = {
            ...params,
            org_id: this.orgId,
          }
        }
        const { data } = await http.get('auth/api/v1/traces/aggregates/', { params });
        params.trace_item = '%doctype:extraction%';
        const extractionData = await http.get('auth/api/v1/traces/aggregates/', { params });
        for (const item of data) {
          const updateData = extractionData.data.find(ext => ext.timestamp === item.timestamp);
          if (updateData) {
            item.useful_page_count = updateData.useful_page_count;
          }
        }
        this.traces = data;
      } catch (error) {
        console.error(error);
      }
    },

    async downloadTracesAggregates() {
      const params = {};
      let filename = 'traces-aggregates';
      if (this.userRole === 'sysadmin') {
        params.org_id = this.orgId;
        filename += `-orgId-${this.orgId}`;
      }
      try {
        const response = await http.get('auth/api/v1/traces/aggregates/download/', {
          params,
          responseType: 'arraybuffer',
        });
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      }
    },

    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      weekNo = String(weekNo).padStart(2, '0');
      return [d.getUTCFullYear(), weekNo];
    },
  }
}
</script>