<template>
  <v-dialog
    v-model="openDialog"
    width="600"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      class="dialog-card"
      @dragenter="dragInsideEnter"
      @dragover="dragInsideEnter"
      @dragleave="dragging = false;"
      @drop="dropFile"
    >
      <h2 class="dialog-title mb-3">
        {{ title }}
      </h2>
      <div
        v-if="dragging"
        class="upload-container__active mt-2"
      >
        <h4
          class="text-h4"
          style="color: rgb(var(--v-theme-primary))"
        >
          {{ $t('dropFile') }}
        </h4>  
      </div>
      <div v-else>
        <div class="label">
          {{ input.label }}
        </div>
        <v-text-field
          id="datasetName"
          ref="datasetName"
          v-model="name"
          variant="outlined"
          color="primary"
          density="compact"
          style="margin-top: 5px"
          :placeholder="input.placeholder"
          :hint="input.hint"
          @keydown.enter="click"
          persistent-hint
        />
        <div>
          <v-chip
            v-if="file"
            class="inline-middle mr-4 top-gap ellipsis"
            style="max-width: 160px;"
          >
            {{ file.name }}
          </v-chip>
          <v-btn
            class="inline-middle top-gap"
            color="primary"
            style="font-size: 12px; font-weight: 600; letter-spacing: initial;"
            variant="outlined"
            :disabled="!nameValid"
            @click="click"
            rounded
          >
            <v-icon start>
              fas fa-plus
            </v-icon>
            {{ selectBtn }}
          </v-btn>
        </div>
        <input
          ref="fileUploader"
          type="file"
          class="d-none"
          style="width: 100%; visibility: hidden;"
          accept=".zip"
          @change="handleUpload"
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { nextTick } from 'vue'

export default {
  name: 'DatasetInput',

  data() {
    return {
      name: '',
      file: null,
      dragging: false,
      openDialog: this.modelValue,
    }
  },

  computed: {
    nameValid() {
      return this.name.length > 3;
    },

    trimmedName() {
      return this.name.trim();
    },
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
      if (show) {
        setTimeout(() => {
          nextTick(() => this.$refs.datasetName.focus());
        }, 150);
      }
    },
  },

  methods: {
    click() {
      this.$refs.fileUploader.click();
    },

    reset() {
      this.name = '';
      this.file = null;
    },

    dragInsideEnter(e) {
      e.preventDefault();
      if (this.nameValid) {
        this.dragging = true;
      }
    },

    dropFile(e) {
      e.preventDefault();
      let { files } = e.dataTransfer;
      if (this.nameValid) {
        this.dragging = false;
        if (typeof files === 'object') {
          files = Object.values(files).map(item => item);
        }
        if (files.length > 1) {
          return
        }
        this.file = files[0];
        this.$emit('change', {
          name: this.trimmedName,
          zipfile: this.file,
        });
        this.reset();
      }
    },

    handleUpload({ target: { files }}) {
      if (typeof files === 'object') {
        files = Object.values(files).map(item => item);
      }
      this.file = files[0];
      this.$emit('change', {
        name: this.trimmedName,
        zipfile: this.file,
      });
      this.reset();
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    input: {
      type: Object,
      required: true,
    },
    selectBtn: {
      type: String,
      required: true,
    }
  },

  emits: ['close', 'change', 'update:modelValue'],
};
</script>
<style lang="scss" scoped>
  .upload-container__active {
    border: 2px dashed rgb(var(--v-theme-primary));
    border-radius: 5px;
    height: 148px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #datasetName.input {
    margin-top: 5px !important;
  }
</style>
