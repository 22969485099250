<template>
  <div class="extraction-agents page-padding py-7">
    <BreadcrumbComponent />
    <TableActions
      type="docTypes"
      :number-of-selected="selected.length"
      :lock-actions="selected.some(item => item.is_locked)"
      :delete-condition="selected.every(item => (!item.user_id || item.user_id === user.id))"
      @edit-click="handleEditName"
      @delete-click="handleDeleteButton"
      @create-click="handleCreateButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
      @download-click="handleDownloadClick"
      @import-click="handleImportClick"
    />
    <ViewSelector
      v-if="userRole === 'orgadmin'"
      v-model="view"
      class="left-gap-sm"
    />
    <div class="top-gap">
      <v-card
        v-if="view === 'list'"
        class="elevation-6 pa-0"
      >
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="auto">
              <SortButton v-model="sortDesc" />
              <v-checkbox
                v-model="allSelected"
                class="inline-middle"
                style="margin-top: -16px"
                @change="toggleSelectAll"
              />
            </v-col>
            <v-col cols="4">
              {{ $t('docTypes.doc_type') }}
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="text-align: center"
            >
              {{ $t('docTypes.docs_validated') }}
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="text-align: center"
            >
              {{ $t('docTypes.correctly_extracted') }}
            </v-col>
            <v-col style="text-align: center">
              {{ $t('docTypes.global_precision_header') }}
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="text-align: center"
            >
              API
            </v-col>
          </v-row>
        </v-container>
        <div v-if="loading">
          <div v-if="myLibrary.length === 0">
            <div
              v-for="item in 10"
              :key="item"
              class="table-row-height"
            >
              <v-skeleton-loader type="table-row" />
            </div>
          </div>
          <div
            v-for="item in myLibrary"
            :key="item.id"
            class="table-row-height"
          >
            <v-skeleton-loader type="table-row" />
          </div>
        </div>
        <div
          v-else-if="myLibrary.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px;"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in myLibrary"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap"
                style="margin-top: -15px"
                @change="handleSelect"
              />
            </v-col>
            <v-col cols="4">
              <ItemName
                :ref="`name_${item.id}`"
                :key="item.id"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editType === item.id"
                @save-file-name="saveName"
                @name-click="goToDocType(item.id)"
              />
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="color: rgb(var(--v-theme-primary)); text-align: center"
            >
              <StatItem
                v-if="item.nb_files > 0"
                class="clickable"
                style="margin-top: -11px"
                :left="item.nb_verified_files"
                :right="item.nb_files"
                @stat-click="handleFilesButton(item.id)"
              />
              <span
                v-else
                class="clickable"
                @click="selectFile(item.id)"
              >
                {{ $t('docTypes.add') }}
              </span>
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="text-align: center"
            >
              <StatItem
                v-if="item.nb_valid_files > 0"
                style="margin-top: -11px"
                :left="item.nb_valid_files"
                :right="item.nb_verified_files"
              />
              <span v-else>
                —
              </span>
            </v-col>
            <v-col style="text-align: center">
              <StatItem
                v-if="item.nb_values > 0"
                style="margin-top: -11px"
                :left="item.nb_valid_values"
                :right="item.nb_values"
              />
              <span v-else>
                —
              </span>
            </v-col>
            <v-col
              v-if="userRole === 'orgadmin'"
              style="text-align: center"
            >
              <v-tooltip
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-btn
                    class="small-button"
                    color="primary"
                    v-bind="props"
                    @click="handleSnippet(item.id)"
                  >
                    <v-icon size="16">
                      fas fa-code
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color: white">
                  {{ $t('docTypes.get_url') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div v-else-if="view === 'grid'">
        <DocTypeInfoCard
          v-for="(type, i) in myLibrary"
          :ref="`typeCard_${type.id}`"
          :key="i"
          :type="type"
          :loading="thumbLoading"
          :editing="editType === type.id"
          @save-file-name="(id, newName) => saveName(id, newName)"
          @name-click="goToDocType(type.id)"
          @snippet-click="handleSnippet"
          @select-click="handleSelect"
          @select-file="selectFile"
        />
        <div
          v-if="myLibrary.length === 0"
          class="top-gap-lg"
          style="text-align: center"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
      </div>
    </div>
    <TableFooter
      v-if="myLibrary.length > 0"
      :initial-items-per-page="itemsPerPage"
      :current-page="currentPage"
      :total-pages="Math.ceil(totalDocTypes / itemsPerPage)"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="page => currentPage = page"
    />
    <SnippetDialog
      :id="selectedDT.toString()"
      v-model="showSnippet"
      @close="showSnippet=false, selectedDT = -1"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('tables.move_to_bin_title', itemsTranslation)"
      :message="$t('tables.move_to_bin_confirm', itemsTranslation)"
      @confirm="deleteType"
      @close="deleteDialog = false"
    />
    <UploadDialog
      v-model="showUpload"
      :title="$t('docTypes.import_document_type')"
      :input="uploadInput"
      :select-btn="$t('docTypes.select_document_type')"
      @change="uploadDoctype"
      @close="showUpload = false"
    />
    <FileInput
      ref="uploader"
      @change="uploadFiles"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { FileAPI } from '@/API/extract/FileAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';
import { callbackError } from '@/utils/classes/Utils';

import { http } from '@/plugins/axios';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableFooter from '@/components/common/elements/Tables/TableFooter';
import UploadDialog from '@/components/common/elements/Forms/UploadDialog';
import FileInput from '@/components/common/elements/Forms/FileInput';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import ViewSelector from '@/components/extract/elements/DocTypes/ViewSelector';
import DocTypeInfoCard from '@/components/extract/elements/DocTypes/DocTypeInfoCard';
import SnippetDialog from '@/components/extract/elements/DocTypes/SnippetDialog';
import StatItem from '@/components/extract/elements/DocTypes/StatItem';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import doc_type_mixin from '@/mixins/document_type';
import file_mixin from '@/mixins/file.js';

export default {
  name: 'ExtractionAgents',

  mixins: [doc_type_mixin, file_mixin],

  components: {
    ItemName,
    TableFooter,
    FileInput,
    DeleteDialog,
    TableActions,
    ViewSelector,
    DocTypeInfoCard,
    SnippetDialog,
    StatItem,
    SortButton,
    BreadcrumbComponent,
    UploadDialog,
  },

  data() {
    return ({
      sortDesc: true,
      trimmedFilter: '',
      modelFilter: '',
      uploadType: -1,
      selectedFile: null,
      editType: -1,
      deleteDialog: false,
      allSelected: false,
      forceCompute: Math.random(),
      showSnippet: false,
      selectedDT: -1,
      thumbLoading: false,
      loading: false,
      checkUploadedDoctype: null,
      showUpload: false,
      uploadedDoctypeName: '',
    })
  },

  computed: {
    itemsTranslation() {
      return {items: this.selected.length === 1 ? this.$t('tables.document_type') : this.$t('tables.document_types')}
    },

    pendingUpload() {
      return this.$store.getters.library.some(item => item.name === this.newDoctypeName);
    },

    pageTitle() {
      if (this.userRole === 'sysadmin') {
        return this.$t('docTypes.recital_library');
      }
      return this.$t('docTypes.my_types');
    },

    view: {
      get() {
        return this.$store.getters.docTypesView;
      },
      set(view) {
        this.$store.commit('setDocTypesView', view);
      }
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
    userRole(){
      if (this.user){
        return this.user.role;
      }
      return null;
    },

    itemsPerPage: {
      get: function() {
        return this.$store.getters.itemsPerPage['docTypes'];
      },
      set(itemsPerPage) {
        this.$store.commit('setItemsPerPage', {
          key: 'docTypes',
          itemsPerPage,
        });
      }
    },

    currentPage: {
      get: function() {
        return this.$store.getters.currentPage['docTypes'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', {
          key: 'docTypes',
          currentPage,
        });
      }
    },

    myLibrary: {
      get: function() {
        return this.$store.getters.library
      },
      set: function() {
        // pass
      }
    },

    totalDocTypes: {
      get: function() {
        return this.$store.getters.librarySize;
      },
      set: function() {
        // pass
      },
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.myLibrary.length > 0) {
          return [...new Set(this.myLibrary.filter(item => item.selected))];
        }
        return [];
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.getDocTypes();
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDocTypes();
    },

    currentPage() {
      this.getDocTypes();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDocTypes();
      }, 300
    ),
  },

  async mounted() {
    this.setBreadcrumb();
    this.loading = true;
    this.currentPage = 1;
    await this.getLibrary();
    this.loading = false;
    await this.getLibraryThumbs();
    if (this.myLibrary.length === 0) {
      this.handleCreateButton();
    }
    this.$store.commit('setLoadingScreen', false);
  },

  created() {
    this.uploadInput = {
      label: this.$t('docTypes.document_type_name'),
      placeholder: this.$t('docTypes.type_new_document_type_name'),
      hint: this.$t('docTypes.document_type_name_hint')
    };
    this.timeToNextCheck = 3000;
  },

  unmounted() {
    clearTimeout(this.checkUploadedDataset);
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.agents') },
          { title: this.$t('menu.extraction') },
        ]
      );
    },

    handleImportClick() {
      this.showUpload = true;
    },

    async checkUploaded() {
      await this.getDocTypes();
      if (this.pendingUpload) {
        clearTimeout(this.checkUploadedDoctype);
      } else {
        this.checkUploadedDoctype = setTimeout(async () => {
          await this.checkUploaded();
        }, this.timeToNextCheck);
      }
    },

    async uploadDoctype(payload) {
      const { name, zipfile } = payload;
      this.newDoctypeName = name;
      this.$store.commit('setLoadingScreen', true);
      const formData = new FormData();
      formData.append('document_type_name', name);
      formData.append('zipfile', zipfile);
      try {
        await http.post(
          'system_2/document_type/import',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        this.showUpload = false;
        await this.checkUploaded();
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.myLibrary.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },

    handleSelect() {
      this.allSelected = this.myLibrary.every(dp => dp.selected);
      this.forceCompute = Math.random();
    },

    toggleSelectAll() {
      this.myLibrary.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },

    handleEditName() {
      this.editType = this.selected[0].id;
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(
            `system_2/document_type/${id}`,
            { name: newName }
          );
          this.$store.commit("setDocumentTypes", {});
          await this.getDocTypes();
          this.handleSelect();
          this.$store.commit('setLoadingScreen', false);
          await this.$store.commit(
            'setSuccessMessage', this.$t('docTypes.updated_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.editType = -1;
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
          console.log(error);
          return
        }
      }
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    async deleteType() {
      await Promise.all(this.selected.map(async type => {
        try {
         return await http.delete(`system_2/document_type/${type.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage } = this;
      await this.getDocTypes();
      if (Math.ceil(this.totalDocTypes / this.itemsPerPage) > this.currentPage ) {
        this.currentPage = currentPage;
      } else {
        this.currentPage = 1;
      }
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('docTypes.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleEnter() {
      if (this.myLibrary.length > 0) {
        this.handleTypeClick(this.myLibrary[0]);
      }
    },

    async handleDownloadClick() {
      try {
        const response = await DocTypeAPI.prepareZippedDoctype(this.selected[0].id);
        this.$store.commit(
          'setSuccessMessage', response.data.filename + ' ' + this.$t('models.prepare_dataset_zip')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        callbackError(error);
      }
    },

    handleTypeClick(type) {
      if (type.nb_files === 0) {
        this.selectFile(type.id);
      }
      this.goToDPs(type.id)
    },

    goToDocType(id) {
      if (this.userRole === 'orgadmin') {
        this.handleFilesButton(id);
      } else if (this.userRole === 'sysadmin') {
        this.goToDPs(id);
      }
    },

    handleFilesButton(id) {
      this.$router.push({
        name: 'DocType',
        params: {
          id: id,
          tab: 'documents',
        },
      });
    },

    handleCreateButton() {
      this.$router.push({
        name: 'CreateDoctype',
      });
    },

    goToDPs(id) {
      this.$router.push({
        name: 'DocType',
        params: {
          id: id,
          tab: 'extractors',
        },
      });
    },

    selectFile(id) {
      this.uploadType = id;
      this.$refs.uploader.click()
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await FileAPI.post(this.uploadType, file);
        this.$store.commit('setLoadingScreen', false);
        this.handleFilesButton(this.uploadType);
      } catch (error) {
        console.log(error);
        this.$store.commit('setLoadingScreen', false);
      }
    },

    handleSnippet(id) {
      this.showSnippet = true;
      this.selectedDT = id;
    },
  },
}
</script>
