<template>
  <div
    class="python-editor"
    :style="{
      opacity: disabled ? 0.5 : 1,
    }"
  >
    <div class="cursor-tracker editor-box">
      <div
        class="inline-middle"
        style="color: transparent"
      >
        {{ code.substring(0, cursorPos) }}
        <div
          ref="cursorDiv"
          class="cursor-location-div inline-middle"
        />
      </div>
    </div>
    <div
      ref="code"
      class="code-area language-python pa-0"
      :class="{ 'font-italic': !code }"
    >
      {{ code || $t('workflows.transitions.code_placeholder') }}
    </div>
    <textarea
      ref="textarea"
      v-model="editedCode"
      class="code-area editor-box"
      spellcheck="false"
      :disabled="disabled"
      @blur="$emit('saveConfig')"
      @click="getCursorPos($refs.textarea, $refs.cursorDiv)"
      @input="handleCodeChange"
      @keydown="handleKeyDown"
      @scroll="handleCodeScroll"
    />
    <v-tooltip
      :disabled="disabled"
      bottom
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          class="expand-icon"
          color="primary"
          size="16"
          :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
          @click="codeDialog = !disabled"
        >
          fas fa-expand
        </v-icon>
      </template>
      <span>
        {{ $t('expand') }}
      </span>
    </v-tooltip>
    <v-dialog
      v-model="codeDialog"
      max-width="800"
    >
      <v-card
        class="dialog-card py-4"
        :style="{ height: dialogHeight }"
      >
        <h2 class="dialog-title mb-3">
          {{ title }}
        </h2>
        <v-icon
          class="close-icon"
          size="12"
          @click="codeDialog = false"
        >
          fas fa-times
        </v-icon>
        <div
          class="code-dialog"
        >
          <div class="cursor-tracker editor-box">
            <div
              class="inline-middle"
              style="color: transparent"
            >
              {{ code.substring(0, cursorPos) }}
              <div
                ref="expandedCursorDiv"
                class="cursor-location-div inline-middle"
              />
            </div>
          </div>
          <div
            ref="expandedCode"
            class="code-area language-python pa-0"
            :class="{ 'font-italic': !code }"
          >
            {{ code || $t('workflows.transitions.code_placeholder') }}
          </div>
          <textarea
            ref="expandedTextarea"
            v-model="editedCode"
            class="code-area editor-box"
            spellcheck="false"
            @blur="$emit('saveConfig')"
            @click="getCursorPos($refs.expandedTextarea, $refs.expandedCursorDiv)"
            @input="handleCodeChange"
            @keydown="handleKeyDown"
            @scroll="handleCodeScroll"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { nextTick } from 'vue';
  import hljs from 'highlight.js';
  import '@/assets/default_highlights.css';

  export default {
    data() {
      return {
        editedCode: '',
        codeDialog: false,
        cursorPos: 0,
        cursorLeft: 0,
        cursorTop: 0,
      };
    },

    computed: {
      isFocused() {
        return this.$refs.textarea === document.activeElement || this.$refs.expandedTextarea === document.activeElement;
      },
    },

    watch: {
      code(newCode) {
        this.editedCode = newCode;
        this.highlightCode();
      },

      codeDialog(show) {
        if (show) {
          nextTick(() => {
            hljs.highlightElement(this.$refs.expandedCode, { language: 'python' });
            this.$refs.expandedTextarea.addEventListener('blur', () => this.$emit('saveConfig'));
          });
        }
      },

      disabled(disabled) {
        if (disabled) {
          this.$emit('saveConfig');
        }
      },
    },

    created() {
      this.editedCode = this.code;
      nextTick(() => {
        hljs.highlightElement(this.$refs.code, { language: 'python' });
        this.$refs.textarea.addEventListener('blur', () => this.$emit('saveConfig'));
      });
    },

    methods: {
      handleCodeScroll() {
        let codeEditor = null;
        let codeArea = null;
        if (this.codeDialog) {
          codeEditor = this.$refs.expandedTextarea;
          codeArea = this.$refs.expandedCode;
        } else {
          codeEditor = this.$refs.textarea;
          codeArea = this.$refs.code;
        }
        if (!codeEditor || !codeArea) {
          return;
        }
        const top = codeEditor.scrollTop;
        const left = codeEditor.scrollLeft;
        codeArea.scrollTo({
          behavior: "instant",
          top,
          left,
        });
      },

      setSelectionRange(input, selectionStart) {
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(selectionStart, selectionStart);
        }
        else if (input.createTextRange) {
          var range = input.createTextRange();
          range.collapse(true);
          range.moveEnd('character', selectionStart);
          range.moveStart('character', selectionStart);
          range.select();
        }
      },

      getCursorPos(codeArea, cursorDiv) {
        nextTick(() => {
          this.cursorPos = codeArea.selectionStart;
          this.cursorLeft = cursorDiv.offsetLeft;
          this.cursorTop = cursorDiv.offsetTop;
        });
      },

      handleKeyDown(event) {
        const codeArea = this.codeDialog ? this.$refs.expandedTextarea : this.$refs.textarea;
        const cursorDiv = this.codeDialog ? this.$refs.expandedCursorDiv : this.$refs.cursorDiv;
        this.getCursorPos(codeArea, cursorDiv);
        if (event.code === 'Tab' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
          event.preventDefault();
          this.editedCode = this.editedCode.substring(0, this.cursorPos) + '    ' + this.editedCode.substring(this.cursorPos);
          this.cursorPos = this.cursorPos + 4;
          this.handleCodeChange();
        }
        if (event.code === 'Enter' && event.ctrlKey) {
          codeArea.blur();
          if (this.codeDialog) {
            this.codeDialog = false;
          }
          this.$emit('saveConfig');
        }
      },

      highlightCode() {
        nextTick(() => {
          hljs.highlightElement(this.$refs.code, { language: 'python' });
          if (this.codeDialog) {
            hljs.highlightElement(this.$refs.expandedCode, { language: 'python' });
          }
        });
      },

      handleCodeChange(event) {
        this.$emit('update:code', this.editedCode);
        let cursorPos = this.cursorPos;
        const codeDisplay = this.codeDialog ? this.$refs.expandedCode : this.$refs.code;
        const codeArea = this.codeDialog ? this.$refs.expandedTextarea : this.$refs.textarea;
        const cursorDiv = this.codeDialog ? this.$refs.expandedCursorDiv : this.$refs.cursorDiv;
        const diff = this.cursorPos - codeArea.selectionStart;
        switch (event?.inputType) {
          case 'insertText':
            cursorPos = this.cursorPos + 1;
            break;
          case 'insertLineBreak':
            cursorPos = this.editedCode.substring(0, this.cursorPos + 1).length;
            break;
          case 'insertFromPaste':
            cursorPos = this.cursorPos + this.editedCode.length;
            break;
          case 'deleteContentBackward':
            cursorPos = this.cursorPos - diff;
            break;
          case 'historyUndo':
            cursorPos = this.cursorPos - diff;
            break;
          case 'historyRedo':
            cursorPos = this.cursorPos - diff;
            break;
          case 'deleteWordBackward':
            cursorPos = this.editedCode.length;
            break;
          default:
            break;
        }
        nextTick(() => {
          this.getCursorPos(codeArea, cursorDiv);
          if (cursorPos < this.editedCode.length) {
            this.setSelectionRange(codeArea, cursorPos);
          }
          hljs.highlightElement(codeDisplay, { language: 'python' });
        });
      },
    },

    props: {
      code: {
        type: String,
        default: '',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      dialogHeight: {
        type: String,
        default: '100%',
      },

      title: {
        type: String,
        required: true,
      }
    },

    emits: ['saveConfig', 'update:code'],
  }
</script>
<style lang="scss" scoped>
.python-editor .editor-box,
.code-dialog .editor-box {
  word-break: break-all !important;
  font-family: monospace;
  background-color: transparent !important;
  color: transparent !important;
  padding: 10px;
  padding-right: 10px;
  width: 100%;
  overflow: auto;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;

  &:focus {
    outline: none !important;
  }
}

.python-editor .code-area,
.code-dialog .code-area {
  word-break: break-all !important;
  resize: none;
  width: 100%;
  border-radius: 4px;
  padding: 5px 10px !important;
  caret-color: rgb(var(--v-theme-primary));
  border: 1px solid transparent !important;
  font-family: monospace;
  white-space: pre-wrap;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
  
  &:focus {
    outline: none !important;
    border: 1px solid rgb(var(--v-theme-primary)) !important;
  }
}

.python-editor {
  position: relative;
  height: 250px;
  width: 100% !important;
  
  .expand-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
}

.cursor-location-div {
  width: 1px;
  height: 1px;
}

.code-dialog {
  position: relative;
  width: 100% !important;
  height: calc(100% - 48px);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
</style>
