<template>
  <v-dialog
    v-model="openDialog"
    max-width="650"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="validate()"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form ref="form">
        <h2 class="dialog-title mb-3">
          {{ $t('users.edit.title') }}
        </h2>
        <div>
          {{ form.email }}
        </div>
        <div v-if="roleChoices">
          <div
            class="mt-6 label"
            style="margin-bottom: 5px"
          >
            {{ $t('role') }}
          </div>
          <v-radio-group
            v-model="form.role"
            inline
          >
            <div
              v-for="item in roleChoices"
              :key="item.value"
              class="radio-box right-gap-sm"
            >
              <v-radio
                :label="item.label"
                :value="item.value"
              />
            </div>
          </v-radio-group>
        </div>
        <div
          class="label"
          style="margin-bottom: 5px"
        >
          {{ $t('status') }}
        </div>
        <v-radio-group
          v-model="form.status"
          inline
        >
          <div
            v-for="item in statusChoices"
            :key="item.value"
            class="radio-box right-gap-sm"
          >
            <v-radio
              :label="item.label"
              :value="item.value"
              :disabled="item.value === 'expired'"
              :style="{
                opacity: item.value === 'expired'? 0.5 : 1
              }"
            />
          </div>
        </v-radio-group>
      </v-form>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="validate()"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
      <hr class="mt-6 bottom-gap">
      <h2 class="dialog-title mb-3">
        {{ $t('login.lostPassword.reset') }}
      </h2>
      <div class="d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="resetPassword()"
            block
            rounded
          >
            {{ $t('login.lostPassword.reset_and_email') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRules from '@/utils/classes/FormRules/FormRules';
import { PasswordAPI } from '@/API/authenticator/PasswordAPI';
import { UserAPI } from '@/API/authenticator/UserAPI';

export default {
  name: 'EditUserDialog',

  data() {
    return ({
      commonFieldRules: [FormRules.required],
      emailFieldRules: [FormRules.emailLocal],
      roleChoices: null,
      openDialog: this.modelValue,
      statusChoices: [
        { label: this.$t('users.status.open'), value: 'open' },
        { label: this.$t('users.status.blocked'), value: 'blocked' },
        { label: this.$t('users.status.expired'), value: 'expired' },
      ],
      valid: true,
      errorsMessages: {},
      form: {},
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },
  },

  watch: {
    editedUser() {
      this.form.role = this.editedUser.role;
      this.form.email = this.editedUser.email;
      this.form.status = this.editedUser.status;
      this.form = {...this.form};
    },

    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  mounted() {
    this.form.org_id = this.user.org_id;
    this.form.timezone = this.user.timezone;
    const roleChoices = [
        { label: this.$t('roles.basic'), value: 'basic' },
        { label: this.$t('roles.orgadmin'), value: 'orgadmin' },
        { label: this.$t('roles.sysadmin'), value: 'sysadmin' }
      ];
    if (this.user.role == 'orgadmin'){
      this.roleChoices = roleChoices.slice(0, 2);
    }
    if (this.user.role == 'sysadmin'){
      this.roleChoices = roleChoices;
    }
  },

  methods: {
    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.editUser();
      }
    },

    async resetPassword() {
      try {
        await PasswordAPI.passwordReset(this.editedUser.id);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('users.password_reset')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.$emit('refresh', {
          status: 'expired',
          role: this.editedUser.role,
        });
        this.$emit('close');
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async editUser() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const update = {...this.form};
        update.id = this.editedUser.id;
        await UserAPI.editUser(update);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('users.edit.success')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.$emit('refresh', {
          status: this.form.status,
          role: this.form.role,
        });
        this.$emit('close');
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    editedUser: {
      type: Object,
      required: true,
    },
  },

  emits: ['close', 'refresh', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
