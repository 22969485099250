<template>
  <v-dialog
    v-model="openDialog"
    max-width="850"
    height="700"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card class="dialog-card pb-2">
      <v-icon
        class="close-icon"
        size="16px"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <h2 class="dialog-title">
        {{ $t('workflows.test.title') }}
      </h2>
      <v-card-text class="pa-2">
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.name') }}
          </div>
          <div class="settings-right">
            <v-text-field
              v-model="workflow.name"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="true"
              hide-details
            />
          </div>
        </div>
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.test.data') }}
          </div>
          <div class="settings-right">
            <textarea
              v-model="workflow.data"
              class="data-input text-mono px-2 py-1"
              rows="5"
              @keyup="validateJSON"
              hide-details
              no-resize
            />
            <span 
              v-if="errorData" 
              class="error-message"
            >
              {{ errorData }}
            </span>
          </div>
        </div>
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.test.custom_metadata') }}
          </div>
          <div class="settings-right">
            <textarea
              v-model="workflow.custom_metadata"
              class="data-input text-mono px-2 py-1"
              rows="5"
              hide-details
              no-resize
            />
          </div>
        </div>
        <div
          v-for="(file, index) in files"
          :key="index"
          class="config-body mb-4"
        >
          <div class="settings-left">
            {{ $t('workflows.jobs.files') }}
          </div>
          <div class="settings-right d-flex align-center">
            <v-select
              v-model="file.type"
              style="max-width: 200px;"
              variant="outlined"
              color="primary"
              density="compact"
              label="Collection"
              :items="localizedfileItems"
              hide-details
            />

            <ButtonWithIcon
              v-if="!file.file"
              class="ml-2"
              icon="fas fa-upload"
              icon-position="left"
              variant="outlined"
              :color="'primary'"
              :message="$t('workflows.test.upload')"
              :span-class="'ml-2'"
              @click="triggerFileUpload(index)"
              rounded
            />
            <div 
              v-else
              class="ellipsis clickable ml-2"
              style="max-width: calc(100% - 240px);"
              @click="triggerFileUpload(index)"
            >
              <small class="font-weight-bold">
                {{ $t('workflows.test.file_name') }}
              </small>
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <small
                    class="d-block ellipsis"
                    v-bind="props"
                  >
                    {{ file.file.name }}
                  </small>
                </template>
                {{ `${file.file.name} (${$t('workflows.test.click_change')})` }}
              </v-tooltip>
            </div>
            <input
              ref="uploader"
              type="file"
              class="d-none"
              @change="handleFileUpload($event, index)"
            >
            <v-tooltip v-if="index === files.length - 1">
              <template #activator="{ props }">
                <v-icon
                  class="fas fa-plus ml-2"
                  v-bind="props"
                  density="compact"
                  color="primary"
                  size="16"
                  @click="addFile"
                />
              </template>
              {{ $t('workflows.test.add_file') }}
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-0">
        <v-btn
          color="primary"
          variant="flat"
          :disabled="!isValid"
          @click="createTestInstance"
          rounded
        >
          {{ $t('workflows.test.launch') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';

export default {
  name: 'WorkflowTestDialog',

  components: {
    ButtonWithIcon
  },

  data(){
    return ({
      fileItems: ['email', 'file', 'attachment'],
      openDialog: this.testWorkflow,
      errorData: '',
      files: [
        {type: 'email', file: null}
      ],
    });
  },
  
  computed: {
    isValid() {
      return this.errorData === '' ;
    },
    
    localizedfileItems(){
      return this.fileItems.map(item => ({
        title: this.$t(`workflows.test.${item}`),
        value: item
      }));
    }
  },

  watch: {
    openDialog(open){
      this.$emit('update:modelValue', open);
    },
    
    testWorkflow(show){
      this.openDialog = show;
    },
  },
  
  methods: {
    triggerFileUpload(index) {
      this.$refs.uploader[index].click();
    },
    
    handleFileUpload(event, index){
      const file = event.target.files[0];
      this.files[index].file = file;
    },

    addFile() {
      this.files.push({type: 'Email', file: null});
    },

    validateJSON() {
      this.errorData = '';
      const trimmedData = this.workflow.data ? this.workflow.data.trim() : '';

      if (trimmedData === '') {
        return;
      }

      try {
        const parsedData = JSON.parse(trimmedData);

        if (parsedData === null || typeof parsedData !== 'object' || Array.isArray(parsedData)) {
          this.errorData = this.$t('workflows.test.object_expected');
          return;
        }

        if (Object.keys(parsedData).length === 0) {
          this.errorData = this.$t('workflows.test.empty_json');
          return
        }
      } catch (error) {
        this.errorData = this.$t('workflows.test.invalid_json_format');
      }
    },


    createTestInstance() {
      if (this.isValid){
        const testJobData = {
        id: this.workflow.id,
        uuid: this.workflow.uuid,
        name: this.workflow.name,
        files: this.files,
        data: this.workflow.data,
        custom_metadata: this.workflow.custom_metadata
      }
        this.$emit('submit', testJobData);
      } else {
        this.$store.commit('setSnackbar', true);
      }
    },
  },

  props: {
    workflow: {
      type: Object,
      required: true,
    },
  },

  emits: ['close', 'submit', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.config-body {
  display: flex;
  align-items: center;
  .settings-left {
    width: 25%;
    padding-right: 20px;
    font-weight: bold;
  }
  .settings-right {
    width: 75%;
  }

  .data-input {
    border: 1px solid;
    border-color: #ccc;
    border-radius: 4px;
    color: #333;
    resize: none;
    width: 100%;
    animation: border-color 0.5s ease-in-out;
    cursor: text;

    &:hover {
      border-color: #666;
    }

    &:focus {
      outline: none;
      border-color: rgb(var(--v-theme-primary));
    }
  }

  .file-name-container {
    display: flex;
    align-items: center;
    
    border: 1px solid #ccc;
    max-width: 100%;
    flex-grow: 0;
  }

}
.d-none {
  display: none !important;
}
.error-message {
  color: red;
  font-size: smaller;
}
</style>