<template>
  <strong
    class="notificationLink"
    @click="handleLinkClicked(notification)"
  >
    {{ notification.args.text }}
  </strong>
</template>

<script>
import { DownloadUtils } from '@/utils/DownaloadUtils';

export default {
  name: 'NotificationLink',

  methods: {
    handleLinkClicked(notification) {
      if (notification.args.type == 'redirect') {
        this.$router.push({name: notification.args.link.route, params: notification.args.link.params})
      } else {
        DownloadUtils.download(notification.args.link);
      }
    }
  },

  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notificationLink {
  text-decoration: underline;
  cursor: pointer;
}
</style>
