/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'workflows/api/v1/';

export class WorkflowAPI {
  static async getWorkflows() {
    return http
      .get(`${BASE_PATH}workflows/`,
      { ignoreGlobalCatch: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getByUUID(uuid, name) {
    return http
      .get(`${BASE_PATH}workflows/by-uuid/`,
      { params: { uuid, name } })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getActions() {
    return http
      .get(`${BASE_PATH}workflows/actions/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getWorkflow(id) {
    return http
      .get(`${BASE_PATH}workflows/${id}/`,
      { ignoreGlobalCatch: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
    
  static async updateWorkflow(id, data) {
    return http
      .patch(`${BASE_PATH}workflows/${id}/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async createWorkflow(data) {
    return http
      .post(`${BASE_PATH}workflows/`, data,)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async publishWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/publish`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async activateWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/activate`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async checkIfValid(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/validate/`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async forkWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/fork/`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async deleteWorkflow(id) {
    return http
      .delete(`${BASE_PATH}workflows/${id}/`,)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
  
  static async deleteJob(id){
    return http
      .delete(`${BASE_PATH}jobs/${id}/`,)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getSteps(id, action = null, python = false) {
    return http
      .get(`${BASE_PATH}workflows/${id}/steps/`, 
        {
            params: {
              action,
              python,  
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getWorkflowStep(workflow_id, step_id) {
    return http
      .get(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async createStep(workflow_id, data) {
    return http
      .post(`${BASE_PATH}workflows/${workflow_id}/steps/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async patchStep(workflow_id, step_id, data) {
    return http
      .patch(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async deleteStep(workflow_id, step_id) {
    return http
      .delete(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getTransitions(id) {
    return http
      .get(`${BASE_PATH}workflows/${id}/transitions/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async createTransition(workflow_id, data) {
    return http
      .post(`${BASE_PATH}workflows/${workflow_id}/transitions/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async deleteTransition(workflow_id, transition_id) {
    return http
      .delete(`${BASE_PATH}workflows/${workflow_id}/transitions/${transition_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async patchTransition(workflow_id, transition_id, data) {
    return http
      .patch(
        `${BASE_PATH}workflows/${workflow_id}/transitions/${transition_id}`,
        data,
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createJob(formData, queryParams){
    const jobQueryString = new URLSearchParams(queryParams);
    const isFormDataEmpty = !formData || (formData instanceof FormData && ![...formData.entries()].length);

    const jsonHeaders = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const formHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const headerConfig = isFormDataEmpty ? jsonHeaders : formHeaders;
    const requestData = isFormDataEmpty ? {} : formData;
    
    return http
      .post(`${BASE_PATH}jobs/?${jobQueryString}`, requestData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getJobs(workflowId, options = {}){
    const {
      workflowFilter,
      stepFilter,
      statusFilter,
      uuid,
      limit,
      offset, 
      sortDesc
    } = options

    const requestParams = {
      workflow_filter: workflowFilter, 
      step_filter: stepFilter,
      state: statusFilter,
      limit,
      offset,
      sort_desc: sortDesc
    }
    
    return http
      .get(`${BASE_PATH}jobs/`, { params: {workflowId, uuid: uuid, ...requestParams}})
      .catch(error => Promise.reject(error));
  }

  static async getJob(jobId) {
    return http
      .get(`${BASE_PATH}jobs/${jobId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
  
  static async getJobHistory(jobId){
    return http
      .get(`${BASE_PATH}jobs/${jobId}/history/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getJobFiles(jobId){
    return http
      .get(`${BASE_PATH}jobs/${jobId}/files/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async downloadJobFile(jobId, fileId){
    return http.get(`${BASE_PATH}jobs/${jobId}/files/${fileId}`, {
        responseType: 'arraybuffer'
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
