<template>
  <v-card class="search-result bottom-gap">
    <div style="max-width: 95%">
      <div class="result-info bottom-gap-sm right-gap inline-top">
        <span v-if="type === 'chunk'">
          {{ $t('page') }} {{ result.meta.page }} {{ $t('search.home.of') }}
        </span> <a
          target="_blank"
          :href="result.url"
        >
          <ItemName
            class="noselect inline-top"
            :item="{ name: getFileName(result.meta.path) }"
            :editing-allowed="false"
            :show-id="false"
            :fade-in-slow="false"
          />
        </a>
      </div>
      <div class="bottom-gap-sm inline-top">
        <MaxWidthChip
          color="#502BFF"
          style="text-transform: uppercase !important"
          :text-array="[getExtension(result.meta.path)]"
          small
        />
        <MaxWidthChip
          v-for="(meta, i) in displayMetas"
          :key="i"
          color="#502BFF"
          :text-array="[meta[1], ' ', '(', meta[0], ')']"
          small
        />
        <MaxWidthChip
          v-if="!showAllMeta && allMetasNum > $options.constants.MAX_METAS"
          color="#502BFF"
          :text-array="[`+ ${allMetasNum - $options.constants.MAX_METAS}`]"
          small
        />
        <ChevronButton
          v-if="allMetasNum > $options.constants.MAX_METAS && !showAllMeta"
          v-model="showAllMeta"
          class="inline-top"
          :size="14"
        />
      </div>
    </div>
    <div class="text-center">
      <a
        target="_blank"
        :href="result.url"
      >
        <ImageWithFallback
          :img-attributes="{
            width: '100%',
            style: 'border: 1px lightgrey solid',
            src: result.image,
          }"
        />
      </a>
    </div>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="favorite-button"
          color="primary"
          @click="handleFavoriteClick"
        >
          <v-icon size="16">
            {{ favorites.includes(result.meta.file_id) ? 'fas fa-check' : 'fas fa-star' }}
          </v-icon>
        </v-btn>
      </template>
      {{ favorites.includes(result.meta.file_id) ? $t('search.home.remove_from_favorites') : $t('search.home.add_to_favorites') }}
    </v-tooltip>
    <FavoriteDialog
      v-model="favoriteDialog"
      :doc-id="docToFavorite"
    />
  </v-card>
</template>

<script>
import FavoriteDialog from '@/components/search/elements/Home/FavoriteDialog';
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import ImageWithFallback from '@/components/common/elements/General/ImageWithFallback';
import ChevronButton from '@/components/common/elements/General/ChevronButton';

export default {
  name: 'SearchResult',

  components: {
    ItemName,
    MaxWidthChip,
    ImageWithFallback,
    ChevronButton,
    FavoriteDialog,
  },

  constants: {
    MAX_METAS: 3,
  },

  data() {
    return({
      showAllMeta: false,
      docToFavorite: 0,
      favoriteDialog: false,
    });
  },

  computed: {
    allMetas() {
      const metaKeys = Object.keys(this.result.meta.metadata);
      let allMetas = [];
      metaKeys.forEach(key => {
        const meta = this.result.meta.metadata[key];
        if (Array.isArray(meta)) {
          meta.forEach(element => {
            allMetas.push([key, element]);
          });
        } else {
          allMetas.push([key, meta]);
        }
      });
      return allMetas;
    },

    allMetasNum() {
      return this.allMetas.length;
    },

    displayMetas() {
      const showNum = this.showAllMeta ? this.allMetasNum : this.$options.constants.MAX_METAS;
      return this.allMetas.slice(0, showNum);
    },
  },

  methods: {
    handleFavoriteClick(event) {
      event.preventDefault();
      const isFavorite = this.favorites.includes(this.result.meta.file_id);
      if (!isFavorite) {
        this.docToFavorite = this.result.meta.file_id;
        this.favoriteDialog = true;
      } else {
        this.$emit('removeFromFavorites', this.result.meta.file_id);
      }
    },

    getFileName(path) {
      return path.split('/').pop();
    },

    getExtension(path) {
      return this.getFileName(path).split('.').pop();
    }
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => ['chunk', 'document'].includes(value),
    },

    result: {
      type: Object,
      required: true,
    },

    favorites: {
      type: Array,
      required: true,
    },
  },

  emits: [ 'removeFromFavorites' ],
}
</script>

<style lang="scss" scoped>
.result-info {
  font-size: 0.9rem;
  color: rgb(var(--v-theme-grey-base));
}

.favorite-button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px !important;
}
</style>
