<template>
  <div class="dataset-select d-flex">
    <div
      class="custom-select noselect"
      @click="toggleSelectOn"
    >
      <div class="dataset-name ellipsis">
        <span
          v-if="dataset.name"
          :style="{'font-style': numberOfDatasets? 'normal' : 'italic' }"
        >
          {{ dataset.name }}
        </span>
        <span
          v-else-if="numberOfDatasets === 0"
          style="font-style: italic"
        >
          {{ $t('models.no_datasets') }}
        </span>
        <span v-else>
          {{ $t('models.select_dataset') }}
        </span>
      </div>
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(datasetItem, i) in datasets"
          :ref="`Option${i}`"
          :key="datasetItem.id"
          class="option ellipsis"
          :style="{ opacity: isDisabled(datasetItem) ? 0.4 : 1 }"
          :value="datasetItem.id"
          @click="selectDataset(datasetItem)"
        >
          {{ datasetItem.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '../../../../plugins/axios';

export default {
  name: 'DatasetSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      dataset: {},
    })
  },

  computed: {
    lastPos() {
      return this.numberOfDatasets - 1;
    },

    numberOfDatasets() {
      return this.datasets.length;
    },
  },

  watch: {
    selected() {
      this.selectedId = this.selected;
    },

    selectedId(id) {
      this.getDatasetById(id);
      this.$emit('selectedChanged', id);
      this.$store.commit('setSelectedDatasetId', id);
    },
  },

  mounted() {
    this.selectedId = this.selected;
  },

  methods: {
    isDisabled(dataset) {
      return (
        dataset.id !== this.selectedId && this.disabledDatasets.includes(dataset.id)
      );
    },

    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          this.$emit('getMore');
        }
      }
    },

    toggleSelectOn() {
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          this.$refs.selectPanel.focus();
        }, 50);
      }
    },

    selectDataset(dataset) {
      if (!this.isDisabled(dataset)) {
        this.selectedId = dataset.id;
        this.dataset =  this.datasets.find(d => d.id === this.selectedId);
      }
    },
    
    async getDatasetById(datasetId) {
      if (datasetId === -1) {
        this.dataset = {};
        return;
      }
      try {
        const response = await http.get(`dataset/${datasetId}`)
        this.dataset = response.data;
      } catch (err) {
        this.dataset = {};
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setSelectedDatasetId', -1);
        console.log(err);
      }
    }
  },

  props: {
    datasets: {
      type: Array,
      required: true,
    },

    selected: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabledDatasets: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['getMore', 'selectedChanged'],
}
</script>

<style lang="scss" scoped>
.dataset-select {
  .custom-select {
    width: 300px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .dataset-name {
      max-width: 250px;
    }
    
    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
    }

    .select-panel {
      width: 300px;
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
}
</style>
