<template>
  <div class="add-document-label">
    <div
      class="mt-2 ml-3 d-flex flex-wrap"
      style="padding-bottom: 10px"
    >
      <v-text-field
        v-model="newLabel"
        class="inline-middle right-gap label-field mb-4"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('models.type_label_name')"
        @keydown.enter="addLabelIfComplete"
      />
      <v-select
        v-model="newLabelType"
        class="inline-middle right-gap label-type-select mb-3"
        variant="outlined"
        color="primary"
        density="compact"
        style="margin-top: 0px"
        item-title="text"
        item-value="value"
        :items="docLabelTypes"
      />
      <v-btn
        class="add-button"
        color="primary"
        style="height: 37px"
        :disabled="trimmedLabel === '' || (newLabelType !== 'text' && options.some(o => o.trim() === ''))"
        @click="addLabel"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t("docTypes.add") }}
      </v-btn>
    </div>
    <div
      v-if="['single_select', 'multi_select'].includes(newLabelType)"
      class="doc-label-options"
    >
      <div class="inline-top">
        <v-text-field
          v-for="(option, i) in options"
          :key="i"
          class="option-input"
          variant="outlined"
          color="primary"
          density="compact"
          :model-value="option"
          :append-icon="i < 2 ? null : 'fas fa-times'"
          :placeholder="`Option ${i + 1}`"
          @update:model-value="t => handleOptionInput(i, t)"
          @click:append="options.splice(i, 1)"
        />
        <v-text-field
          v-model="newOption"
          class="option-input"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('models.label_types.type_option')"
          @keydown.enter="options.push(newOption); options = [...options]; newOption = ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';

export default {
  name: 'AddDocumentLabel',

  data() {
    return ({
      newLabel: '',
      newLabelType: 'text',
      options: ['', ''],
      newOption: '',
      docLabelTypes: [
        {
          text: this.$t('models.label_types.text'),
          value: 'text',
        },
        {
          text: this.$t('models.label_types.single_select'),
          value: 'single_select',
        },
        {
          text: this.$t('models.label_types.multi_select'),
          value: 'multi_select',
        },
      ],
    });
  },

  computed: {
    trimmedLabel() {
      return this.newLabel.trim();
    },
  },

  methods: {
    handleOptionInput(i, text) {
      this.options[i] = text;
      this.options = [...this.options];
    },

    addLabelIfComplete() {
      if (!(this.newLabelType !== 'text' && this.options.some(o => o.trim() === ''))) {
        this.addLabel();
      }
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          const label = {
            name: this.trimmedLabel,
          };
          if (this.newLabelType === 'single_select') {
            label.exclusive = true;
          }
          if (this.newLabelType !== 'text') {
            label.options = this.options;
          }
          await http.post(`dataset/label/${this.datasetId}/?type=entry`, label);
          this.newLabel = "";
          this.options = ['', ''];
        } catch (error) {
          this.$store.commit("setSnackbar", true);
          console.log(error);
        }
        this.$emit('getLabels');
      }
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    },
  },

  emits: ['getLabels'],
}
</script>

<style lang="scss" scoped>
.add-document-label {
  .label-field {
    width: 230px;
  }

  .label-type-select {
    margin-top: 0px;
    width: 209px;
  }

  .add-button {
    top: 3px;
    box-shadow: none !important;
  }

  .doc-label-options {
    text-align: right;
    margin-right: 109px;
  }

  .option-input {
    width: 240px !important;
    position: relative;
    top: -10px !important;
    margin-top: -10px !important;
  }
}
</style>
