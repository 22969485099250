<template>
  <div class="settings-view page-padding py-7">
    <BreadcrumbComponent />
    <ProfileSettings />
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search.metadata.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search.metadata.explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchMetadataSettings />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_llm_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_llm_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchLLMSettings
          :config="config"
          :options="configOptions"
          :loading="loading"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchEnhancementSettings
          :config="config"
          :loading="loading"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_results_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_results_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchResultSettings
          :config="config"
          :loading="loading"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_other_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_other_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchOtherSettings
          :config="config"
          :loading="loading"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>API</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_api_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <ApiTokenSettings app="search" />
      </div>
    </div>
  </div>
</template>

<script>
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import ProfileSettings from '@/components/common/elements/Settings/ProfileSettings';
import ApiTokenSettings from '@/components/common/elements/Settings/ApiTokenSettings';
import SearchEnhancementSettings from '@/components/search/elements/Settings/SearchEnhancementSettings';
import SearchResultSettings from '@/components/search/elements/Settings/SearchResultSettings';
import SearchLLMSettings from '@/components/search/elements/Settings/SearchLLMSettings';
import SearchOtherSettings from '@/components/search/elements/Settings/SearchOtherSettings';
import SearchMetadataSettings from '@/components/search/elements/Settings/SearchMetadataSettings';

export default {
  name: 'SearchSettingsView',

  components: {
    BreadcrumbComponent,
    ProfileSettings,
    ApiTokenSettings,
    SearchEnhancementSettings,
    SearchResultSettings,
    SearchLLMSettings,
    SearchOtherSettings,
    SearchMetadataSettings,
  },

  data() {
    return ({
      loading: false,
      config: {
        length_chunk: 0,
        detect_titles: false,
        top_chunk: 0,
        coef_ranker_chunk: 0,
        use_synonyms: false,
        boost_content_chunk: false,
        top_doc: 0,
        coef_ranker_doc: 0,
        boost_content_doc: false,
        s3_fallback: false,
        llm_provider: null,
        llm_name: null,
        llm_api_key: null,
        llm_active: false,
      },
      configOptions: {
        llm_provider: [],
        llm_name: null,
      },
    });
  },

  async created() {
    this.setBreadcrumb();
    this.$store.commit('setLoadingScreen', true);
    await Promise.all([
      this.getSearchConfig(),
      this.getSearchConfigOptions()
    ]);
    this.$store.commit('setLoadingScreen', false);
  },

  methods: {
    async getSearchConfig() {
      try {
        const config = await SearchConfigAPI.get();
        config.llm_provider = 'OpenAI';
        config.llm_api_key = config.llm_api_key_decrypted;
        this.config = config;
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async getSearchConfigOptions() {
      try {
        this.configOptions = await SearchConfigAPI.getOptions();
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async updateConfig() {
      this.loading = true;
      this.$store.commit('setLoadingScreen', true);
      try {
        await SearchConfigAPI.update(this.config);
        this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      } finally {
        this.loading = false;
        this.$store.commit('setLoadingScreen', false);
      }
    },
  
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'SearchHome'} },
          { title: this.$t('menu.settings.title')},
        ]
      );
    },

  }
}
</script>
<style lang="scss">
.settings-view {
  .settings-body {
    width: 1024px;

    .settings-left {
      width: 400px;
      padding-right: 30px;
    }

    .settings-right {
      width: 600px;

      .label {
        font-weight: bold;
        font-size: 0.9rem;
      }

      .text-field-label {
        margin-bottom: 3px;
      }

      .radio-group-label {
        margin-bottom: -12px;
      }
    }
  }
}
</style>
