import { i18n } from '@/plugins/i18n/i18n';
import { store } from '@/plugins/store';
import router from '@/plugins/router';

const locale = i18n.global;

const resetSearch = () => store.commit('reinitSearch');

class MenuClassBuild {
  static buildMenuSidebarItems() {
    const searchMenu = {
      blank: {
        icon: 'fas fa-search',
        title: locale.t('search.title'),
        route: '/search/home',
        action: resetSearch,
        authorize: ['basic', 'orgadmin', 'sysadmin'],
      },
      docs: {
        icon: 'fas fa-file-alt',
        title: locale.t('search.documents_capitalized'),
        route: '/search/documents',
        authorize: ['basic', 'orgadmin', 'sysadmin']
      },
      settings: {
        icon: 'fas fa-cog',
        title: locale.t('menu.settings.title'),
        route: '/search/settings/general',
        authorize: ['sysadmin', 'orgadmin']
      },
      users: {
        icon: 'fas fa-user-friends',
        title: locale.t('menu.users.title'),
        route: '/search/users',
        authorize: ['orgadmin', 'sysadmin']
      },
    };

    const classifyMenu = {
      blank: {
        icon: 'fas fa-file-alt',
        title: locale.t('classify.title'),
        route: '/classify/home',
        authorize: ['basic', 'orgadmin', 'sysadmin']
      },
      settings: {
        icon: 'fas fa-cog',
        title: locale.t('menu.settings.title'),
        route: '/classify/settings/general',
        authorize: ['sysadmin', 'orgadmin']
      },
      users: {
        icon: 'fas fa-user-friends',
        title: locale.t('menu.users.title'),
        route: '/classify/users',
        authorize: ['orgadmin', 'sysadmin']
      },
    };

    const product = router.currentRoute.value.path.split('/')[1];

    const productMenus = {
      suite: store.getters.suiteMenu,
      search: searchMenu,
      classify: classifyMenu,
    }

    if (!['suite', 'search', 'classify'].includes(product)) {
      router.push({name: '404Redirect'});
    }

    return productMenus[product];
  }
}

export const MenuClass = MenuClassBuild;
