<template>
  <div class="files-table">
    <TableActions
      type="correctionDocs"
      :number-of-selected="selected.length"
      :edit-condition="false"
      :delete-condition="true"
      @delete-click="deleteDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="bundles.length"
      :total="totalBundles"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="inline-middle"
            style="margin-top: -16px"
            @change="toggleSelectAll"
          />
        </v-col>
        <v-col cols="6">
          {{ $t('datatable.header.docName') }}
        </v-col>
        <v-col cols="2">
          {{ $t('status') }}
        </v-col>
        <v-col cols="2">
          {{ $t('datatable.header.addDate') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in bundles"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap"
                style="margin-top: -15px"
                @change="handleSelect"
              />
            </v-col>
            <v-col
              cols="6"
              @click="goToBundle(item)"
            >
              <ItemName
                :key="item.id"
                style="z-index: 202"
                type="file"
                name-field="filename"
                :item="item"
                :editing-allowed="false"
              />
            </v-col>
            <v-col cols="2">
              {{ $t(`workflows.split.bundle_status.${item.status}`) }}
            </v-col>
            <v-col cols="2">
              <small class="gray--text">
                {{ formatDate(item.created_on) }}
              </small>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.delete_file')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteBundles"
      @close="deleteDialog = false"
    />
    <ProgressDialog
      v-model="progressDialog"
      :all-delete="allDelete"
      :current-delete="currentDelete"
    />
  </div>
</template>
<script>
  import _ from 'lodash';
  import { ClassifyBundlesAPI } from '@/API/classify/ClassifyBundlesAPI';
  import ItemName from '@/components/common/elements/General/ItemName';
  import DeleteDialog from '@/components/common/elements/Tables/DeleteDialog';
  import ProgressDialog from '@/components/common/elements/Tables/ProgressDialog';
  import SortButton from '@/components/common/elements/Tables/SortButton';
  import TableActions from '@/components/common/elements/Tables/TableActions';
  import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

  import format_mixin from '@/mixins/format.js';

  export default {
    name: 'BundlesTable',

    mixins: [format_mixin],

    components: {
      DeleteDialog,
      ItemName,
      ProgressDialog,
      SortButton,
      TableActions,
      TableWithFooter,
    },

    data() {
      const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

      return {
        allDelete: 0,
        currentDelete: 0,
        allSelected: false,
        bundles: [],
        loading: false,
        deleteDialog: false,
        progressDialog: false,
        sortDesc: true,
        trimmedFilter: '',
        itemsPerPage,
        currentPage,
      };
    },

    computed: {
      selected() {
        if (this.bundles.length > 0) {
          return this.bundles.filter(item => item.selected);
        }
        return [];
      },
    },

    watch: {
      trimmedFilter: _.debounce(
        async function() {
          this.currentPage = 1;
          await this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
        }, 300
      ),

      currentPage() {
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },

      itemsPerPage() {
        this.resetCurrentPage();
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },

      sortDesc() {
        this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      },
    },

    created() {
      this.getBundles();
    },

    methods: {
      async getBundles(
        offset = (this.currentPage - 1) * this.itemsPerPage,
        limit = this.itemsPerPage,
        sortDesc = true, filter = '')
      {
        try {
          this.loading = true;
          const response = await ClassifyBundlesAPI.get(
            offset,
            limit,
            sortDesc,
            filter,
          );
          this.bundles = response.data;
          this.$emit('update:totalBundles', parseInt(response.headers['x-total-count'], 10));
        } catch (error) {
          console.error(error);
          this.$store.commit('setSnackbar', true);
        } finally {
          this.loading = false;
        }
      },

      goToBundle(bundle) {
        this.$router.push({
          name: 'PageCorrection',
          params: {
            id: bundle.id,
          },
          meta: {
            bundle,
          }
        });
      },

      handleSelect() {
        this.allSelected = this.bundles.every(b => b.selected);
      },

      toggleSelectAll() {
        this.bundles = this.bundles.map(b => {
          b.selected = this.allSelected;
          return b;
        });
      },

      async deleteBundles() {
        const total = this.selected.length;
        this.deleteDialog = false;
        this.allDelete = total;
        this.progressDialog = true;
        for (let i = 0; i < total; i++) {
          this.currentDelete = i + 1;
          await ClassifyBundlesAPI.delete(this.selected[i].id);
        }
        this.$emit('update:totalBundles' ,this.totalBundles - total);
        this.progressDialog = false;
        await this.getBundles(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
        const lastPage = Math.max(1, Math.ceil(this.totalFiles / this.itemsPerPage));
        this.currentPage = Math.min(this.currentPage, lastPage);
        this.allSelected = false;
        this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
        this.$store.commit('setSuccessSnackbar', true);
      },

      resetCurrentPage() {
        this.currentPage = 1;
        this.allSelected = false;
        this.bundles = this.bundles.map(b => {
          b.selected = false;
          return b;
        });
      },
    },

    props: {
      totalBundles: {
        type: Number,
        default: 0,
      },
    },

    emits: ['update:totalBundles'],
  };
</script>
<style lang="scss" scoped>

</style>