<template>
  <v-card class="doc-type-info-card inline-middle right-gap-lg bottom-gap">
    <div class="type-image">
      <div
        v-if="type.imageUrl"
        class="type-page-image vertical-centered"
        :style="{
          'background-image': `url(${type.imageUrl})`
        }"
      />
      <div
        v-else-if="loading"
        class="type-page-image vertical-centered"
      >
        <v-skeleton-loader
          class="vertical-centered horizontal-centered"
          type="image"
          width="150"
        />
      </div>
      <v-icon
        v-else
        size="41"
        style="color: #C8C8C8 !important"
        class="vertical-centered"
      >
        far fa-file-alt
      </v-icon>
    </div>
    <div class="type-info">
      <div
        class="type-info-section"
        style="height: 70px"
        :style="{
          'border-bottom': userRole === 'orgadmin' ? '1px solid #D8D8D8' : 'none',
        }"
      >
        <div
          class="vertical-centered"
          style="font-size: 16px"
        >
          <ItemName
            :key="type.id"
            style="z-index: 202; max-width: 150px;"
            :item="type"
            :editing-allowed="!!(type.selected)"
            :editing="editing"
            @save-file-name="(id, newName) => $emit('saveFileName', id, newName)"
            @name-click="$emit('nameClick', type.id)"
          />
        </div>
      </div>
      <div class="type-info-section">
        <div style="font-size: 0.8rem">
          {{ $t('docTypes.docs_validated') }}
        </div>
        <StatItem
          v-if="type.nb_files > 0"
          class="clickable"
          style="color: rgb(var(--v-theme-primary))"
          :left="type.nb_verified_files"
          :right="type.nb_files"
          @stat-click="handleFilesClick(type)"
          linear
        />
        <span
          v-else
          class="clickable"
          style="color: rgb(var(--v-theme-primary))"
          @click="handleFilesClick(type)"
        >
          {{ $t('docTypes.add') }}
        </span>
      </div>
      <div class="type-info-section">
        <div style="font-size: 0.8rem">
          {{ $t('docTypes.correctly_extracted') }}
        </div>
        <StatItem
          v-if="type.nb_valid_files > 0"
          :left="type.nb_valid_files"
          :right="type.nb_verified_files"
          linear
        />
        <span v-else>
          —
        </span>
      </div>
      <div class="type-info-section">
        <div style="font-size: 0.8rem">
          {{ $t('docTypes.global_precision_header') }}
        </div>
        <StatItem
          v-if="type.nb_values > 0"
          :left="type.nb_valid_values"
          :right="type.nb_values"
          linear
        />
        <span v-else>
          —
        </span>
      </div>
      <div
        v-if="userRole === 'orgadmin'"
        class="snippet-button"
      >
        <v-tooltip
          color="#423F4F"
          right
        >
          <template #activator="{ props }">
            <v-btn
              class="small-button"
              color="primary"
              v-bind="props"
              @click="$emit('snippetClick', type.id)"
            >
              <v-icon size="16">
                fas fa-code
              </v-icon>
            </v-btn>
          </template>
          <span style="color: white">
            {{ $t('docTypes.get_url') }}
          </span>
        </v-tooltip>
      </div>
    </div>
    <div class="toolbox">
      <v-checkbox
        v-model="type.selected"
        color="primary"
        @change="$emit('selectClick')"
      />
    </div>
  </v-card>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import StatItem from '@/components/extract/elements/DocTypes/StatItem';

export default {
  name: 'DocTypeInfoCard',

  components: {
    ItemName,
    StatItem,
  },

  computed: {
    userRole() {
      return this.$store.getters.loggedInUser.role;
    },
  },

  methods: {
    handleFilesClick(type) {
      if (type.nb_files === 0) {
        this.$emit('selectFile', type.id);
      } else {
        this.$router.push({
        name: 'DocType',
        params: {
          id: type.id,
          tab: 'documents',
        },
      });
      }
    },

    getPrecision(type) {
      if (type.nb_values <= 0) {
        return '—';
      }
      return `${(type.nb_valid_values / type.nb_values * 100).toFixed(0)}%`;
    },
  },

  props: {
    type: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    editing: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'saveFileName',
    'nameClick',
    'snippetClick',
    'selectClick',
    'selectFile',
  ],
}
</script>

<style lang="scss" scoped>
.doc-type-info-card {
  display: inline-block;
  width: 450px;
  height: 240px;
  position: relative;

  .snippet-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .toolbox {
    position: absolute;
    top: 10px;
    right: 0px;
  }

  .type-image {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 200px;
    background-color: #D8D8D8;
    border-radius: 5px 0px 0px 5px !important;
    padding: 0.5rem;
    text-align: center;
  }

  .type-page-image {
    background-size: contain;
    background-position: center center;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .type-info {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 250px;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    justify-content: space-between;

    .type-info-section {
      width: 250px;
      border-bottom: 1px solid #D8D8D8;
      padding: 15px;
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 0.9rem;
    }
  }
}
</style>
