/* jshint esversion: 6 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import jwtDecode from 'jwt-decode';
import { store } from '@/plugins/store';
import router from '@/plugins/router';
import { AuthAPI } from '@/API/authenticator/AuthAPI';
import { UserAPI } from '@/API/authenticator/UserAPI';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';

/**
 * This class is used as a data structure containing ID Token information.
 */
class IdTokenInfo {
  /**
   *
   * @param {string} token The initial encoded token.
   * @param {Date} expDate The expiration date.
   * @param {string} username The user name associated to the token.
   * @param {string} email  The email associated to the token.
   */
  constructor(token, expDate, username, email) {
    this.token = token;
    this.expDate = expDate;
    this.username = username;
    this.email = email;
  }
}

/**
 * This class provides functions for authentication.
 */
class AuthenticationUtilsClass {
  static async refreshAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      try {
        const response = await AuthAPI.refresh({ refresh_token: refreshToken });
        localStorage.setItem('token', response.access_token);
        return true;
      } catch (error) {
        router.push({ name: 'logout' });
        return false;
      }
    }
    return false;
  }

  static async refreshTokenIfNeeded(local = false) {
    let tokenIsValid = await AuthenticationUtils.isTokenValid(local);
    if (!tokenIsValid) {
      tokenIsValid = await AuthenticationUtils.refreshAccessToken();
    }
    return tokenIsValid;
  }

  /**
   * Extract information from token.
   * @param {string} token
   * @return {IdTokenInfo} Return an IdTokenInfo object.
   */
  static getTokenInfo(token) {
    const decodedToken = jwtDecode(token);
    return new IdTokenInfo(
      token,
      this.getExpDateFromToken(decodedToken),
      decodedToken.name,
      decodedToken.email
    );
  }

  /**
   * Save the id token.
   * @param {string} token The encoded id token.
   */
  static saveToken(token) {
    localStorage.setItem('token', token);
  }

  /**
   * Get the IdToken saved locally.
   * @return {string} The id token stored locally if exists.
   */
  static getToken() {
    return localStorage.getItem('token');
  }

  /**
   * It resets id and access tokens stored locally
   */
  static deleteLocalAuthenticationToken() {
    localStorage.removeItem('token');
  }

  /**
   * Get the expiration date from the token.
   * @param {string} decodedToken The DECODED token.
   * @return {Date} The expiration date.
   */
  static getExpDateFromToken(decodedToken) {
    const date = new Date(0);
    return date.setUTCSeconds(decodedToken.exp);
  }

  /**
   * Checks if the user is correctly logged in which means he has authentication token valid.
   * @param {*} state The state of the store.
   * @param {*} getters Getters of the store
   * @return {boolean} If user is correctly logged in.
   */
  static async isLoggedIn() {
    const tokenIsValid = await AuthenticationUtils.refreshTokenIfNeeded();
    if (tokenIsValid) {
      if (!store.getters.loggedInUser) {
        try {
          const user = await UserAPI.getCurrentUser();
          if (user.org_id) {
            const org = await OrgsAPI.getOneOrg(user.org_id);
            user.org_name = org.name;
            user.product = org.product;
          }
          store.commit('login', user);
        } catch (error) {
          console.log(error);
          return false
        }
      }
      return true;
    }
    return false;
  }

  /**
   * Checks if there is an ID token currently valid.
   * @param {*} state The state of the store.
   * @return {Promise<boolean>} If ID token exists and is valid.
   */
  static async isTokenValid(local = false) {
    const token = AuthenticationUtils.getToken();
    if (token && token !== 'undefined' && token !== 'null') {
      if (local) {
        const tokenInfo = AuthenticationUtils.getTokenInfo(token);
        return !!tokenInfo.expDate && tokenInfo.expDate > new Date();
      } else {
        try {
          await AuthAPI.verifyToken(token);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    }
    return false;
  }
}

export const AuthenticationUtils = AuthenticationUtilsClass;
